import React, {useEffect, useState} from "react";
import apiClient from "../../Services/apiService";
import HeaderSimple from "./HeaderSimple";
import FooterBar from "./FooterBar";
import {Helmet} from "react-helmet";
import {encodeBase64} from "../../utils/utils";

export default function PageListePlanningClt (){
    const [pastPlaningCreneaux, setPastPlaningCreneaux] = useState([]);
    const [futurePlaningCreneaux, setFuturePlaningCreneaux] = useState([]);
    const [showFirstTable, setShowFirstTable] = useState(true);
    const [id, setId] = useState(null); // Déclare l'ID comme état pour qu'il soit accessible partout

    const userEmail = localStorage.getItem('userEmail');

    useEffect(() => {

        const fetchPastPlaningCreneaux = async () => {
            try {
                const prospectBeforeResponse  = await apiClient.post("/reservation/prospect/before",{
                    email : userEmail
                })
                //const expertId = idExpertResponse.data;// Stocker l'ID expert dans l'état
                //const response = await apiClient.get(`/detailsCreneau/expert/past/${expertId}`); // Remplacez '1' par l'ID de l'expert souhaité
                setPastPlaningCreneaux(prospectBeforeResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchFuturePlaningCreneaux = async () => {
            try {
                const prospectAfterResponse  = await apiClient.post("/reservation/prospect/after",{
                    email : userEmail
                })
                //const expertId = idExpertResponse.data;// Stocker l'ID expert dans l'état
                //const response = await apiClient.get(`/detailsCreneau/expert/future/${expertId}`); // Remplacez '1' par l'ID de l'expert souhaité
                setFuturePlaningCreneaux(prospectAfterResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPastPlaningCreneaux();
        fetchFuturePlaningCreneaux();
    }, []);

    const toggleTable = () => {
        setShowFirstTable(!showFirstTable);
    };

    function getClassNameForStatus(statut) {
        switch (statut) {
            case 'RÉSERVÉ':
                return 'bg-green-700'; // Autre exemple de classe pour un autre statut
            case 'OCCUPÉ':
                return 'bg-yellow-600'; // Autre exemple de classe pour un autre statut
            case 'TERMINER':
                return 'bg-blue-700'; // Autre exemple de classe pour un autre statut
            default:
                return 'bg-gray-700'; // Classe par défaut pour les autres statuts
        }
    }

    const getStatutText = (statut) => {
        switch (statut) {
            case "ATTENTE_TRAITEMENT":
                return "En traitement";
            case "ATTENTE_CONFIRMATION_EXPERT":
                return "Attente confirmation expert";
            case "ATTENTE_ENVOI_MAIL_VALID_EXPERT":
                return "Attente confirmation expert";
            case "ATTENTE_ENVOI_MAIL_PROSPECT":
                return "Attente votre paiement";
            case "ATTENTE_PAIEMENT_PROSPECT":
                return "Attente votre paiement";
            case "PAIEMENT_PROSPECT_EFFECTUER":
            case "PAIEMENT_PROSPECT_ALL_NOTIFIER":
            case "ALL_NOTIFIER_LIEN_MEET_AGENDA":
                return "Planifié";
            default:
                return "Statut inconnu";
        }
    };

    const getStatutColor = (statut) => {
        switch (statut) {
            case "ATTENTE_TRAITEMENT":
                return "bg-bleu-500"; // Jaune pour indiquer une attente en cours
            case "ATTENTE_CONFIRMATION_EXPERT":
            case "ATTENTE_ENVOI_MAIL_VALID_EXPERT":
                return "bg-orange-500"; // Violet pour l'attente de validation par l'expert
            case "ATTENTE_ENVOI_MAIL_PROSPECT":
            case "ATTENTE_PAIEMENT_PROSPECT":
                return "bg-amber-500"; // Rouge pour signaler l'attente d'un paiement
            case "PAIEMENT_PROSPECT_EFFECTUER":
            case "PAIEMENT_PROSPECT_ALL_NOTIFIER":
            case "ALL_NOTIFIER_LIEN_MEET_AGENDA":
                return "bg-green-700"; // Vert pour indiquer que la réservation est planifiée
            default:
                return "bg-gray-500"; // Gris pour les statuts inconnus
        }
    };

    return(
        <>
            <><Helmet><title>Planning Client - Experlik.ma</title></Helmet></>
            <HeaderSimple
                mainTitle="Consultations : À venir et Historique"
                subTitle="Suivez facilement vos consultations prévues et passées."
            ></HeaderSimple>
        <div className="p-3">
            <div className="flex justify-center  mb-4">
                <div className="mx-8 shadow font-poppins rounded-full h-10 mt-4 flex p-1 relative items-center w-64">
                    <div className="w-full flex justify-center">
                        <button
                            onClick={() => setShowFirstTable(true)}
                            className={`w-full text-center ${showFirstTable ? 'text-white' : 'text-gray-700'}`}
                        >
                            À venir
                        </button>
                    </div>
                    <div className="w-full flex justify-center">
                        <button
                            onClick={() => setShowFirstTable(false)}
                            className={`w-full text-center ${showFirstTable ? 'text-gray-700' : 'text-white'}`}
                        >
                            Historique
                        </button>
                    </div>
                    <span
                        className={`elSwitch bg-teal-900 shadow text-white  flex items-center justify-center w-1/2 rounded-full h-8 transition-all absolute top-[4px] ${showFirstTable ? 'left-1' : 'right-1'}`}
                    >
                        {showFirstTable ? 'À venir' : 'Historique'}
                    </span>
                </div>
            </div>

            <div
                className="relative overflow-x-auto   shadow-md rounded-lg md:w-3/4 w-full flex mx-auto  ">
                {showFirstTable ? (
                    <table className="w-full text-sm text-center rounded-2xl rtl:text-right text-gray-500 ">
                            <thead className=" text-xs text-gray-700  uppercase bg-gray-200 ">
                            <tr>
                                <th scope="col" className=" border px-10 py-3">
                                    Jour
                                </th>
                                <th scope="col" className=" border px-10 py-3">
                                    Semaine
                                </th>

                                <th scope="col" className="border px-6 py-3">
                                    Heure Début
                                </th>
                                <th scope="col" className="border px-6 py-3">
                                    Heure Fin
                                </th>
                                <th scope="col" className="border px-6 py-3">
                                    statut
                                </th>
                            </tr>
                            </thead>
                        <tbody>
                        {futurePlaningCreneaux.map((creneau) => (
                                <tr className="bg-white font-semibold border-b " key={creneau.idPlaningCreneau}>
                                    <td className="border px-4 py-2">{creneau.jour}</td>
                                    <td className="border px-4 py-2">{creneau.semaine}</td>

                                    <td className="border px-4 py-2">{creneau.heureDebut}</td>
                                    <td className="border px-4 py-2">{creneau.dateFinBlocage}</td>
                                    <td className={`border px-4 py-2`}>
                                    <span
                                        className={`text-white rounded-full inline-block bg-blue-700 ${getStatutColor(creneau.statutReservation)} py-1 w-64`}
                                    >
                                        {getStatutText(creneau.statutReservation)}
                                    </span>
                                    </td>
                                </tr>
                        ))}
                        </tbody>
                    </table>

                ) : (
                    <table className="w-full text-sm text-center rounded-2xl rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                        <tr>
                            <th scope="col" className="px-10 py-3">
                                Jour
                            </th>
                            <th scope="col" className="px-10 py-3">
                                Semaine
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Heure Début
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Heure Fin
                            </th>
                            <th scope="col" className="px-6 py-3">
                                statut
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {pastPlaningCreneaux.map((creneau) => (
                            <tr className="bg-white font-semibold border-b " /*key={creneau.idPlaningCreneau}*/>
                                <td className="border px-4 py-2">{creneau.jour}</td>
                                <td className="border px-4 py-2">{creneau.semaine}</td>

                                <td className="border px-4 py-2">{creneau.heureDebut}</td>
                                <td className="border px-4 py-2">{creneau.dateFinBlocage}</td>
                                <td className={`border px-4 py-2`}>
                                    {(() => {
                                        // Fusion de jour et heureDebut pour créer un objet Date
                                        const dateHeureDebut = new Date(
                                            `${creneau.jour.split('/').reverse().join('-')}T${creneau.heureDebut}`
                                        );

                                        const maintenant = new Date();

                                        // Calcul de la différence en heures
                                        const differenceEnHeures = (maintenant - dateHeureDebut) / (1000 * 60 * 60);

                                        if (differenceEnHeures <= 24 && differenceEnHeures > 0 && creneau.flagAvis === "0" ) {
                                            return (
                                                <a href={`/AvisClient/${encodeBase64(creneau.idReservation)}`} className="text-white inline-block w-64 bg-yellow-600 py-1 px-4 rounded-full">
                                                    En attente d'avis
                                                </a>
                                            );
                                        } else {
                                            return (
                                                <span className="text-white rounded-full inline-block bg-green-700 py-1 w-64">
                                                    Terminer
                                                </span>
                                            );
                                        }
                                    })()}
                                    {/*<span
                                        className={`text-white rounded-full inline-block bg-green-700 py-1 w-64`}
                                    >
                                        Terminer
                                        {creneau.statutReservation}
                                    </span>*/}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

        </div>
            <FooterBar></FooterBar>
        </>
    )
}