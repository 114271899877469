import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CheckCircleIcon } from 'lucide-react';
import { STATUS_OK, TRACER_VALIDATION_PRESENCE_MEET } from '../utils/eventsTypes';
import { getPublicIP } from '../utils/getIpAdress';
import { jwtDecode } from 'jwt-decode';
import apiClient from '../Services/apiService';


// trace meet validation back-end - front end handle hash - handle meet add time
const ValidationPresenceToast = ({ token, roomName, closeToast }) => {
  const traceEvent = async () => {
    let decoded = jwtDecode(token)
    let eventPayload;
    
    eventPayload = {
      clientEmail: decoded.context.user.email,
      ipAddress: await getPublicIP(), // Optional
      role: decoded.context.user.affiliation,
      roomName: roomName
    };

    console.log(eventPayload)

    try {
      const response = await apiClient.post("/meet/valider-presence", eventPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      closeToast();
    } catch (error) {
      // Handle error response
      if (error.response) {
        // Server returned an error response
        console.error(error.response.data);
        closeToast();
      } else {
        // Network or other error
        console.error("An unexpected error occurred");
        closeToast();
      }
    }

  };

  return (
    <div className="flex items-start">
      <CheckCircleIcon className="w-6 h-6 text-green-500 mr-3" />
      <div className="flex-1">
        <p className="mb-2 text-gray-800">
          Votre réunion s'approche de sa fin! Est-ce que vous validez le bon déroulement de la consultation/service?
        </p>
        <button
          onClick={() => traceEvent(STATUS_OK, "L'utilisateur à marquer sa presence")}
          className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          Je valide
        </button>
      </div>
    </div>
  );
};

export default ValidationPresenceToast;
