import React from 'react';
import { Field } from 'formik';

export default function StepSettings({ values, errors, touched }) {
    return (
        <div>
            <label className="text-gray-800 text-sm mb-2 block">Email</label>
            <Field
                name="email"
                type="email"
                required
                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                placeholder="Entrez votre email"
            />
            {errors.email && touched.email && (
                <div className="text-red-500 pt-2 text-xs">{errors.email}</div>
            )}

            <label className="text-gray-800 text-sm mb-2 block mt-4">Mot de passe</label>
            <Field
                name="password"
                type="password"
                required
                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                placeholder="Entrez votre mot de passe"
            />
            {errors.password && touched.password && (
                <div className="text-red-500 pt-2 text-xs">{errors.password}</div>
            )}
        </div>
    );
}
