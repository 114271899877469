import axios from 'axios';

/*function decodeResponseBody(encodedResponse) {
    try {
        let base64 = '';
        for (let i = 0; i < encodedResponse.length; i++) {
            if (i % 4 !== 3) { 
                base64 += encodedResponse[i];
            }
        }
        const decodedData = atob(base64);

        try {
            const jsonData = JSON.parse(decodedData);
            return jsonData; 
        } catch (error) {
            return decodedData;
        }
    } catch (error) {
        console.error('Failed to decode response body:', error);
        throw new Error('Failed to decode response body');
    }
}*/
export function decodeResponseBody(encodedResponse) {
    try {
        let base64 = '';
        for (let i = 0; i < encodedResponse.length; i++) {
            if (i % 4 !== 3) {
                base64 += encodedResponse[i];
            }
        }
        // Décodage en base64
        const binaryString = atob(base64);

        // Conversion en texte UTF-8
        const utf8Decoder = new TextDecoder('utf-8');
        const binaryData = Uint8Array.from(binaryString, char => char.charCodeAt(0));
        const decodedData = utf8Decoder.decode(binaryData);

        // Tenter de parser en JSON
        try {
            const jsonData = JSON.parse(decodedData);
            return jsonData;
        } catch (error) {
            return decodedData; // Retourner en tant que texte brut si non JSON
        }
    } catch (error) {
        console.error('Failed to decode response body:', error);
        throw new Error('Failed to decode response body');
    }
}


const apiClient = axios.create({
    baseURL: window._env_.REACT_APP_BACKEND_URL, 
    headers: {
        'Content-Type': 'application/json', 
    },
});

apiClient.interceptors.response.use(
    (response) => {
        // Décoder les données de la réponse
        if (response.data) {
            try {
                response.data = decodeResponseBody(response.data);
            } catch (decodeError) {
                console.error('Erreur lors du décodage des données de la réponse :', decodeError);
            }
        }
        return response;
    },
    (error) => {
        // Décoder les messages d'erreur
        if (error?.response?.data) {
            try {
                error.response.data = decodeResponseBody(error.response.data);
            } catch (decodeError) {
                console.error('Erreur lors du décodage des données d\'erreur :', decodeError);
            }
        }

        // Logger l'erreur pour plus de détails
        console.error('Requête échouée :', error);

        // Rejeter l'erreur pour que les appels puissent la gérer
        return Promise.reject(error);
    }
);

export default apiClient;
/*// Exemple d'utilisation avec l'URL complète
const url1 = 'window._env_.REACT_APP_BACKEND_URL';
const url2 = 'http://192.168.11.102:8080';*/

/*// Exemple d'appel à l'API avec la première URL
apiClient.get(`${url1}`)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });

// Exemple d'appel à l'API avec la deuxième URL
apiClient.get(`${url2}`)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });


/!*
const apiClient = axios.create({
    baseURL: window._env_.REACT_APP_API_URL || 'window._env_.REACT_APP_BACKEND_URL', // Utiliser la variable d'environnement ou fallback à localhost
    headers: {
        'Content-Type': 'application/json', // Définit le type de contenu par défaut pour les requêtes
    },
});*!/*/
