import React, { useState } from "react";
import apiClient from "../Services/apiService";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { getPublicIP } from "../utils/getIpAdress";
import { STATUS_OK, TRACER_AJOUT_TEMPS_MEET} from "../utils/eventsTypes";

const TimeSelector = ({ isOpen, onClose, roomName, dateFin, updateExpiration, token }) => {
  const [time, setTime] = useState(5);
  const [loading, setLoading] = useState(false);

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const traceEvent = async (status, message) => {
    let decoded = jwtDecode(token)
    let eventPayload;
    // Check if `ids` is a comma-separated list or a single number                // Build the payload
    eventPayload = {
      clientEmail: decoded.context.user.email,
      eventType: TRACER_AJOUT_TEMPS_MEET,
      ipAddress: await getPublicIP(), // Optional
      parameter: `Affiliation : ${decoded.context.user.affiliation} / userMail : ${decoded.context.user.email} / time : ${time}`,
      status: status,
      description: message,
      roomName: roomName
    };

    console.log(eventPayload)

    try {
      const response = await apiClient.post("/events/trace", eventPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      // Handle error response
      if (error.response) {
        // Server returned an error response
        console.error(error.response.data);
      } else {
        // Network or other error
        console.error("An unexpected error occurred");
      }
    }

  };

  const handleOpenToastSuccess = () => {
    toast.success('La date d\'expiration a été mise à jour avec succès !', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleOpenToastFailed = (message) => {
    toast.error(message || 'Oups ! Une erreur s\'est produite. Veuillez réessayer.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // API request to update expiration date
      const response = await apiClient.post("/infoVisioCall/updateExpirationDate", {
        roomName,
        bonus: time.toString(),
      });

      // Update the parent's state for dateFin
      updateExpiration(time);

      // Notify success
      handleOpenToastSuccess();
      traceEvent(STATUS_OK,`L'expert a ajouté un temps aditionnelle de ${time}`)
      onClose();
    } catch (err) {
      if (err.response?.status === 451) {
        const errorMessage = `Le meet a déjà été mise à jour et ne peut pas l'être une deuxième fois.`;
        traceEvent(STATUS_OK, errorMessage)
        handleOpenToastFailed(errorMessage);
      } else {
        const errorMessage = "Oups ! Une erreur s'est produite. Veuillez réessayer.";
        traceEvent(STATUS_OK,errorMessage)
        handleOpenToastFailed(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed bottom-4 right-4 w-80 bg-white shadow-lg rounded-lg border border-gray-300 p-4 z-50">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Ajouter du temps</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-800 transition"
        >
          ✕
        </button>
      </div>
      <p className="text-sm text-gray-600 mb-4">
        Ajouter du temps supplémentaire à la réunion (5-10 minutes).
      </p>
      <div className="flex items-center justify-between w-full mb-4">
        <span className="text-sm text-gray-500">5 min</span>
        <input
          type="range"
          min="5"
          max="10"
          value={time}
          onChange={handleTimeChange}
          className="w-full mx-2 accent-blue-500"
        />
        <span className="text-sm text-gray-500">10 min</span>
      </div>
      <div className="text-center text-sm text-gray-800 mb-4">
        Temps sélectionné : <strong>{time} min</strong>
      </div>
      <button
        onClick={handleSubmit}
        disabled={loading}
        className={`w-full ${
          loading ? "bg-gray-400" : "bg-green-600 hover:bg-green-700"
        } text-white py-2 rounded-md transition`}
      >
        {loading ? "Mise à jour..." : "Ajouter du temps"}
      </button>
    </div>
  );
};

export default TimeSelector;
