import React, {useEffect, useRef, useState} from 'react';
import Header from "./Header";
import * as services from "react-bootstrap/ElementChildren";
import axios from "axios";
import apiClient from "../../Services/apiService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {getDomaineService} from "../../Services/DomaineService";
import DomaineDropdown from "./DomaineDropdown";
//import  '../styles/NosDomains.css';
import HashedLink from '../../utils/HashedLink';

export default function NosServicesDetails({  selectedDomaine, setSelectedDomaine }) {

    const [services, setServices] = useState([]);
    const [domaineServices, setDomaineServices] = useState([]);
    //const [selectedDomaine, setSelectedDomaine] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    /*useEffect(() => {
        apiClient.get('/services/list')
            .then(response => {
                setServices(response.data); // Assuming response.data is an array of services
            })
            .catch(error => {
                console.error('Error fetching services:', error);
            });
    }, []);*/

    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                setDomaineServices(domainesService);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    /*// Filtrer les domaines sélectionnés
    const filteredDomaines = selectedDomaine.length > 0
        ? domaineServices.filter(domaine => selectedDomaine.includes(domaine.idDomaine))
        : [];*/

    const filteredDomaines = selectedDomaine.length > 0
        ? domaineServices.filter(domaine => selectedDomaine.includes(domaine.idDomaine))
        : []; // Optionally show all if nothing is selected

    // Obtenir les 6 premiers services
// Obtenir un service par domaine
    const topServices = domaineServices.map(domaine => domaine.services[0]); // Sélectionner le premier service de chaque domaine
    topServices.sort((a, b) => b.nomService.localeCompare(a.nomService)); // Trier les services en ordre décroissant
    const displayedTopServices = topServices.slice(0, 9); // Obtenir les 6 premiers services

    return (
        <div id={"ServiceDetails"} className=" flex text-center flex-col px-5 pb-8 our-services mx-auto items-center justify-center ">
            <h2 className="title-service font-poppins text-3xl font-bold text-gray-700">Nos services </h2>
            <div className="text-center text-gray-700 py-5">
                <p className="font-poppins text-md font-normal text-center text-gray-700">
                    Explorez nos services exceptionnels.
                </p>
                <p className="font-poppins text-md font-normal text-center text-gray-700">
                    Cliquez ici pour choisir le service qui répondra parfaitement
                </p>
                <p className="font-poppins text-md font-normal text-center text-gray-700">
                    A vos besoins et commencez votre voyage vers le succès dès aujourd'hui !
                </p>
            </div>

            <DomaineDropdown
                domaineServices={domaineServices}
                selectedDomaine={selectedDomaine}
                setSelectedDomaine={setSelectedDomaine}
            />

            <div id="services" className="text-center w-3/4 md:w-auto text-gray-700 py-5 mx-auto">
                {filteredDomaines.length === 0 ? (
                    <div>
                        <h2 className="title-service mt-4 w-full text-left text-lg underline font-montserrat font-bold text-gray-700">Top
                            Services</h2>
                        <div className="mt-4 flex-col md:grid md:grid-cols-3 md:gap-3">
                            {displayedTopServices.map((service) => (
                                <div key={service.idService}
                                     className="bg-teal-900 font-semibold text-white text-center p-2 m-2 rounded-xl w-full md:w-60 content-center mx-auto">
                                    <HashedLink to={`/service/:id`} id={service.idService}
                                          className="link-services">{service.nomService}
                                        <div className={"text-xs"}> "{service.nomDomaine}"</div>
                                    </HashedLink>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    filteredDomaines.map((domaine) => (
                        <div key={domaine.idDomaine} className="">
                            <h2 className="title-service mt-4 w-full text-left text-lg underline font-montserrat font-bold text-gray-700">
                                {domaine.nomDomaine}
                            </h2>
                            <div className="mt-4 flex-col md:grid md:grid-cols-3 md:gap-3">
                                {domaine.services.map((service) => (
                                    <div key={service.idService}
                                         className="bg-teal-900 font-semibold text-white text-center p-2 m-2 rounded-xl w-full md:w-60 content-center mx-auto">
                                        <HashedLink to={`/service/:id`} id={service.idService}
                                              className="link-services">{service.nomService}</HashedLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}