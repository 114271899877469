import axios from "axios";

export const getPublicIP = async () => {
    try {
        const response = await axios.get("https://api.ipify.org?format=json");
        return response.data.ip; // Returns the IP address
    } catch (error) {
        console.error("Error fetching the IP address:", error);
        return null;
    }
};