import React, { useState } from "react";
import axios from 'axios'
import { Link, Navigate, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import logoHbm from '../images/Xperlink-noir-12.webp'
//import { logo, logoDark } from "../assets";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import decodeJwtToken from "./decodeToken";
import { jwtDecode } from "jwt-decode";
import { getPublicIP } from "../utils/getIpAdress";
import { CONSULTER_EXPERT_PROFILE, SE_CONNECTER, STATUS_NOK, STATUS_OK } from "../utils/eventsTypes";
import apiClient from "../Services/apiService";




const schema = Yup.object().shape({
    email: Yup.string()
        .required("Email est obligatoire")
        .email("Invalid email format"),
    password: Yup.string()
        .required("Mot de passe est obligatoire")
        .min(8, "Password must be at least 8 characters"),
});


const Login = () => {


    const [responseStatus, setResponseStatus] = useState(null)
    const [visiblePassword, setVisiblePassword] = useState(false)

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const { state } = useLocation();
    const navigate = useNavigate()


    const traceEvent = async (status, message,email,role) => {
        let eventPayload;
        // Check if `ids` is a comma-separated list or a single number                // Build the payload
        eventPayload = {
            clientEmail: email,
            eventType: SE_CONNECTER,
            ipAddress: await getPublicIP(), // Optional
            parameter: role ? "Role : " + role : null,
            status: status,
            description: message
        };

        try {
            const response = await apiClient.post("/events/trace", eventPayload, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            // Handle error response
            if (error.response) {
                // Server returned an error response
                console.error(error.response.data);
            } else {
                // Network or other error
                console.error("An unexpected error occurred");
            }
        }

    };


    // Gestion de la redirection en fonction de l'authentification
    if (isAuthenticated) {
        // Si l'utilisateur est authentifié et une route précédente est disponible, il est redirigé vers cette route
        if (state?.from?.pathname) {
            return <Navigate to={state?.from?.pathname} />;
        } else {
            // Si aucune route précédente n'est disponible, on redirige en fonction du rôle de l'utilisateur
            const role = localStorage.getItem('role');  // On récupère le rôle de l'utilisateur depuis le localStorage
            if (role === 'EXPERT') {
                return <Navigate to='/' />;
            } else if (role === 'CLIENT') {
                return <Navigate to='/' />;
            } else {
                return <Navigate to='/' />;
            }
        }
    }

    // Function to get the current date and time plus 30 minutes
    const getExpirationDate = (minutesToAdd) => {
        const now = new Date(); // Current date and time
        now.setMinutes(now.getMinutes() + minutesToAdd); // Add minutes
        return now;
    };


    return (
        <>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                        <a href="/"><img
                            src={logoHbm} alt="logo" className='w-52 mb-8 mx-auto block'/>
                        </a>
                        <div className="p-8 rounded-2xl bg-white shadow">
                            <h2 className="text-gray-800 text-center text-2xl font-bold">Se connecter</h2>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={schema}
                                onSubmit={values => {
                                    apiClient.post('/api/v1/authenticate', {  // Notez que nous appelons maintenant le backend proxy
                                        "email": values.email,
                                        "password": values.password
                                    })
                                        .then((res) => {
                                            if (res.status === 200) {
                                                // Décoder le access_token
                                                const decodedToken = jwtDecode(res.data.access_token);
                                                //console.log(res.data.access_token);
                                                // Extraire les informations du token
                                                const { firstName, lastName, tel, role, email } = decodedToken;
                                                // Maintenant vous avez accès au rôle de l'utilisateur depuis le token JWT

                                                /*console.log('firstName:', firstName);
                                                console.log('lastName:', lastName);
                                                console.log('role:', role);
                                                console.log('email:', email);*/

                                                //console.log('User Info:', firstName, lastName,tel, role, email);

                                                // Récupérer le rôle directement depuis la réponse JSON
                                                /*const role = res.data.role;
                                                console.log('role:', role);*/
                                                if (signIn({
                                                    auth: {
                                                        token: res.data.access_token,
                                                        type: 'Bearer'
                                                    },
                                                    //refresh: res.data.refresh_token,
                                                    userState: {
                                                        email: values.email,
                                                        fullName: firstName + " " + lastName,
                                                        tel: tel,
                                                        role: role
                                                    }
                                                })) {
                                                    window.localStorage.setItem('session_expire_at', getExpirationDate(60))
                                                    window.localStorage.setItem('userFirstName', firstName);  // Storing first name
                                                    window.localStorage.setItem('accessToken', res.data.access_token);
                                                    window.localStorage.setItem('userLastName', lastName);    // Storing last name
                                                    window.localStorage.setItem('userTel', tel);    // Storing last name
                                                    window.localStorage.setItem('userEmail', email);
                                                    window.localStorage.setItem('role', role);    // Storing last name
                                                    const lastRoute = window.localStorage.getItem('lastRoute')
                                                    // Redirection selon le rôle de l'utilisateur
                                                    if (lastRoute === '/resetPassword' || lastRoute === '/passwordResetPage' || lastRoute === '/resetpassword') {
                                                        navigate('/');
                                                    } else if (lastRoute) {
                                                        navigate(lastRoute);
                                                    } else {
                                                        if (role === 'EXPERT' || role === 'CLIENT') {
                                                            navigate('/');
                                                        } else {
                                                            navigate('/');
                                                        }
                                                    }

                                                    traceEvent(STATUS_OK,"Connexion reussie.",email,role);
                                                } else {
                                                    traceEvent(STATUS_NOK,'Erreur lors de la connexion client_side.',email,role);
                                                    setResponseStatus('Erreur lors de la connexion.');
                                                }
                                            }

                                        }).catch(function (error) {
                                            if (error.response?.status === 401) {
                                                setResponseStatus('Mot de passe incorrect')
                                                traceEvent(STATUS_NOK,"401 : Mot de passe incorrect", values.email,null);
                                            } else if (error.response?.status === 404) {
                                                setResponseStatus('Utilisateur non trouvé')
                                                traceEvent(STATUS_NOK,"404 : Utilisateur non trouve", values.email,null);
                                            } else if (error.response) {
                                                // The request was made and the server responded with a status code
                                                // that falls out of the range of 2xx
                                                setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                                traceEvent(STATUS_NOK,"Erreur de serveur interne veuillez réessayer plus tard", values.email,null);
                                            } else if (error.request) {
                                                // The request was made but no response was received
                                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                                // http.ClientRequest in node.js
                                                setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                                traceEvent(STATUS_NOK,"Erreur de serveur interne veuillez réessayer plus tard", values.email,null);
                                            } else {
                                                // Something happened in setting up the request that triggered an Error
                                                console.log('Error', error.message);
                                            }
                                        });
                                }}
                            >
                                {({ errors, touched }) => (

                                    <Form className="mt-8 h-auto space-y-4">
                                        {responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }
                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Email</label>
                                            <div className="relative flex items-center">
                                                <Field name="email" type="text" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Enter user name"/>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4" viewBox="0 0 24 24">
                                                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                                                    <path
                                                        d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-6"}>
                                                {errors.email && touched.email ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.email}</div>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="password" type={visiblePassword ? 'text' : 'password'}
                                                       required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Enter password"/>
                                                <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4 cursor-pointer"
                                                     viewBox="0 0 128 128">
                                                    <path
                                                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-6"}>
                                                {errors.password && touched.password ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                            <div className="flex flex-wrap items-center justify-end gap-4">
                                                <div className="text-sm">
                                                    <a href="/passwordResetPage"
                                                       className="text-third hover:underline font-semibold">
                                                        Mot de passe oublié ?
                                                    </a>
                                                </div>
                                            </div>

                                        <div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                Se connecter
                                            </button>
                                        </div>
                                        <p className="text-gray-800 text-sm !mt-8 text-center">Vous n'avez pas de compte
                                                                                               ? <Link to="/register"
                                                                                                       className="text-third hover:underline ml-1 whitespace-nowrap font-semibold">Inscrivez-vous
                                                                                                                                                                                   maintenant</Link>
                                        </p>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className="flex flex-col items-center justify-center">
                            <Link to='/'
                                  className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
                                  title="Return Home">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                                <span>Return Home</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;

/*<div className="p-8 rounded-2xl bg-white shadow w-full md:w-1/2">
    <h2 className="text-gray-800 text-center text-2xl font-bold">Se connecter</h2>
    <p className="text-center font-semibold text-blue-700">Accès Expert</p>
    <Formik
        initialValues={{
            email: '',
            password: '',
        }}
        validationSchema={schema}
        onSubmit={values => {
            axios.post(window._env_.REACT_APP_BACKEND_URL + '/api/v1/authenticate', {  // Notez que nous appelons maintenant le backend proxy
                "email": values.email,
                "password": values.password
            })
                .then((res) => {
                    if (res.status === 200) {
                        // Décoder le access_token
                        const decodedToken = jwtDecode(res.data.access_token);
                        //console.log(res.data.access_token);
                        // Extraire les informations du token
                        const {firstName, lastName, tel, role, email} = decodedToken;
                        // Maintenant vous avez accès au rôle de l'utilisateur depuis le token JWT

                        /!*console.log('firstName:', firstName);
                        console.log('lastName:', lastName);
                        console.log('role:', role);
                        console.log('email:', email);*!/

                        //console.log('User Info:', firstName, lastName,tel, role, email);

                        // Récupérer le rôle directement depuis la réponse JSON
                        /!*const role = res.data.role;
                        console.log('role:', role);*!/
                        if (signIn({
                            auth: {
                                token: res.data.access_token,
                                type: 'Bearer'
                            },
                            //refresh: res.data.refresh_token,
                            userState: {
                                email: values.email,
                                fullName: firstName + " " + lastName,
                                tel: tel,
                                role: role
                            }
                        })) {
                            window.localStorage.setItem('session_expire_at', getExpirationDate(60))
                            window.localStorage.setItem('userFirstName', firstName);  // Storing first name
                            window.localStorage.setItem('accessToken', res.data.access_token);
                            window.localStorage.setItem('userLastName', lastName);    // Storing last name
                            window.localStorage.setItem('userTel', tel);    // Storing last name
                            window.localStorage.setItem('userEmail', email);
                            window.localStorage.setItem('role', role);    // Storing last name
                            const lastRoute = window.localStorage.getItem('lastRoute')
                            // Redirection selon le rôle de l'utilisateur
                            if (lastRoute === '/resetPassword' || lastRoute === '/passwordResetPage' || lastRoute === '/resetpassword') {
                                navigate('/');
                            } else if (lastRoute) {
                                navigate(lastRoute);
                            } else {
                                if (role === 'EXPERT' || role === 'CLIENT') {
                                    navigate('/');
                                } else {
                                    navigate('/');
                                }
                            }
                        } else {
                            setResponseStatus('Erreur lors de la connexion.');
                        }
                    }

                }).catch(function (error) {
                if (error.response?.status === 401) {
                    setResponseStatus('Mot de passe incorrect')
                } else if (error.response?.status === 404) {
                    setResponseStatus('Utilisateur non trouvé')
                } else if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
        }}
    >
        {({errors, touched}) => (

            <Form className="mt-8 h-auto space-y-4">
                {responseStatus &&
                    <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                }
                <div>
                    <label className="text-gray-800 text-sm mb-2 block">Email</label>
                    <div className="relative flex items-center">
                        <Field name="email" type="text" required
                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                               placeholder="Enter user name"/>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                             className="w-4 h-4 absolute right-4" viewBox="0 0 24 24">
                            <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                            <path
                                d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                data-original="#000000"></path>
                        </svg>
                    </div>
                    <div className={"h-6"}>
                        {errors.email && touched.email ? (
                            <div className="text-red-500 pt-2 text-xs">{errors.email}</div>
                        ) : null}
                    </div>

                </div>

                <div>
                    <label className="text-gray-800 text-sm mb-2 block">Mot de passe</label>
                    <div className="relative flex items-center">
                        <Field name="password" type={visiblePassword ? 'text' : 'password'}
                               required
                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                               placeholder="Enter password"/>
                        <svg onClick={() => setVisiblePassword(!visiblePassword)}
                             xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                             className="w-4 h-4 absolute right-4 cursor-pointer"
                             viewBox="0 0 128 128">
                            <path
                                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                data-original="#000000"></path>
                        </svg>
                    </div>
                    <div className={"h-6"}>
                        {errors.password && touched.password ? (
                            <div
                                className="text-red-500 pt-2 text-xs">{errors.password}</div>
                        ) : null}
                    </div>
                </div>

                <div className="flex flex-wrap items-center justify-end gap-4">
                    <div className="text-sm">
                        <a href="/passwordResetPage"
                           className="text-third hover:underline font-semibold">
                            Mot de passe oublié ?
                        </a>
                    </div>
                </div>

                <div className="!mt-8">
                    <button type="submit"
                            className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-blue-700 hover:bg-blue-800 focus:outline-none">
                        Se connecter en tant qu'Expert
                    </button>
                </div>
                <p className="text-gray-800 text-sm !mt-8 text-center">Vous n'avez pas de
                                                                       compte
                                                                       ? <Link
                        to="/register"
                        className="text-third hover:underline ml-1 whitespace-nowrap font-semibold">Inscrivez-vous
                                                                                                    maintenant</Link>
                </p>
            </Form>
        )}
    </Formik>
</div>*/
