import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import React, {Fragment, useEffect, useState} from "react";
import axios from 'axios'
import logoHbm from "../images/Xperlink-noir-12.webp";
import {getDomaineService} from "../Services/DomaineService";
import apiClient, {decodeResponseBody} from "../Services/apiService";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {toast} from "react-toastify";
import {Step, Stepper, Typography} from "@material-tailwind/react";
import {CogIcon, HomeIcon, UserIcon} from "@heroicons/react/24/outline";
import {Button} from "@mui/material";
import { PDFDocument } from 'pdf-lib';


const compressImage = async (file, maxSizeKB = 500) => {
    const maxSizeBytes = maxSizeKB * 1024;

    // Vérifier si le fichier est une image JPEG ou PNG
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
        throw new Error("Seules les images JPEG et PNG sont supportées.");
    }

    // Si le fichier est déjà en dessous de la taille maximale, le retourner tel quel
    if (file.size <= maxSizeBytes) {
        return file;
    }

    // Charger l'image dans un objet Image
    const image = await new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = () => {
            img.src = reader.result;
        };

        img.onload = () => resolve(img);
        img.onerror = reject;
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });

    // Créer un canvas pour redimensionner l'image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Calculer le facteur d'échelle pour réduire la taille du fichier
    let scaleFactor = 1;
    let compressedBlob;
    let quality = 0.9; // Qualité initiale

    do {
        // Ajuster le facteur d'échelle
        scaleFactor *= 0.9;

        canvas.width = image.width * scaleFactor;
        canvas.height = image.height * scaleFactor;

        // Dessiner l'image redimensionnée sur le canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Compresser l'image en ajustant la qualité
        compressedBlob = await new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                file.type,
                quality
            );
        });

        // Réduire la qualité si nécessaire
        if (compressedBlob.size > maxSizeBytes) {
            quality -= 0.1;
        }

        // Si la qualité devient trop faible, arrêter
        if (quality < 0.1) {
            throw new Error("Impossible de compresser l'image en dessous de 500 Ko.");
        }

    } while (compressedBlob.size > maxSizeBytes);

    // Retourner un nouveau fichier avec l'image compressée
    return new File([compressedBlob], file.name, {type: file.type});
};


const schema = Yup.object().shape({
    firstName: Yup.string().required("Prénom est obligatoire"),
    lastName: Yup.string().required("Nom est obligatoire"),
    titreExpert: Yup.string().required("Titre est obligatoire"),
    descriptionExpert: Yup.string().required("Description est obligatoire"),
    experience: Yup.string().required("l'experience est obligatoire"),
    formation: Yup.string().required("La formation est obligatoire"),
    tarif: Yup.string().required("Le tarif est obligatoire"),
    /*NbrSeanceCumule: Yup.string().required("Le nombre maximal de séances cumulées est obligatoire"),*/
    tel: Yup.string()
        .required("Le numéro de téléphone est obligatoire")
        .matches(/^(?:0)[5-7]\d{8}$/, "Numéro de téléphone invalide"),
    email: Yup.string()
        .required("Email est obligatoire")
        .email("Invalid email format"),
    password: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/
            , "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial")
        .required("Mot de passe est obligatoire"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
    selectedDomaine: Yup.string()
        .required("Le domaine est obligatoire")
        .test("not-empty", "Veuillez choisir un domaine valide", (value) => value !== ""),
    selectedTypeExpert: Yup.string()
        .required("Le type d'expert est obligatoire")
        .test(
            "not-empty",
            "Veuillez choisir un type d'expert valide",
            (value) => value !== ""
        ),
    selectedUnite: Yup.string()
        .required("La duree est obligatoire")
        .test(
            "not-empty",
            "Veuillez choisir un type d'expert valide",
            (value) => value !== ""
        ),
    /*selectedLangue: Yup.string()
        .required("La langue est obligatoire")
        .test(
            "not-empty",
            "Veuillez choisir au moins une langue",
            (value) => value !== ""
        ),*/
    selectedLangue: Yup.array()
        /*.min(1, 'Vous devez sélectionner au moins une langue.')
        .required('Veuillez sélectionner une langue.'),*/
        .test(
            "at-least-one-service",
            "Vous devez sélectionner au moins un service si un domaine est choisi",
            function (value) {
                const {selectedLangue} = this.parent; // Accéder au champ `selectedDomaine`
                if (!selectedLangue) {
                    return true; // Pas besoin de valider si aucun domaine n'est sélectionné
                }
                return value && value.length > 0; // Valider que des services sont sélectionnés
            }
        ),
    selectedService: Yup.array()
        .test(
            "at-least-one-service",
            "Vous devez sélectionner au moins un service si un domaine est choisi",
            function (value) {
                const {selectedDomaine} = this.parent; // Accéder au champ `selectedDomaine`
                if (!selectedDomaine) {
                    return true; // Pas besoin de valider si aucun domaine n'est sélectionné
                }
                return value && value.length > 0; // Valider que des services sont sélectionnés
            }
        ),
    rc: Yup.string().when("selectedTypeExpert", {
        is: (value) => value === "1" || value === "2", // RC requis pour types 1 et 2
        then: (schema) => schema.required("Le RC est obligatoire"),
        otherwise: (schema) => schema.notRequired(),
    }),
    ice: Yup.string().when("selectedTypeExpert", {
        is: (value) => value === "1" || value === "2", // ICE requis pour types 1 et 2
        then: (schema) => schema.required("L'ICE est obligatoire"),
        otherwise: (schema) => schema.notRequired(),
    }),
    rib: Yup.string().when("selectedTypeExpert", {
        is: (value) => value === "1" || value === "2" || value === "3", // RIB requis pour types 1, 2, 3
        then: (schema) => schema
            .required("Le RIB est obligatoire")
            .matches(/^\d{24}$/, "Le RIB doit contenir exactement 24 chiffres")
            .max(24, "Le RIB ne doit pas dépasser 24 caractères")
        ,
        otherwise: (schema) => schema.notRequired(),
    }),
});


const RegisterExpert = () => {
    const [responseStatus, setResponseStatus] = useState(null)
    const [visiblePassword, setVisiblePassword] = useState(false)

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const navigate = useNavigate()
    const [domaineServices, setDomaineServices] = useState([]);
    const [uniteServices, setUniteServices] = useState([]);
    const [selectedUnite, setSelectedUnite] = useState("");

    const [typeExpertServices, setTypeExpertServices] = useState([]);
    const [selectedTypeExpert, setSelectedTypeExpert] = useState("");

    const [certificatServices, setCertificatServices] = useState([]);
    const [selectedCertificat, setSelectedCertificat] = useState([]);

    const [typeFileServices, setTypeFileServices] = useState([]);
    const [selectedTypeFile, setSelectedTypeFile] = useState([]);

    const [langueServices, setLangueServices] = useState([]);
    const [selectedLangue, setSelectedLangue] = useState([]);

    const [competenceServices, setCompetenceServices] = useState([]);
    const [showAddNewInputComp, setShowAddNewInputComp] = useState(false);
    const [showAddNewInputCert, setShowAddNewInputCert] = useState(false);
    const [newCompetence, setNewCompetence] = useState([]);
    const [newCertificat, setNewCertificat] = useState([]);
    const [selectedCompetence, setSelectedCompetence] = useState([]);
    const [selectedDomaine, setSelectedDomaine] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [photo, setPhoto] = useState(null);
    //const navigate = useHashedNavigate();
    const [fileName, setFileName] = useState(""); // Gérer le nom du fichier sélectionné
    const [preview, setPreview] = useState(null);

    const [activeStep, setActiveStep] = React.useState(0);
    const [isLastStep, setIsLastStep] = React.useState(false);
    const [isFirstStep, setIsFirstStep] = React.useState(false);
    const [fileEntries, setFileEntries] = useState([]);

    /*const handleAddFile = (typeId, file) => {
        if (!typeId || !file) return;
        const selectedType = typeFileServices.find((type) => type.idFileType === parseInt(typeId));
        if (selectedType) {
            setFileEntries((prevEntries) => [
                ...prevEntries,
                { id: Date.now(), type: selectedType.nomType, fileName: file.name },
            ]);
        }
        setCurrentFile(null);
    };*/

    /*const handleAddFile = (type, file) => {
        if (!type || !file) {
            alert("Veuillez sélectionner un type et un fichier.");
            return;
        }

        // Valider le type de fichier (MIME)
        if (file.type !== "application/pdf") {
            alert("Seuls les fichiers PDF sont autorisés.");
            return;
        }

        const newEntry = {
            id: Date.now(),
            type,
            file,
            fileName: file.name,
        };

        setFileEntries([...fileEntries, newEntry]);
        setCurrentFile(null); // Réinitialise le champ de fichier
        setCurrentType("");   // Réinitialise le champ de type
    };*/

    const compressPdf = async (file) => {
        // Lire le fichier PDF
        const fileData = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileData);

        // Optimiser le PDF en supprimant les métadonnées
        pdfDoc.setProducer('');
        pdfDoc.setCreator('');

        // Sauvegarder le PDF compressé
        const compressedPdf = await pdfDoc.save({ useObjectStreams: true });

        // Créer un nouvel objet fichier compressé
        return new File([compressedPdf], file.name, { type: 'application/pdf' });
    };
    const handleAddFile = async (typeId, file) => {
        if (!typeId || !file) {
            alert("Veuillez sélectionner un type et un fichier.");
            return;
        }

        // Compresser le fichier PDF
        const compressedFile = await compressPdf(file);

        // Trouver le type de fichier correspondant
        const selectedType = typeFileServices.find(
            (type) => type.idFileType === parseInt(typeId)
        );

        if (selectedType) {
            // Ajouter une nouvelle entrée dans la liste des fichiers
            setFileEntries((prevEntries) => [
                ...prevEntries,
                {
                    id: Date.now(), // Identifiant unique pour l'entrée
                    type: selectedType.nomType, // Nom du type de fichier
                    typeId: typeId, // ID du type de fichier pour l'envoi
                    //fileName: file.name, // Nom du fichier
                    fileName: compressedFile.name,
                    file: file, // Fichier lui-même
                },
            ]);
        } else {
            alert("Type de fichier invalide.");
        }

        // Réinitialiser l’état du fichier courant
        setCurrentFile(null);
    };


    const handleClearAll = () => {
        setFileEntries([]);
    };

    const handleRemoveFile = (id) => {
        setFileEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
    };

    const [currentType, setCurrentType] = useState("");
    const [currentFile, setCurrentFile] = useState(null);

    //const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

    const stepSchemas = [
        // Étape 1 : Informations personnelles
        Yup.object().shape({
            firstName: Yup.string().required("Prénom est obligatoire"),
            lastName: Yup.string().required("Nom est obligatoire"),
            titreExpert: Yup.string().required("Titre est obligatoire"),
            descriptionExpert: Yup.string().required("Description est obligatoire"),
            tel: Yup.string()
                .required("Le numéro de téléphone est obligatoire")
                .matches(/^(?:0)[5-7]\d{8}$/, "Numéro de téléphone invalide"),
            email: Yup.string()
                .required("Email est obligatoire")
                .email("Invalid email format"),
            selectedTypeExpert: Yup.string()
                .required("Le type d'expert est obligatoire")
                .test(
                    "not-empty",
                    "Veuillez choisir un type d'expert valide",
                    (value) => value !== ""
                    ),
            rc: Yup.string().when("selectedTypeExpert", {
            is: (value) => value === "1" || value === "2", // RC requis pour types 1 et 2
            then: (schema) => schema.required("Le RC est obligatoire"),
            otherwise: (schema) => schema.notRequired(),
            }),
            ice: Yup.string().when("selectedTypeExpert", {
                is: (value) => value === "1" || value === "2", // ICE requis pour types 1 et 2
                then: (schema) => schema.required("L'ICE est obligatoire"),
                otherwise: (schema) => schema.notRequired(),
            }),
            rib: Yup.string().when("selectedTypeExpert", {
                is: (value) => value === "1" || value === "2" || value === "3", // RIB requis pour types 1, 2, 3
                then: (schema) => schema
                    .required("Le RIB est obligatoire")
                    .matches(/^\d{24}$/, "Le RIB doit contenir exactement 24 chiffres")
                    .max(24, "Le RIB ne doit pas dépasser 24 caractères"),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),

        // Étape 2 : Paramétrage
        Yup.object().shape({
            experience: Yup.string().required("l'experience est obligatoire"),
            formation: Yup.string().required("La formation est obligatoire"),
            selectedDomaine: Yup.string()
                .required("Le domaine est obligatoire")
                .test("not-empty", "Veuillez choisir un domaine valide", (value) => value !== ""),
            selectedService: Yup.array()
                .test(
                    "at-least-one-service",
                    "Vous devez sélectionner au moins un service si un domaine est choisi",
                    function (value) {
                        const { selectedDomaine } = this.parent;
                        if (!selectedDomaine) {
                            return true;
                        }
                        return value && value.length > 0;
                    }
                ),

        }),

        // Étape 3 : Profil
        Yup.object().shape({
            tarif: Yup.string().required("Le tarif est obligatoire"),
            selectedUnite: Yup.string()
                .required("La duree est obligatoire")
                .test(
                    "not-empty",
                    "Veuillez choisir un type d'expert valide",
                    (value) => value !== ""
                ),
            password: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/,
                    "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
                )
                .required("Mot de passe est obligatoire"),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
            selectedLangue: Yup.array()
                /*.min(1, 'Vous devez sélectionner au moins une langue.')
                .required('Veuillez sélectionner une langue.'),*/
                .test(
                    "at-least-one-service",
                    "Vous devez sélectionner au moins un service si un domaine est choisi",
                    function (value) {
                        const {selectedLangue} = this.parent; // Accéder au champ `selectedDomaine`
                        if (!selectedLangue) {
                            return true; // Pas besoin de valider si aucun domaine n'est sélectionné
                        }
                        return value && value.length > 0; // Valider que des services sont sélectionnés
                    }
                ),
            /*selectedLangue: Yup.array()
                .min(1, 'Vous devez sélectionner au moins une langue.')
                .required('Veuillez sélectionner une langue.'),*/
        }),
    ];

    const handleNext = async (values, setErrors, setTouched) => {
        try {
            // Marquer tous les champs de l'étape comme touchés
            const touchedFields = {};
            Object.keys(stepSchemas[activeStep].fields).forEach((field) => {
                touchedFields[field] = true;
            });
            setTouched(touchedFields);



            // Valider les champs de l'étape actuelle
            await stepSchemas[activeStep].validate(values, { abortEarly: false });

            if (!photo) {
                toast.error("Veuillez ajouter une photo !");
                return;
            }
            // Passer à l'étape suivante
            setActiveStep((prev) => Math.min(prev + 1, stepSchemas.length - 1));
        } catch (validationErrors) {
            // Collecter et définir les erreurs
            const errors = {};
            validationErrors.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            if (!photo) {
                toast.error("Veuillez ajouter une photo !");
                return;
            }
            setErrors(errors);
        }
    };





    const steps = [
        {label: 'Informations personnelles'},
        {label: 'Paramétrage'},
        {label: 'Profil'},
    ];


    const [expertDetails, setExpertDetails] = useState({
        idExpert: "",
        nomExpert: "",
        prenomExpert: "",
        domaineId: "",
        idServices: []
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                const unite = await apiClient.get("unite/list")
                const certificat = await apiClient.get("certificat/listCertificat")
                const competence = await apiClient.get("competence/listCompetence")
                const typeExpert = await apiClient.get("typeExpert/list")
                const langueExpert = await apiClient.get("langue/list")
                const TypeFile = await apiClient.get("typeFile/list")
                setTypeFileServices(TypeFile.data)
                setLangueServices(langueExpert.data)
                setTypeExpertServices(typeExpert.data)
                setUniteServices(unite.data);
                setCertificatServices(certificat.data);
                setCompetenceServices(competence.data);
                setDomaineServices(domainesService);

            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    if (isAuthenticated) {
        return <Navigate to={"/"}/>
    }

    // Function to get the current date and time plus 30 minutes
    const getExpirationDate = (minutesToAdd) => {
        const now = new Date(); // Current date and time
        now.setMinutes(now.getMinutes() + minutesToAdd); // Add minutes
        return now;
    };


    /*const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) { // Limite à 10 MB
                alert("Le fichier est trop volumineux. Maximum 10 Mo.");
                return;
            }
            setPhoto(file); // Mettre à jour l'état avec le fichier
            setFileName(file.name); // Met à jour le nom du fichier
        } else {
            setPhoto(null); // Réinitialise si aucune image n'est ajoutée
            setFileName("");
        }
    };*/

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) return;

        try {
            // Tente de compresser l'image
            const compressedFile = await compressImage(file, 500); // Limite à 500 Ko
            console.log("Image originale :", file);
            console.log("Image compressée :", compressedFile);

            // Utiliser l'image compressée (par exemple, dans un état React)
            setPhoto(compressedFile); // `setPhoto` est une méthode pour gérer l'état du fichier sélectionné
            setFileName(file.name);
            // Créer un aperçu de l'image
            const reader = new FileReader();
            reader.onload = () => setPreview(reader.result);
            reader.readAsDataURL(file);
        } catch (error) {
            console.error("Erreur lors de la compression :", error.message);
            alert(error.message);
            setPhoto(null); // Réinitialise si aucune image n'est ajoutée
            setFileName("");
            setPreview(null);
        }
    };


    // Gestion de l'upload de fichier
    /*const handleFileChange = (e) => {
        setPhoto(e.target.files[0]);
    };*/
    const handleUniteChange = (event) => {
        setSelectedUnite(event.target.value);
    };
    // Gestion des autres champs
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setExpertDetails({...expertDetails, [name]: value});
    };

    const handleCheckboxService = (event) => {
        const {value, checked} = event.target;
        if (checked) {
            // Ajouter l'ID du certificat sélectionné
            setSelectedService((prev) => [...prev, value]);
        } else {
            // Retirer l'ID du certificat si décoché
            setSelectedService((prev) => prev.filter((id) => id !== value));
        }
    };

    const fieldConfigurations = {
        "1": [
            {name: "rc", label: "RC (Registre de Commerce)", placeholder: "Saisir votre RC (Registre de Commerce)"},
            {name: "ice", label: "ICE (Identifiant Commun de l'Entreprise)", placeholder: "Saisir votre ICE"},
            {name: "rib", label: "RIB (Relevé d'Identité Bancaire)", placeholder: "Saisir votre RIB"},
        ],
        "2": [
            {name: "rc", label: "RC (Registre de Commerce)", placeholder: "Saisir votre RC (Registre de Commerce)"},
            {name: "ice", label: "ICE (Identifiant Commun de l'Entreprise)", placeholder: "Saisir votre ICE"},
            {name: "rib", label: "RIB (Relevé d'Identité Bancaire)", placeholder: "Saisir votre RIB"},
        ],
        "3": [
            {name: "rib", label: "RIB (Relevé d'Identité Bancaire)", placeholder: "Saisir votre RIB"},
        ],
    };

    const renderFields = (fields, errors, touched) =>
        fields.map((field) => (
            <div key={field.name} className={"py-2"}>
                <label className="text-gray-800 text-sm mb-2 block">{field.label}<span className="text-red-500">*</span></label>
                <div className="relative flex items-center">
                    <Field
                        name={field.name}
                        type="text"
                        required
                        maxLength={field.name ==="rib" ? "24" : null}
                        className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                        errors[field.name] && touched[field.name]
                            ? "border-[1px] border-red-500"
                            : "border-gray-300"
                    }`}
                        placeholder={field.placeholder}
                    />
                </div>
                <div className="h-4">
                    {errors[field.name] && touched[field.name] && (
                        <div className="text-red-500 pt-2 text-xs">{errors[field.name]}</div>
                    )}
                </div>
            </div>
        ));

    return (
        <>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className=" w-full md:w-2/6">
                        <a href="/"><img
                            src={logoHbm} alt="logo" className='w-52 mb-8 mx-auto block'/>
                        </a>
                        <div className="flex w-full flex-col md:flex-row items-center justify-center px-8 space-y-4 md:space-y-0 md:space-x-8">
                            <Stepper
                                lineClassName="!bg-blue-gray-50"
                                activeLineClassName="bg-black"
                                activeStep={activeStep}
                                isLastStep={(value) => setIsLastStep(value)}
                                isFirstStep={(value) => setIsFirstStep(value)}
                            >
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    //onClick={() => setActiveStep(0)}
                                    >
                                    <UserIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 0 ? "black" : "gray"}
                                        >
                                            Info
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 0 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    //onClick={() => setActiveStep(1)}
                                    >
                                    <HomeIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 1 ? "black" : "gray"}
                                        >
                                            Profile
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 1 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    //onClick={() => setActiveStep(2)}
                                    >
                                    <CogIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 2 ? "black" : "gray"}
                                        >

                                            Parametrage
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 2 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                            </Stepper>
                        </div>
                        <div className="p-8 mt-20 rounded-2xl  bg-white shadow">
                            <h2 className="text-gray-800 text-center text-2xl font-bold">Créer votre compte expert</h2>


                            <Formik
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    titre: '',
                                    description: '',
                                    tel: '',
                                    email: '',
                                    password: '',
                                    passwordConfirmation: '',
                                    selectedDomaine: '',
                                    selectedTypeExpert: '',
                                    selectedUnite: '',
                                    selectedCompetence: [],
                                    tarif: '',
                                    selectedService: [],
                                    selectedLangue: [],
                                    rc: '',
                                    ice: '',
                                    rib: '',
                                    nbrMaxSeanceCumule: '',
                                }}
                                validationSchema={schema}
                                onSubmit={values => {

                                    const formData = new FormData();
                                    // Ajout des détails de l'expert
                                    const expertDetails = {
                                        expert: {
                                            nomExpert: values.lastName,
                                            prenomExpert: values.firstName,
                                            telExpert: values.tel,
                                            titreExpert: values.titreExpert,
                                            descriptionExpert: values.descriptionExpert,
                                            emailExpert: values.email,
                                            villeExpert: values.ville,
                                            payeExpert: values.pays,
                                            iceExpert: values.ice,
                                            rcExpert: values.rc,
                                            ribExpert: values.rib,
                                            nbrMaxSeanceCumule: values.nbrMaxSeanceCumule,
                                            facebook: values.facebook,
                                            instagram: values.instagram,
                                            linkedIn: values.linkedin,
                                        },
                                        domaineId: values.selectedDomaine, // Id du domaine
                                        passwordExpert: values.password, // Mot de passe
                                        idServices: values.selectedService, // Liste des services
                                        idCertificat: selectedCertificat, // Liste des certificats
                                        idLangues: selectedLangue, // Liste des certificats
                                        idUnite: selectedUnite, // ID de l'unité
                                        idTypeExpert: selectedTypeExpert,
                                        tarif: values.tarif, // Tarif
                                        idCompetences: selectedCompetence, // Liste des compétences
                                        nouvelleFormation: {
                                            titreFormation: values.formation // Titre de la nouvelle formation
                                        },
                                        nouvelleExperience: {
                                            titreExperience: values.experience // Titre de la nouvelle expérience
                                        }
                                    };
                                    formData.append('expertDetails', new Blob([JSON.stringify(expertDetails)], {type: 'application/json'}));

                                    // Ajouter la photo si elle existe
                                    if (photo) {
                                        formData.append('photo', photo);
                                    } else {
                                        alert("Veuillez ajouter une photo !");
                                        return;
                                    }

                                    // Ajouter les fichiers avec leurs types
                                    /*fileEntries.forEach((entry, index) => {
                                        formData.append(`files[${index}].file`, entry.file); // Fichier
                                        formData.append(`files[${index}].typeFileId`, entry.type); // ID du type
                                    });*/

                                    fileEntries.forEach((entry) => {
                                        formData.append("files", entry.file); // Fichier
                                        formData.append("fileTypes", entry.typeId); // ID du type de fichier
                                    });

                                    apiClient.post(
                                        `/experts/registerAndAddExpert`,
                                        formData,
                                        {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        }
                                    )
                                        .then((res) => {
                                            if (res.status === 200) {
                                                /*const {
                                                    firstName,
                                                    lastName,
                                                    tel,
                                                    role,
                                                    email
                                                } = decodeJwtToken(res.data.access_token)
                                                setResponseStatus(null)
                                                console.log('User Info:', firstName, lastName, tel, role, email);
                                                if (signIn({
                                                    auth: {
                                                        token: res.data.access_token,
                                                        type: 'Bearer'
                                                    },
                                                    //refresh: res.data.refresh_token,
                                                    userState: {
                                                        email: values.email,
                                                        fullName: firstName + " " + lastName,
                                                        tel: tel,
                                                        role: role
                                                    }
                                                })) {
                                                    window.localStorage.setItem('session_expire_at', getExpirationDate(60))
                                                    window.localStorage.setItem('userFirstName', firstName);  // Storing first name
                                                    window.localStorage.setItem('accessToken', res.data.access_token);
                                                    window.localStorage.setItem('userLastName', lastName);    // Storing last name
                                                    window.localStorage.setItem('userTel', tel);   // Storing last name
                                                    window.localStorage.setItem('userEmail', email);
                                                    window.localStorage.setItem('role', role);    // Storing last name
                                                    const lastRoute = window.localStorage.getItem('lastRoute')
                                                    // Redirection selon le rôle de l'utilisateur
                                                    if (lastRoute) {
                                                        navigate(lastRoute);
                                                    } else {
                                                        if (role === 'EXPERT') {
                                                            navigate('/');
                                                        } else if (role === 'CLIENT') {
                                                            navigate('/');
                                                        } else {
                                                            navigate('/');
                                                        }
                                                    }
                                                } else {
                                                    setResponseStatus('Erreur lors de la connexion.');
                                                }*/
                                                //navigate("/login")
                                                toast.success(res.data);
                                            }
                                        }).catch(function (error) {
                                        if (error?.response) {
                                            console.log(error?.response);
                                            const { status, data } = error.response;

                                            // Décodage des données
                                            /*let decodedData;
                                            try {
                                                decodedData = decodeResponseBody(data);
                                            } catch (decodeError) {
                                                console.error('Erreur lors du décodage des données :', decodeError);
                                                decodedData = 'Impossible de décoder la réponse du serveur.';
                                            }*/

                                            // Gestion des erreurs basées sur le code HTTP
                                            switch (status) {
                                                case 400:
                                                    // Erreur de validation
                                                    setResponseStatus(data || 'Erreur de validation, veuillez vérifier vos données.');
                                                    toast.error(data || 'Erreur de validation, veuillez vérifier vos données.');
                                                    break;

                                                case 409:
                                                    // Conflit : email ou ressource déjà existante
                                                    setResponseStatus(data || 'Cet adresse mail est déjà utilisée.');
                                                    toast.error(data || 'Cet adresse mail est déjà utilisée.');
                                                    break;

                                                case 500:
                                                    // Erreur interne du serveur
                                                    setResponseStatus(data || 'Erreur de serveur interne, veuillez réessayer plus tard.');
                                                    toast.error(data || 'Erreur de serveur interne, veuillez réessayer plus tard.');
                                                    break;

                                                case 503:
                                                    // Service indisponible (exemple : API JWT injoignable)
                                                    setResponseStatus(data || 'Le service est actuellement indisponible, veuillez réessayer plus tard.');
                                                    toast.error(data || 'Le service est actuellement indisponible, veuillez réessayer plus tard.');
                                                    break;

                                                default:
                                                    // Gestion des autres erreurs inattendues
                                                    setResponseStatus(data || 'Une erreur inattendue s\'est produite.');
                                                    toast.error(data || 'Une erreur inattendue s\'est produite.');
                                                    break;
                                            }
                                        } else if (error?.request) {
                                            // Erreur de connexion au serveur (exemple : réseau ou serveur inaccessible)
                                            setResponseStatus('Erreur de connexion au serveur, veuillez réessayer plus tard.');
                                            toast.error('Erreur de connexion au serveur, veuillez réessayer plus tard.');
                                        } else {
                                            // Gestion des erreurs inconnues ou non gérées
                                            console.error('Erreur inconnue :', error.message);
                                            setResponseStatus('Une erreur inconnue s\'est produite.');
                                            toast.error('Une erreur inconnue s\'est produite.');
                                        }
                                    })
                                }}
                            >
                                {({errors, values, setFieldValue ,setTouched ,touched, setFieldTouched,setErrors}) => (

                                    <Form className="mt-8 h-auto space-y-4">
                                        {/*{responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }*/}
                                        {/* Étape conditionnelle */}
                                        {activeStep === 0 && (
                                            <>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Photo<span
                                                        className="text-red-500">*</span></label>

                                                    <div className="flex items-center justify-center w-50 h-50">
                                                        <label
                                                            htmlFor="dropzone-file"
                                                            className="flex flex-col p-2 items-center justify-center w-60 h-60 border-2 border-gray-300 border-dashed rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100"
                                                        >
                                                            <div
                                                                className="flex flex-col items-center justify-center w-full h-full">
                                                                {preview ? (
                                                                    // Afficher l'aperçu si une image est sélectionnée
                                                                    <img
                                                                        src={preview}
                                                                        alt="Aperçu"
                                                                        className="w-full h-full object-cover rounded-full"
                                                                    />
                                                                ) : (
                                                                    // Sinon afficher l'icône par défaut
                                                                    <>
                                                                        <svg
                                                                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                                            aria-hidden="true"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 20 16"
                                                                        >
                                                                            <path
                                                                                stroke="currentColor"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                                            />
                                                                        </svg>
                                                                        <p className="mb-2 text-sm text-center text-gray-500 dark:text-gray-400">
                                                                    <span className="font-medium text-sm">
                                                                        Cliquez pour télécharger
                                                                    </span>
                                                                        </p>
                                                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                                                            PNG, JPG (MAX. 400x400px)
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <input
                                                                id="dropzone-file"
                                                                name={"photo"}
                                                                type="file"
                                                                className="hidden"
                                                                onChange={handleFileChange} // Ajoute un gestionnaire d'événement
                                                                accept="image/png, image/jpeg"
                                                            />
                                                        </label>
                                                    </div>
                                                    {/* Afficher si une image a été ajoutée */}
                                                    <div className="mt-2 text-center">
                                                        {fileName ? (
                                                            /*<p className="text-sm text-green-600">Image ajoutée : {fileName}</p>*/
                                                            <p className="text-sm text-green-600">Image ajoutée</p>
                                                        ) : (
                                                            <p className="text-sm text-gray-500">Aucune image
                                                                                                 ajoutée</p>
                                                        )}
                                                    </div>
                                                    {/*                                            <div className={"h-4"}>
                                                {errors.tel && touched.tel ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.tel}</div>
                                                ) : null}
                                            </div>*/}
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800  text-sm mb-2 block">Prénom<span
                                                            className="text-red-500">*</span> </label>
                                                        <div className="relative flex items-center">
                                                            <Field name="firstName" type="text" required
                                                                   className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                       errors.firstName && touched.firstName
                                                                           ? "border-[1px] border-red-500"
                                                                           : "border-gray-300"
                                                                   }`}
                                                                   placeholder="Enter votre prénom"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.firstName && touched.firstName ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.firstName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Nom<span
                                                            className="text-red-500">*</span></label>
                                                        <div className="relative flex items-center">
                                                            <Field name="lastName" type="text" required
                                                                   className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                       errors.lastName && touched.lastName
                                                                           ? "border-[1px] border-red-500"
                                                                           : "border-gray-300"
                                                                   }`}
                                                                   placeholder="Enter votre nom"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.lastName && touched.lastName ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.lastName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800 text-sm mb-2 block">Téléphone<span
                                                            className="text-red-500">*</span></label>
                                                        <div className="relative flex items-center">
                                                            <Field name="tel" type="text" required
                                                                   className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                       errors.tel && touched.tel
                                                                           ? "border-[1px] border-red-500"
                                                                           : "border-gray-300"
                                                                   }`}
                                                                   placeholder="Saisir votre telephone"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb" viewBox="0 0 24 24"
                                                                 className="w-4 h-4 absolute right-4">
                                                                <path
                                                                    d="M21.707 16.293l-5-5a1 1 0 0 0-1.414 0l-2 2a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414l2-2a1 1 0 0 0 0-1.414l-5-5a1 1 0 0 0-1.414 0C3.105 3.895 2 6.385 2 8c0 6.627 5.373 12 12 12 1.615 0 4.105-1.105 5.707-2.707a1 1 0 0 0 0-1.414z"/>
                                                            </svg>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.tel && touched.tel ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.tel}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800 text-sm mb-2 block">Email<span
                                                            className="text-red-500">*</span></label>
                                                        <div className="relative flex items-center">
                                                            <Field name="email" type="text" required
                                                                   className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                       errors.email && touched.email
                                                                           ? "border-[1px] border-red-500"
                                                                           : "border-gray-300"
                                                                   }`}
                                                                   placeholder="Enter l'email"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 stroke="#bbb" viewBox="0 0 24 24">
                                                                <path
                                                                    d="M2 4c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h20c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H2zm0 2h20l-10 7L2 6zm0 2.236l7.5 5.264L2 18v-9.764zM22 18l-7.5-5.264L22 6v12z"/>
                                                            </svg>

                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.email && touched.email ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800  text-sm mb-2 block">Ville</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="ville" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   placeholder="Enter votre ville"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>
                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Pays</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="pays" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   placeholder="Enter votre pays"/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"w-full"}>
                                                    <label
                                                        className="text-gray-800 text-sm mb-2 block">Titre<span
                                                        className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="titreExpert" type="text" required
                                                               className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                   errors.titreExpert && touched.titreExpert
                                                                       ? "border-[1px] border-red-500"
                                                                       : "border-gray-300"
                                                               }`}
                                                               placeholder="Enter votre titre"/>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.titreExpert && touched.titreExpert ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.titreExpert}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className={"w-full"}>
                                                    <label
                                                        className="text-gray-800 text-sm mb-2 block">Description<span
                                                        className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="descriptionExpert" as="textarea" required
                                                               className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                   errors.descriptionExpert && touched.descriptionExpert
                                                                       ? "border-[1px] border-red-500"
                                                                       : "border-gray-300"
                                                               }`}
                                                               placeholder="Enter votre description"/>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.descriptionExpert && touched.descriptionExpert ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.descriptionExpert}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Type
                                                                                                            expert<span
                                                                className="text-red-500">*</span></label>
                                                        <div className="relative flex items-center">
                                                            <select
                                                                id="area"
                                                                className={`select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                                                    errors.selectedTypeExpert && touched.selectedTypeExpert
                                                                        ? "border-[1px] border-red-500"
                                                                        : "border-gray-300"
                                                                }`}
                                                                value={values.selectedTypeExpert} // Utilisation de Formik values
                                                                onChange={(event) => {
                                                                    const value = event.target.value;
                                                                    setFieldValue("selectedTypeExpert", value); // Mise à jour dans Formik
                                                                    // Si le type sélectionné est "3", définir rc et ice comme vides
                                                                    if (value === "3") {
                                                                        setFieldValue("rc", ""); // Mettre rc à une chaîne vide
                                                                        setFieldValue("ice", ""); // Mettre ice à une chaîne vide
                                                                    }
                                                                    setSelectedTypeExpert(value); // Mise à jour de l'état local
                                                                }} name="selectedTypeExpert"
                                                                onBlur={() => setFieldTouched("selectedTypeExpert", true)} // Gestion du "touch" via Formik
                                                            >
                                                                <option value="">Choisissez votre type d'expert</option>
                                                                {typeExpertServices.map((typeExpert) => (
                                                                    <option key={typeExpert.idTypeExpert}
                                                                            value={typeExpert.idTypeExpert}>
                                                                        {typeExpert.typeExpert}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.selectedTypeExpert && touched.selectedTypeExpert ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.selectedTypeExpert}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {selectedTypeExpert === "" && (
                                                        <p className="text-gray-500 text-sm">
                                                            Veuillez sélectionner un type pour afficher les informations
                                                            associées.
                                                        </p>
                                                    )}
                                                    {selectedTypeExpert && fieldConfigurations[selectedTypeExpert] && (
                                                        <>{renderFields(fieldConfigurations[selectedTypeExpert], errors, touched)}</>
                                                    )}
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Profils Réseaux Sociaux</label>
                                                        <div
                                                            className={"p-4 bg-gray-50 rounded-md border border-gray-200"}>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">LinkedIn</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="linkedin" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           placeholder="Entrez votre LinkedIn"/>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M22 22h-4v-7c0-1.657-1.343-3-3-3s-3 1.343-3 3v7H8V8h4v2.586c.703-.949 2.057-2.586 4-2.586 2.761 0 5 2.239 5 5v9zM2 9h4v13H2zM4 7c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">Instagram</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="instagram" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           placeholder="Entrez votre Instagram"/>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M7 2C4.243 2 2 4.243 2 7v10c0 2.757 2.243 5 5 5h10c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5H7zm10 2a3 3 0 013 3v10a3 3 0 01-3 3H7a3 3 0 01-3-3V7a3 3 0 013-3h10zM12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 2a3 3 0 110 6 3 3 0 010-6zm6-3a1 1 0 11.002 2.002A1 1 0 0118 6z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">Facebook</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="facebook" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           placeholder="Entrez votre Facebook"/>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.993h-2.54v-2.885h2.54V9.68c0-2.507 1.493-3.89 3.777-3.89 1.093 0 2.238.195 2.238.195v2.459h-1.26c-1.242 0-1.63.771-1.63 1.56v1.872h2.773l-.443 2.885h-2.33v6.993C18.343 21.128 22 16.991 22 12z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )}
                                        {/* Étape conditionnelle */}
                                        {activeStep === 1 && (
                                            <>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Expériences
                                                                                                        professionnelles
                                                        <span className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="experience" as="textarea" required
                                                               className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                   errors.experience && touched.experience
                                                                       ? "border-[1px] border-red-500"
                                                                       : "border-gray-300"
                                                               }`}
                                                               placeholder="Saisir votre expériences"/>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                             stroke="#bbb" viewBox="0 0 24 24"
                                                             className="w-4 h-4 absolute top-3 right-4">
                                                            <path
                                                                d="M10 2a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V8a2 2 0 0 0-2-2h-4V4a2 2 0 0 0-2-2h-6zm0 2h6v2h-6V4zM3 8h18v4H3V8zm0 6h18v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-4z"/>
                                                        </svg>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.experience && touched.experience ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.experience}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Formations &
                                                                                                        Éducation
                                                        <span className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="formation" as="textarea" required
                                                               className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md outline-third ${
                                                                   errors.formation && touched.formation
                                                                       ? "border-[1px] border-red-500"
                                                                       : "border-gray-300"
                                                               }`}
                                                               placeholder="Saisir votre formations"/>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                             stroke="#bbb" viewBox="0 0 24 24"
                                                             className="w-4 h-4 absolute top-3 right-4">
                                                            <path
                                                                d="M12 2L1 8l11 6 11-6-11-6zm0 2.236L19.764 8 12 11.764 4.236 8 12 4.236zM3 10.809v4.5c0 2.486 4.03 4.5 9 4.5s9-2.014 9-4.5v-4.5l-2 .999v3.501c0 1.42-3.05 2.5-7 2.5s-7-1.08-7-2.5v-3.501l-2-.999z"/>
                                                        </svg>

                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.formation && touched.formation ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.formation}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Domaine<span
                                                        className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <select
                                                            id="area"
                                                            className={`select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                                                errors.selectedDomaine && touched.selectedDomaine
                                                                    ? "border-[1px] border-red-500"
                                                                    : "border-gray-300"
                                                            }`}
                                                            value={values.selectedDomaine}
                                                            onChange={(event) => {
                                                                const value = event.target.value;
                                                                setFieldValue("selectedDomaine", value); // Mise à jour dans Formik
                                                                setSelectedDomaine(value); // Mise à jour de l'état local
                                                            }}
                                                            onBlur={() => setFieldTouched("selectedDomaine", true)} // Gestion du "touch" via Formik
                                                            name="selectedDomaine"
                                                        >
                                                            <option value="">Choisissez votre domaine</option>
                                                            {domaineServices.map((domaine) => (
                                                                <option key={domaine.idDomaine}
                                                                        value={domaine.idDomaine}>
                                                                    {domaine.nomDomaine}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {errors.selectedDomaine && touched.selectedDomaine ? (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.selectedDomaine}</div>
                                                    ) : null}
                                                </div>
                                                {/*                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Service</label>
                                            <div className="relative  flex items-center">
                                                {domaineServices && (
                                                    <select id="category"
                                                            className="select-box h-12 p-2 w-full font-normal border bg-gray-100 border-gray-300 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            value={selectedService}
                                                            onChange={(e) => setSelectedService(e.target.value)}
                                                    >
                                                        <option value="">Choisissez votre service</option>
                                                        {domaineServices
                                                            .find((domaine) => domaine.idDomaine === parseInt(selectedDomaine))
                                                            ?.services.map((service) => (
                                                                <option key={service.idService}
                                                                        value={service.idService}>
                                                                    {service.nomService}
                                                                </option>
                                                            ))}
                                                    </select>
                                                )}
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.tel && touched.tel ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.tel}</div>
                                                ) : null}
                                            </div>
                                        </div>*/}
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}
                                                    <label className="text-gray-800 text-sm mb-2 block font-medium">
                                                        Choisissez vos services<span className="text-red-500">*</span>
                                                    </label>

                                                    {/* Liste des checkboxes */}
                                                    <div
                                                        className={`bg-gray-100 p-4 rounded-md border border-gray-300 ${
                                                            errors.selectedService && touched.selectedService
                                                                ? "border-[1px] border-red-500"
                                                                : "border-gray-300"
                                                        }`}
                                                    >
                                                        {values.selectedDomaine ? (
                                                            domaineServices
                                                                .find((domaine) => domaine.idDomaine === parseInt(values.selectedDomaine))
                                                                ?.services.map((service) => (
                                                                <div key={service.idService}
                                                                     className="flex items-center mb-2">
                                                                    <input
                                                                        name={"selectedService"}
                                                                        type="checkbox"
                                                                        id={`service-${service.idService}`}
                                                                        value={service.idService}
                                                                        /*onChange={handleCheckboxService}*/
                                                                        onChange={(event) => {
                                                                            const {value, checked} = event.target;
                                                                            if (checked) {
                                                                                setFieldValue("selectedService", [
                                                                                    ...values.selectedService,
                                                                                    value,
                                                                                ]);
                                                                                setSelectedService(value);
                                                                            } else {
                                                                                setFieldValue(
                                                                                    "selectedService",
                                                                                    values.selectedService.filter(
                                                                                        (id) => id !== value
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                        checked={values.selectedService.includes(service.idService.toString())}
                                                                        className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                                        onBlur={() => setFieldTouched("selectedService", true)} // Gestion du "touch" via Formik
                                                                    />
                                                                    <label
                                                                        htmlFor={`service-${service.idService}`}
                                                                        className="ml-2 text-sm text-gray-700"
                                                                    >
                                                                        {service.nomService}
                                                                    </label>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className="text-gray-500 text-sm">
                                                                Veuillez sélectionner un domaine pour afficher les
                                                                services
                                                                associés.
                                                            </p>
                                                        )}
                                                    </div>
                                                    {errors.selectedService && touched.selectedService && (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.selectedService}</div>
                                                    )}

                                                    {/* Résumé des services sélectionnés */}
                                                    <div className="mt-4">
                                                        <p className="text-sm font-medium text-gray-700">
                                                            Services sélectionnés :
                                                        </p>
                                                        {selectedService.length > 0 ? (
                                                            <ul className="list-disc list-inside text-sm text-gray-600">
                                                                {values.selectedService.map((id) => {
                                                                    const serv = domaineServices
                                                                        .find((domaine) => domaine.idDomaine === parseInt(selectedDomaine))
                                                                        ?.services.find((s) => s.idService.toString() === id);
                                                                    // Vérifier si `serv` est défini avant d'afficher l'élément
                                                                    return serv ?
                                                                        <li key={id}>{serv.nomService}</li> : null;
                                                                })}
                                                            </ul>
                                                        ) : (
                                                            <p className="text-sm text-gray-500">Aucun service
                                                                                                 sélectionné</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}

                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Choisissez vos certificats
                                                        </label>
                                                        <a
                                                            className="text-blue-500 cursor-pointer text-sm hover:underline"
                                                            onClick={() => setShowAddNewInputCert(true)}
                                                        >
                                                            Ajouter nouveau
                                                        </a>
                                                    </p>

                                                    {/* Liste des checkboxes */}
                                                    <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                        <select id="area"
                                                                name={""}
                                                                className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    if (selectedValue && !selectedCertificat.includes(selectedValue)) {
                                                                        setSelectedCertificat([...selectedCertificat, selectedValue]);
                                                                    }
                                                                }}

                                                        >
                                                            <option value="">Choisissez votre certificat</option>
                                                            {certificatServices.map((certificat) => (
                                                                <option key={certificat.idCertificat}
                                                                        value={certificat.idCertificat}>
                                                                    {certificat.titreCertificat}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* Ajouter un nouveau certificat */}
                                                    {showAddNewInputCert && (
                                                        <div className="mt-4">
                                                            <input
                                                                type="text"
                                                                placeholder="Entrez une nouvelle certificat"
                                                                value={newCertificat}
                                                                onChange={(e) => setNewCertificat(e.target.value)}
                                                                className="h-12 p-2 font-normal w-full border bg-gray-50 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            />
                                                            <button
                                                                className="mt-2 w-full h-10 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600"
                                                                onClick={async () => {
                                                                    if (newCertificat.trim()) {
                                                                        try {
                                                                            const response = await apiClient.post("/certificat/ajouter", {
                                                                                titreCertificat: newCertificat,
                                                                            });

                                                                            // Vérification de la réponse
                                                                            if (response.status === 200) {
                                                                                const newId = response.data; // ID retourné par le backend
                                                                                if (!newId) {
                                                                                    throw new Error("ID du certificat non défini !");
                                                                                }

                                                                                const newComp = {
                                                                                    idCertificat: newId,
                                                                                    titreCertificat: newCertificat
                                                                                };

                                                                                setCertificatServices([...certificatServices, newComp]); // Ajout à la liste
                                                                                setSelectedCertificat([...selectedCertificat, newId.toString()]); // Sélection directe
                                                                                setNewCertificat(""); // Réinitialisation de l'input
                                                                                setShowAddNewInputCert(false); // Fermeture du champ
                                                                                toast.success("Certificat ajouté avec succès !"); // Message de succès
                                                                            }
                                                                        } catch (error) {
                                                                            if (error.response) {
                                                                                // Si l'erreur provient de la réponse du backend (exemple : 400 BAD REQUEST)
                                                                                toast.error(error.response.data); // Affiche le message d'erreur
                                                                            } else {
                                                                                // Si l'erreur est liée à un problème réseau ou autre
                                                                                toast.error("Une erreur réseau est survenue.");
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                            >
                                                                Ajouter
                                                            </button>
                                                        </div>
                                                    )}

                                                    {/* Résumé des certificats sélectionnés */}
                                                    <div className="mt-4">
                                                        <p className="text-sm font-medium text-gray-700 flex items-center justify-between">
                                                            <span>Certificats sélectionnés :</span>
                                                            <button
                                                                className="text-red-500 text-sm hover:underline"
                                                                onClick={() => setSelectedCertificat([])} // Clear toutes les compétences sélectionnées
                                                            >
                                                                Clear all
                                                            </button>
                                                        </p>
                                                        {selectedCertificat.length > 0 ? (
                                                            <ul className="list-disc list-inside text-sm text-gray-600">
                                                                {selectedCertificat.map((id) => {
                                                                    const cert = certificatServices.find(
                                                                        (c) => c.idCertificat.toString() === id
                                                                    );
                                                                    return (cert ? <li key={id}
                                                                                       className={"flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"}
                                                                        >{cert?.titreCertificat}
                                                                            <button
                                                                                className="text-red-500 right-0 ml-2"
                                                                                onClick={() =>
                                                                                    setSelectedCertificat(selectedCertificat.filter((item) => item !== id))
                                                                                }
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5"/>
                                                                            </button>

                                                                        </li> : null
                                                                    )
                                                                })}
                                                            </ul>
                                                        ) : (
                                                            <p className="text-sm text-gray-500">Aucun certificat
                                                                                                 sélectionné</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Choisissez vos compétence
                                                        </label>
                                                        <a
                                                            className="text-blue-500 cursor-pointer text-sm hover:underline"
                                                            onClick={() => setShowAddNewInputComp(true)}
                                                        >
                                                            Ajouter nouveau
                                                        </a>
                                                    </p>

                                                    {/* Liste des checkboxes */}
                                                    <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                        <select id="area"
                                                                className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    if (selectedValue && !selectedCompetence.includes(selectedValue)) {
                                                                        setSelectedCompetence([...selectedCompetence, selectedValue]);
                                                                    }
                                                                }}
                                                        >
                                                            <option value="">Choisissez votre compétence</option>
                                                            {competenceServices.map((competence) => (
                                                                <option key={competence.idCompetence}
                                                                        value={competence.idCompetence}>
                                                                    {competence.titreCompetence}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* Ajouter un nouveau certificat */}
                                                    {showAddNewInputComp && (
                                                        <div className="mt-4">
                                                            <input
                                                                type="text"
                                                                placeholder="Entrez une nouvelle compétence"
                                                                value={newCompetence}
                                                                onChange={(e) => setNewCompetence(e.target.value)}
                                                                className="h-12 p-2 font-normal w-full border bg-gray-50 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            />
                                                            <button
                                                                className="mt-2 w-full h-10 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600"
                                                                onClick={async () => {
                                                                    if (newCompetence.trim()) {
                                                                        try {
                                                                            const response = await apiClient.post("/competence/ajouter", {
                                                                                titreCompetence: newCompetence,
                                                                            });

                                                                            // Vérification de la réponse
                                                                            if (response.status === 200) {
                                                                                const newId = response.data; // ID retourné par le backend
                                                                                if (!newId) {
                                                                                    throw new Error("ID du competence non défini !");
                                                                                }

                                                                                const newComp = {
                                                                                    idCompetence: newId,
                                                                                    titreCompetence: newCompetence
                                                                                };

                                                                                setCompetenceServices([...competenceServices, newComp]); // Ajouter dans la liste
                                                                                setSelectedCompetence([...selectedCompetence, newId.toString()]); // Sélectionner directement
                                                                                setNewCompetence(""); // Réinitialiser l'input
                                                                                setShowAddNewInputComp(false); // Masquer le champ
                                                                                toast.success("Competence ajouté avec succès !"); // Message de succès
                                                                            }
                                                                        } catch (error) {
                                                                            if (error.response) {
                                                                                // Si l'erreur provient de la réponse du backend (exemple : 400 BAD REQUEST)
                                                                                toast.error(error.response.data); // Affiche le message d'erreur
                                                                            } else {
                                                                                // Si l'erreur est liée à un problème réseau ou autre
                                                                                toast.error("Une erreur réseau est survenue.");
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter
                                                            </button>
                                                        </div>
                                                    )}

                                                    {/* Résumé des certificats sélectionnés */}
                                                    <div className="mt-4">
                                                        <p className="text-sm font-medium text-gray-700 flex items-center justify-between">
                                                            <span>Compétence sélectionnés :</span>
                                                            <button
                                                                className="text-red-500 text-sm hover:underline"
                                                                onClick={() => setSelectedCompetence([])} // Clear toutes les compétences sélectionnées
                                                            >
                                                                Clear all
                                                            </button>
                                                        </p>
                                                        {selectedCompetence.length > 0 ? (
                                                            <ul className="list-disc list-inside text-sm text-gray-600">
                                                                {selectedCompetence.map((id) => {
                                                                    const comp = competenceServices.find(
                                                                        (c) => c.idCompetence.toString() === id
                                                                    );
                                                                    return (comp ? <li key={id}
                                                                                       className={"flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"}
                                                                        >{comp?.titreCompetence}
                                                                            <button
                                                                                className="text-red-500"
                                                                                onClick={() =>
                                                                                    setSelectedCompetence(selectedCompetence.filter((item) => item !== id))
                                                                                }
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5"/>
                                                                            </button>

                                                                        </li> : null
                                                                    )
                                                                })}
                                                            </ul>
                                                        ) : (
                                                            <p className="text-sm text-gray-500">Aucun compétence
                                                                                                 sélectionné</p>
                                                        )}
                                                    </div>

                                                </div>

                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm block font-medium">Pièce
                                                                                                                   à
                                                                                                                   joindre</label>
                                                    </p>

                                                    <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                        <div
                                                            className="flex flex-row space-x-5 items-center justify-center">
                                                            <div className="w-full">
                                                                <label className="text-gray-800 text-sm mb-2 block">Type
                                                                                                                    de
                                                                                                                    fichier</label>
                                                                <div className="relative flex items-center">
                                                                    <select
                                                                        id="area"
                                                                        name="selectedTypeFile"
                                                                        className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                        value={currentType}
                                                                        onChange={(e) => setCurrentType(e.target.value)}
                                                                    >
                                                                        <option value="">Choisissez un type</option>
                                                                        {typeFileServices.map((typeFile) => (
                                                                            <option key={typeFile.idFileType}
                                                                                    value={typeFile.idFileType}>
                                                                                {typeFile.nomType}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="w-full">
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">Fichier</label>
                                                                <div className="relative flex items-center">
                                                                    <input
                                                                        id="dropzone-file"
                                                                        name="file"
                                                                        type="file"
                                                                        //onChange={(e) => setCurrentFile(e.target.files[0])}
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file.size > 2 * 1024 * 1024) { // Limite de 2 Mo
                                                                                alert("Le fichier dépasse la taille maximale autorisée (2 Mo).");
                                                                                return;
                                                                            }
                                                                            setCurrentFile(file);
                                                                        }}
                                                                        accept="application/pdf" // Accepter uniquement les fichiers PDF
                                                                        className="hidden"
                                                                    />
                                                                    <label
                                                                        htmlFor="dropzone-file"
                                                                        className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-5 w-5 mr-2 text-gray-500"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M12 4v16m8-8H4"
                                                                            />
                                                                        </svg>
                                                                        {currentFile ? (
                                                                            <p>{currentFile.name}</p>
                                                                        ): (
                                                                            <p>Choisir un fichier</p>
                                                                        )}

                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <button
                                                            type="button" // Empêche la soumission du formulaire
                                                            className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-md hover:bg-blue-600"
                                                            onClick={() => handleAddFile(currentType, currentFile)}
                                                        >
                                                            Ajouter
                                                        </button>
                                                    </div>

                                                    <div className="mt-6">
                                                        <p className="text-sm font-medium text-gray-700 flex items-center justify-between">
                                                            <span>Fichiers ajoutés</span>
                                                            <button
                                                                className="text-red-500 text-sm hover:underline"
                                                                onClick={handleClearAll}
                                                            >
                                                                Clear all
                                                            </button>
                                                        </p>

                                                        {fileEntries.length > 0 ? (
                                                            <div>
                                                                <ul className="list-disc list-inside text-sm text-gray-600">
                                                                    {fileEntries.map((entry) => (
                                                                        <li key={entry.id}
                                                                            className={"flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"}
                                                                        >
                                                                            <p>
                                                                                <span
                                                                                    className={"text-black font-medium font-poppins"}>{entry.type} :</span> {entry.fileName}
                                                                            </p>
                                                                            <button
                                                                                className="text-red-500 hover:underline"
                                                                                onClick={() => handleRemoveFile(entry.id)}
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5"/>
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <p className="text-gray-500 text-sm">Aucun fichier ajouté
                                                                                                 pour le moment.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/* Étape conditionnelle */}
                                        {activeStep === 2 && (
                                            <>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Durée de la
                                                                                                            consultation<span
                                                                className="text-red-500">*</span></label>
                                                        <div className="relative  flex items-center">
                                                            <select id="area"
                                                                    name="selectedUnite"
                                                                    className={`select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                                                        errors.selectedUnite && touched.selectedUnite
                                                                            ? "border-[1px] border-red-500"
                                                                            : "border-gray-300"
                                                                    }`}
                                                                    value={values.selectedUnite}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        setFieldValue("selectedUnite", value); // Mise à jour dans Formik
                                                                        setSelectedUnite(value); // Mise à jour de l'état local
                                                                    }}
                                                                    onBlur={() => setFieldTouched("selectedUnite", true)} // Gestion du "touch" via Formik

                                                            >
                                                                <option value="">Choisissez votre durée</option>
                                                                {uniteServices.map((unite) => (
                                                                    <option key={unite.idUnite}
                                                                            value={unite.idUnite}>
                                                                        {unite.valeurEnMinutes} minutes
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.selectedUnite && touched.selectedUnite ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.selectedUnite}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Tarif
                                                                                                            (DH)<span
                                                                className="text-red-500">*</span></label>
                                                        <div className="relative flex items-center">
                                                            <Field name="tarif" type="number"
                                                                   className={`w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third ${
                                                                       errors.tarif && touched.tarif
                                                                           ? "border-[1px] border-red-500"
                                                                           : "border-gray-300"
                                                                   }`}
                                                                   placeholder="Enter votre tarif"/>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.tarif && touched.tarif ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.tarif}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Nombre
                                                                                                            maximal de
                                                                                                            séances
                                                                                                            cumulées{/*<span
                                                                className="text-red-500">*</span>*/}</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="nbrMaxSeanceCumule" type="number"
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   defaultValue={1}
                                                                   min={1}
                                                                   max={4}
                                                                   placeholder="Nombre maximal de séances cumulées (min : 1, max : 4)"/>
                                                        </div>
                                                        <div className={"h-4"}>
                                                            {errors.nbrMaxSeanceCumule && touched.nbrMaxSeanceCumule ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.nbrMaxSeanceCumule}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Sélectionnez vos langues de communication<span
                                                            className="text-red-500">*</span>
                                                        </label>
                                                    </p>
                                                    {/* Liste des checkboxes */}
                                                    <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                        <select id="area"
                                                                name={""}
                                                                onBlur={() => setFieldTouched('selectedLangue', true)}
                                                                className={`select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                                                    errors.selectedLangue && touched.selectedLangue
                                                                        ? "border-[1px] border-red-500"
                                                                        : "border-gray-300"
                                                                }`}
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    if (selectedValue && !selectedLangue.includes(selectedValue)) {
                                                                        setSelectedLangue([...selectedLangue, selectedValue]);
                                                                        setFieldValue('selectedLangue', [
                                                                            ...values.selectedLangue,
                                                                            selectedValue,
                                                                        ]);

                                                                    }
                                                                }}

                                                        >
                                                            <option value="">Sélectionnez vos langues</option>
                                                            {langueServices.map((langue) => (
                                                                <option key={langue.idLangue}
                                                                        value={langue.idLangue}>
                                                                    {langue.nomLangue}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* Ajouter un nouveau certificat */}
                                                    {errors.selectedLangue && touched.selectedLangue && (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.selectedLangue}</div>
                                                    )}

                                                    {/* Résumé des certificats sélectionnés */}
                                                    <div className="mt-4">
                                                        <p className="text-sm font-medium text-gray-700 flex items-center justify-between">
                                                            <span>Langues sélectionnés :</span>
                                                            <button
                                                                className="text-red-500 text-sm hover:underline"
                                                                onClick={() => {
                                                                    setSelectedLangue([]);
                                                                    setFieldValue('selectedLangue', []);
                                                                    setFieldTouched('selectedLangue', true);
                                                                }
                                                            } // Clear toutes les compétences sélectionnées
                                                            >
                                                                Clear all
                                                            </button>
                                                        </p>
                                                        {selectedLangue.length > 0 ? (
                                                            <ul className="list-disc list-inside text-sm text-gray-600">
                                                                {selectedLangue.map((id) => {
                                                                    const lang = langueServices.find(
                                                                        (c) => c.idLangue.toString() === id
                                                                    );
                                                                    return (lang ? <li key={id}
                                                                                       className={"flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"}
                                                                        >{lang?.nomLangue}
                                                                            <button
                                                                                className="text-red-500 right-0 ml-2"
                                                                                onClick={() => {
                                                                                    /*setSelectedLangue(selectedLangue.filter((item) => item !== id));*/
                                                                                    const updatedLangues = selectedLangue.filter((item) => item !== id);
                                                                                    setSelectedLangue(updatedLangues);
                                                                                    setFieldValue('selectedLangue', updatedLangues); // Met à jour Formik
                                                                                    setFieldTouched('selectedLangue', true); // Marque le champ comme touché
                                                                                    }
                                                                                }
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5"/>
                                                                            </button>

                                                                        </li> : null
                                                                    )
                                                                })}
                                                            </ul>
                                                        ) : (
                                                            <p className="text-sm text-gray-500">Aucun langues
                                                                                                 sélectionné</p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/*<div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                             Titre
                                            <label className="text-gray-800 text-sm mb-2 block font-medium">
                                                Choisissez vos compétence
                                            </label>


                                             Liste des checkboxes
                                            <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                {competenceServices.map((competence) => (
                                                    <div key={competence.idCompetence}
                                                         className="flex items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`certificat-${competence.idCompetence}`}
                                                            value={competence.idCompetence}
                                                            onChange={handleCheckboxCompetence}
                                                            checked={selectedCompetence.includes(
                                                                competence.idCompetence.toString()
                                                            )} // Vérifie si l'ID est sélectionné
                                                            className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                        />
                                                        <label
                                                            htmlFor={`certificat-${competence.idCompetence}`}
                                                            className="ml-2 text-sm text-gray-700"
                                                        >
                                                            {competence.titreCompetence}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>

                                             Résumé des certificats sélectionnés
                                            <div className="mt-4">
                                                <p className="text-sm font-medium text-gray-700">
                                                    Competence sélectionnés :
                                                </p>
                                                {selectedCompetence.length > 0 ? (
                                                    <ul className="list-disc list-inside text-sm text-gray-600">
                                                        {selectedCompetence.map((id) => {
                                                            const comp = competenceServices.find(
                                                                (c) => c.idCompetence.toString() === id
                                                            );
                                                            return <li key={id}>{comp?.titreCompetence}</li>;
                                                        })}
                                                    </ul>
                                                ) : (
                                                    <p className="text-sm text-gray-500">Aucun certificat
                                                                                         sélectionné</p>
                                                )}
                                            </div>
                                        </div>*/}
                                                {/*                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Competence</label>
                                            <div className="relative  flex items-center">
                                                <select id="area"
                                                        className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-gray-700 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                        value={selectedCompetence}
                                                        onChange={(event) => setSelectedCompetence(event.target.value)} // Gestionnaire d'événement
                                                >
                                                    <option value="">Choisissez votre unite</option>
                                                    {competenceServices.map((competence) => (
                                                        <option key={competence.idCompetence}
                                                                value={competence.idCompetence}>
                                                            {competence.titreCompetence}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.competence && touched.competence ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.competence}</div>
                                                ) : null}
                                            </div>
                                        </div>*/}


                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Mot de
                                                                                                        passe<span
                                                            className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="password"
                                                               type={visiblePassword ? 'text' : 'password'}
                                                               required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               placeholder="Enter password"/>
                                                        <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                             xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                             stroke="#bbb"
                                                             className="w-4 h-4 absolute right-4 cursor-pointer"
                                                             viewBox="0 0 128 128">
                                                            <path
                                                                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                                data-original="#000000"></path>
                                                        </svg>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.password && touched.password ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Mot de
                                                                                                        passe<span
                                                            className="text-red-500">*</span></label>
                                                    <div className="relative flex items-center">
                                                        <Field name="passwordConfirmation" type="password" required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               placeholder="Enter password"/>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.passwordConfirmation}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/* Boutons de navigation */}
                                        <div className="mt-32 flex justify-between">
                                            <Button
                                                className="bg-black"
                                                onClick={handlePrev}
                                                disabled={isFirstStep}
                                            >
                                                Prev
                                            </Button>
                                            {isLastStep ? (
                                                <button type="submit"
                                                        className="py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                    Soumettre
                                                </button>
                                            ) : (
                                                <Button className="bg-black"
                                                        onClick={() => handleNext(values, setErrors, setTouched)}
                                                >
                                                    Next
                                                </Button>
                                            )}
                                        </div>
                                        {/*<div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                S'inscrire
                                            </button>
                                        </div>*/}
                                        {/*                                        <p className="text-gray-800 text-sm !mt-8 text-center">Vous avez déja un compte
                                                                                               ?
                                            <Link to="/login"
                                                  className="text-third hover:underline ml-1 whitespace-nowrap font-semibold">Connecter-vous
                                                                                                                              maintenant</Link>
                                        </p>*/}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <Link to='/'
                                  className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
                                  title="Return Home">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                                <span>Return Home</span>
                            </Link>
                        </div>
                        {/*                        <StepperWithIcon></StepperWithIcon>*/}
                    </div>

                </div>
            </div>
        </>
    );
}

export default RegisterExpert;