import React, { useState } from 'react';
import FooterBar from "./FooterBar";
import HeaderServices from "./HeaderServices";
import NosDomaines from "./NosDomaines";
import NosServicesDetails from "./NosServicesDetails";
import {Helmet} from "react-helmet";
import ChatBoot from "../ChatBot/ChatBot";

export default function PageNosServices() {
    const [selectedDomaine, setSelectedDomaine] = useState('');
    return (
        /*<div className="">
            <HeaderServices></HeaderServices>
            <NosDomaines></NosDomaines>
            <NosServicesDetails></NosServicesDetails>
            <FooterBar></FooterBar>
        </div>*/
        <div className="">
            <Helmet>
                <title>Nos services - Experlik.ma</title>
            </Helmet>
            <HeaderServices/>
            <NosDomaines setSelectedDomaine={setSelectedDomaine}/>
            <NosServicesDetails selectedDomaine={selectedDomaine} setSelectedDomaine={setSelectedDomaine}/>
            <FooterBar/>
            <ChatBoot />
        </div>
    );
}

