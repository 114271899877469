import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import axios from 'axios';
import backImgTel from "../../images/Union_tel2.webp";
import backImg from "../../images/competencies.webp";
import Navbar from "../Pages/Navbar";
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, useLocation, useNavigate } from 'react-router';
import AvisExpertPopUp from '../AvisExpert';
import AvisCLientPopUp from '../AvisClient';
import apiClient from "../../Services/apiService";
import { STATUS_NOK, STATUS_OK, TRACER_MEET_CONNECTION, TRACER_MEET_END } from '../../utils/eventsTypes';
import { getPublicIP } from '../../utils/getIpAdress';
import { jwtDecode } from 'jwt-decode';
// Fonction de simulation de vérification
const mockVerifyCredentials = (password) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (password === "password") {
                resolve({ status: 200 });
            } else {
                reject(new Error('Invalid credentials'));
            }
        }, 1000);
    });
};
const handleOpenToastSuccess = () => {
    toast.success('Merci pour votre retour ! Votre avis a été enregistré avec succès !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const handleOpenToastFailed = (message) => {
    toast.error(message || 'Oops ! Quelque chose s\'est mal passé. Veuillez réessayer.', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: "VerificationPopUp"
    });
};
const VerificationPopup = ({ open, onClose, onVerified, roomName, updateInfoVisioCall, affiliation }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [showPopUpExpert, setShowPopUpExpert] = useState(false)
    const [idReservation, setIdReservation] = useState(null)
    const [showPopUpClient, setShowPopUpClient] = useState(false)
    const handleClientPopUpClose = () => {
        setShowPopUpClient(false)
    }
    const handleExpertPopUpClose = () => {
        setShowPopUpExpert(false)
    }
    const navigate = useNavigate()
    // Function to extract and format the dateDebut and dateFin
    const formatDateTime = (jour, time) => {
        // Extract the date part
        const date = jour.split(" ")[0];
        // Combine date and time, and add the 'Z' suffix to indicate UTC time
        const dateTime = `${date}T${time}:00Z`;
        return dateTime;
    };
    const location = useLocation();

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const traceEvent = async (status, message) => {
        const token = getQueryParams(location.search).get('jwt');
        let decoded = jwtDecode(token)
        console.log(decoded)
        let eventPayload;
        // Check if `ids` is a comma-separated list or a single number                // Build the payload
        eventPayload = {
          clientEmail: decoded.context.user.email,
          eventType: TRACER_MEET_CONNECTION,
          ipAddress: await getPublicIP(), // Optional
          parameter: `Affiliation : ${decoded.context.user.affiliation} / userMail : ${decoded.context.user.email}`,
          status: status,
          description: message,
          roomName: roomName
        };
    
        console.log(eventPayload)
    
        try {
          const response = await apiClient.post("/events/trace", eventPayload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (error) {
          // Handle error response
          if (error.response) {
            // Server returned an error response
            console.error(error.response.data);
          } else {
            // Network or other error
            console.error("An unexpected error occurred");
          }
        }
    
      };

    const handleSubmit = async () => {
        /*try {
            // const response = await axios.post('/api/meetings/verify', { email, password });
            const response = await mockVerifyCredentials(password);
            if (response.status === 200) {
                onVerified();
            }
        } catch (error) {
            setError('Invalid credentials. Please try again.');
        }*/
        const body = {
            roomName: roomName,
            password: password,
            affiliation: affiliation
        };
        try {
            const response = await apiClient.post("/infoVisioCall/validatePassword", body);
            onVerified();
            // Extract and format dateDebut and dateFin
            //const dateDebut = formatDateTime("2025-01-13 00:00:00.0", "10:00");//formatDateTime(response.data.jour, response.data.debut);
            //const dateFin = formatDateTime("2025-01-13 00:00:00.0", "15:54"); //formatDateTime(response.data.jour, response.data.fin);

            const dateDebut = formatDateTime(response.data.jour, response.data.debut);
            const dateFin = formatDateTime(response.data.jour, response.data.fin);
            traceEvent(STATUS_OK,"L'individu s'est joint au lien du reunion avec succes.")

            //traceEvent(STATUS_OK,"L'individu s'est joint a la reunion avec succes.")

            updateInfoVisioCall(dateDebut, dateFin)
            //setResponseMessage(response.data); // Assuming response.data contains the message
        } catch (error) {
            if (error.response) {
                // The request was made, and the server responded with a status code
                // that falls out of the range of 2xx
                //setResponseMessage(`Error: ${error.response.data}`);
                // Handle different status codes
                switch (error.response.status) {
                    case 400:
                        //setResponseMessage("Le mot de passe est invalide");
                        handleOpenToastFailed("Le mot de passe est invalide")
                        traceEvent(STATUS_NOK,"Le mot de passe est invalide.")
                        break;
                    case 410: // GONE
                        //setResponseMessage("Le meet a expiré");
                        handleOpenToastFailed("Le meet a expiré")
                        traceEvent(STATUS_NOK,"Le meet a expire.")
                        setTimeout(() => {
                            navigate('/')
                        }, 5000)
                        break;
                    case 406: // Sans avis expert
                        //setResponseMessage("Le meet a expiré");
                        handleOpenToastFailed("La réunion a expiré. Merci de bien vouloir donner votre avis. Vous serez redirigé vers la page des avis dans quelques instants.")
                        traceEvent(STATUS_NOK,"La reunion a expire.")
                        // Wait for 5 seconds (5000 milliseconds) before executing the second instruction
                        setTimeout(() => {
                            setShowPopUpExpert(true)
                            setIdReservation(error.response.data.id)
                        }, 5000);
                        break;
                    case 408: // Sans avis clinet
                        //setResponseMessage("Le meet a expiré");
                        handleOpenToastFailed("La réunion a expiré. Merci de bien vouloir donner votre avis. Vous serez redirigé vers la page des avis dans quelques instants.")
                        traceEvent(STATUS_NOK,"La reunion a expire.")
                        // Wait for 5 seconds (5000 milliseconds) before executing the second instruction
                        setTimeout(() => {
                            setShowPopUpClient(true)
                            setIdReservation(error.response.data.id)
                        }, 5000);
                        break;
                    case 404: // NOT FOUND
                        //setResponseMessage("Il n'y a pas de meet avec ce nom");
                        handleOpenToastFailed("Il n'y a pas de meet avec ce nom")
                        traceEvent(STATUS_NOK,"Il n'y a pas de meet avec ce nom.")
                        setTimeout(() => {
                            navigate('/')
                        }, 5000)
                        break;
                    default:
                        console.log("Erreur: " + error.response.data);
                        handleOpenToastFailed("Serveur erreur")
                        break;
                }
            } else if (error.request) {
                // The request was made but no response was received
                //setResponseMessage('Error: No response from server');
                handleOpenToastFailed("Erreur interne du serveur, veuillez réessayer plus tard.")
            } else {
                // Something happened in setting up the request that triggered an Error
                setResponseMessage(`Erreur interne du serveur, veuillez réessayer plus tard.`);
            }
        }
    };
    const dialogStyles = {
        backgroundColor: '#A52A2A', // Changer la couleur de fond selon vos besoins
    };
    return (
        <>
            <style jsx>{`
                @media (max-width: 768px) {
                    .dialog-container {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    .dialog-container {
                        background-image: url(${backImg});
                        background-size: cover;
                    }
                }
            `}</style>
            <Dialog open={open} onClose={onClose} className="dialog-container" fullWidth>
                <DialogTitle className="text-2xl font-bold text-center text-gray-800">
                    Entrer dans la réunion
                </DialogTitle>
                <DialogContent>
                    {responseMessage && <p className='text-red-500 mb-2'> {responseMessage} </p>}
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        className=" bg-gray-50 " placeholder="Saisir votre password"
                        required
                        margin="dense"
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                </DialogActions>
                {showPopUpExpert && <AvisExpertPopUp id={idReservation} handleClose={handleExpertPopUpClose}/>}
                {showPopUpClient && <AvisCLientPopUp id={idReservation} handleClose={handleClientPopUpClose} />}
            </Dialog>
            <ToastContainer containerId={"VerificationPopUp"} />
        </>
    );
};
export default VerificationPopup;