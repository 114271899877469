import React from 'react';

export default function StepSkills({ values, errors, touched }) {
    return (
        <div>
            <label className="text-gray-800 text-sm mb-2 block">Compétences</label>
            <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                <ul>
                    <li>Compétence 1</li>
                    <li>Compétence 2</li>
                    <li>Compétence 3</li>
                </ul>
            </div>
        </div>
    );
}
