import React from 'react';
import { Stepper, Step } from '@mui/material'; // Remplacez par votre bibliothèque Stepper si nécessaire

export default function StepperWithIcon2({ activeStep, setActiveStep, steps }) {
    return (
        <div className="w-full py-4 px-8">
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => setActiveStep(index)}>
                        <div>{step.label}</div>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
