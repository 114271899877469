import React, { useEffect, useState } from "react";
import { getTime } from "date-fns";
import apiClient from "../Services/apiService";
import { Target } from "lucide-react";


const Countdown = ({ targetDate }) => {
  //const [targetDate, setTargetDate] = useState(iniatialeDate);
  const [timeLeft, setTimeLeft] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null)
  //let timeToUpdate = new Date(iniatialeDate).getTime() - new Date().getTime() - (5 * 60 * 1000) 
  let pollingInterval = 4 * 60 * 1000

  function getDateInTimezone(timezone) {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    const parts = formatter.formatToParts(now);
    const year = parts.find((p) => p.type === "year").value;
    const month = parts.find((p) => p.type === "month").value;
    const day = parts.find((p) => p.type === "day").value;
    const hour = parts.find((p) => p.type === "hour").value;
    const minute = parts.find((p) => p.type === "minute").value;
    const second = parts.find((p) => p.type === "second").value;
    const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    return new Date(dateString);
  }

  const calculateTimeLeft = () => {
    const difference = targetDate - new Date().getTime();
    console.log(difference)
    if (difference <= 0) return null;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  useEffect(() => {
    // Update timeLeft immediately when the component loads
    setTimeLeft(calculateTimeLeft());

    // Recalculate timeLeft every second for live updates
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [targetDate]);

  /*useEffect(() => {
    let notificationShown = false

    // Fetch the latest expiration date every 20 seconds
    const fetchExpirationDate = async () => {
      try {
        const response = await apiClient.get(`/infoVisioCall/getExpirationDate?roomName=${roomName}`);
        if (response.data && response.data.expiration_date_bonus) {
          setTargetDate(response.data.expiration_date_bonus); // Update target date
          notificationShown = true;
        } else if (response.data && response.data.expiration_date) {
          setTargetDate(response.data.expiration_date); // Update target date
        }
      } catch (error) {
        console.error("Failed to fetch expiration date:", error);
      }
    };

    fetchExpirationDate(); // Execute once after `timeToUpdate`

    // Poll API for updates every minute
    const pollingIntervalId = setInterval(fetchExpirationDate, pollingInterval);
    // Cleanup timeouts and interval when component unmounts
    return () => {
      clearInterval(pollingIntervalId);
    };
  }, [roomName]); */

  if (!timeLeft) return <p className="text-center text-red-500"></p>;

  return (
    <div className="flex justify-center space-x-4">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">{value}</span>
          <span className="text-sm uppercase text-gray-50">{unit}</span>
        </div>
      ))}
    </div>
  );
};

export default Countdown;


/*const Countdown = ({ iniatialeDate, roomName }) => {
  const [targetDate, setTargetDate] = useState(iniatialeDate);
  const [timeLeft, setTimeLeft] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null)
  //let timeToUpdate = new Date(iniatialeDate).getTime() - new Date().getTime() - (5 * 60 * 1000) 
  let pollingInterval = 4 * 60 * 1000

  function getDateInTimezone(timezone) {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    const parts = formatter.formatToParts(now);
    const year = parts.find((p) => p.type === "year").value;
    const month = parts.find((p) => p.type === "month").value;
    const day = parts.find((p) => p.type === "day").value;
    const hour = parts.find((p) => p.type === "hour").value;
    const minute = parts.find((p) => p.type === "minute").value;
    const second = parts.find((p) => p.type === "second").value;
    const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    return new Date(dateString);
  }

  const calculateTimeLeft = () => {
    const difference =
      new Date(targetDate).getTime() - new Date().getTime();
    console.log(difference)
    if (difference <= 0) return null;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  useEffect(() => {
    // Update timeLeft immediately when the component loads
    setTimeLeft(calculateTimeLeft());

    // Recalculate timeLeft every second for live updates
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [targetDate]);

  useEffect(() => {
    let notificationShown = false

    // Fetch the latest expiration date every 20 seconds
    const fetchExpirationDate = async () => {
      try {
        const response = await apiClient.get(`/infoVisioCall/getExpirationDate?roomName=${roomName}`);
        if (response.data && response.data.expiration_date_bonus) {
          setTargetDate(response.data.expiration_date_bonus); // Update target date
          notificationShown = true;
        } else if (response.data && response.data.expiration_date) {
          setTargetDate(response.data.expiration_date); // Update target date
        }
      } catch (error) {
        console.error("Failed to fetch expiration date:", error);
      }
    };

    fetchExpirationDate(); // Execute once after `timeToUpdate`

    // Poll API for updates every minute
    const pollingIntervalId = setInterval(fetchExpirationDate, pollingInterval);
    // Cleanup timeouts and interval when component unmounts
    return () => {
      clearInterval(pollingIntervalId);
    };
  }, [roomName]);

  if (!timeLeft) return <p className="text-center text-red-500"></p>;

  return (
    <div className="flex justify-center space-x-4">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">{value}</span>
          <span className="text-sm uppercase text-gray-50">{unit}</span>
        </div>
      ))}
    </div>
  );
};

export default Countdown; */
