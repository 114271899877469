import { useNavigate as useReactRouterNavigate } from "react-router-dom";
import { encodeBase64 } from "./utils";

export function useHashedNavigate() {
    const navigate = useReactRouterNavigate();

    return (to, id) => {

        if (!id) {
            throw new Error("`id` prop is required for HashedLink");
        }
        // Encode the ID and inject it into the URL
        const hashedTo = to.replace(":id", encodeBase64(id));

        navigate(hashedTo)

    };
}

