import React, {useEffect, useState} from "react";
import apiClient from "../../Services/apiService";
import HeaderSimple from "./HeaderSimple";
import FooterBar from "./FooterBar";
import {Helmet} from "react-helmet";

export default function PageListePlanning (){
    const [pastPlaningCreneaux, setPastPlaningCreneaux] = useState([]);
    const [futurePlaningCreneaux, setFuturePlaningCreneaux] = useState([]);
    const [showFirstTable, setShowFirstTable] = useState(true);
    const [id, setId] = useState(null); // Déclare l'ID comme état pour qu'il soit accessible partout

    const userEmail = localStorage.getItem('userEmail');

    useEffect(() => {

        const fetchPastPlaningCreneaux = async () => {
            try {
                const idExpertResponse  = await apiClient.post("/experts/id",{
                    email : userEmail
                })
                const expertId = idExpertResponse.data;// Stocker l'ID expert dans l'état
                const response = await apiClient.get(`/detailsCreneau/expert/past/${expertId}`); // Remplacez '1' par l'ID de l'expert souhaité
                setPastPlaningCreneaux(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchFuturePlaningCreneaux = async () => {
            try {
                const idExpertResponse  = await apiClient.post("/experts/id",{
                    email : userEmail
                })
                const expertId = idExpertResponse.data;// Stocker l'ID expert dans l'état
                const response = await apiClient.get(`/detailsCreneau/expert/future/${expertId}`); // Remplacez '1' par l'ID de l'expert souhaité
                setFuturePlaningCreneaux(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPastPlaningCreneaux();
        fetchFuturePlaningCreneaux();
    }, []);

    const toggleTable = () => {
        setShowFirstTable(!showFirstTable);
    };

    function getClassNameForStatus(statut) {
        switch (statut) {
            case 'RÉSERVÉ':
                return 'bg-green-700'; // Autre exemple de classe pour un autre statut
            case 'OCCUPÉ':
                return 'bg-yellow-600'; // Autre exemple de classe pour un autre statut
            case 'TERMINER':
                return 'bg-blue-700'; // Autre exemple de classe pour un autre statut
            default:
                return 'bg-gray-700'; // Classe par défaut pour les autres statuts
        }
    }

    return(
        <>
            <><Helmet><title>Planning Expert - Experlik.ma</title></Helmet></>
            <HeaderSimple></HeaderSimple>
        <div className="p-3">
            <div className="flex justify-center  mb-4">
                <div className="mx-8 shadow font-poppins rounded-full h-10 mt-4 flex p-1 relative items-center w-64">
                    <div className="w-full flex justify-center">
                        <button
                            onClick={() => setShowFirstTable(true)}
                            className={`w-full text-center ${showFirstTable ? 'text-white' : 'text-gray-700'}`}
                        >
                            À venir
                        </button>
                    </div>
                    <div className="w-full flex justify-center">
                        <button
                            onClick={() => setShowFirstTable(false)}
                            className={`w-full text-center ${showFirstTable ? 'text-gray-700' : 'text-white'}`}
                        >
                            Historique
                        </button>
                    </div>
                    <span
                        className={`elSwitch bg-teal-900 shadow text-white  flex items-center justify-center w-1/2 rounded-full h-8 transition-all absolute top-[4px] ${showFirstTable ? 'left-1' : 'right-1'}`}
                    >
                        {showFirstTable ? 'À venir' : 'Historique'}
                    </span>
                </div>
            </div>

            <div
                className="relative overflow-x-auto   shadow-md rounded-lg md:w-3/4 w-full flex mx-auto  ">
                {showFirstTable ? (
                    <table className="w-full text-sm text-center rounded-2xl rtl:text-right text-gray-500 ">
                            <thead className=" text-xs text-gray-700  uppercase bg-gray-200 ">
                            <tr>
                                <th scope="col" className=" border px-10 py-3">
                                    Jour
                                </th>
                                <th scope="col" className=" border px-10 py-3">
                                    Semaine
                                </th>

                                <th scope="col" className="border px-6 py-3">
                                    Heure Début
                                </th>
                                <th scope="col" className="border px-6 py-3">
                                    Heure Fin
                                </th>
                                <th scope="col" className="border px-6 py-3">
                                    statut
                                </th>
                            </tr>
                            </thead>
                        <tbody>
                        {futurePlaningCreneaux.map((creneau) => (
                                <tr className="bg-white font-semibold border-b " key={creneau.idPlaningCreneau}>
                                    <td className={"border px-4 py-2"}>{creneau.jour}</td>
                                    <td className="border px-4 py-2">{creneau.semaine}</td>

                                    <td className="border px-4 py-2">{creneau.idCreneau.heureDebut}</td>
                                    <td className="border px-4 py-2">{creneau.idCreneau.heureFin}</td>
                                    <td className={`border px-4 py-2`}>
                                    <span
                                        className={`text-white rounded-full py-1 w-28 ${getClassNameForStatus(creneau.statutCreneau)}`}
                                        style={{display: 'inline-block'}}
                                    >
                                        {creneau.statutCreneau}
                                    </span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                    </table>

                ) : (
                    <table className="w-full text-sm text-center rounded-2xl rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                        <tr>
                            <th scope="col" className="px-10 py-3">
                                Jour
                            </th>
                            <th scope="col" className="px-10 py-3">
                                Semaine
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Heure Début
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Heure Fin
                            </th>
                            <th scope="col" className="px-6 py-3">
                                statut
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {pastPlaningCreneaux.map((creneau) => (
                            <tr className="bg-white font-semibold border-b " key={creneau.idPlaningCreneau}>
                                <td className="border px-4 py-2">{creneau.jour}</td>
                                <td className="border px-4 py-2">{creneau.semaine}</td>

                                <td className="border px-4 py-2">{creneau.idCreneau.heureDebut}</td>
                                <td className="border px-4 py-2">{creneau.idCreneau.heureFin}</td>
                                <td className={`border px-4 py-2`}>
                                    <span
                                        className={`text-white rounded-full py-1 w-28 ${getClassNameForStatus(creneau.statutCreneau)}`}
                                        style={{display: 'inline-block'}}
                                    >
                                        {creneau.statutCreneau}
                                    </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

        </div>
            <FooterBar></FooterBar>
        </>
    )
}