import { useParams as useRouterParams } from "react-router-dom";
import { decodeBase64 } from "./utils";

export function useParams() {
  const params = useRouterParams();
  const decodedParams = { ...params };

  // Decode all params that look like Base64
  Object.keys(decodedParams).forEach((key) => {
    try {
      decodedParams[key] = decodeBase64(decodedParams[key]);
    } catch (e) {
      console.warn(`Failed to decode Base64 param: ${key}`);
    }
  });

  return decodedParams;
  //return params;
}
