import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import { useParams } from '../utils/useParams';
import StarsRating from './StarsRating';
import * as Yup from 'yup';
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FooterBar from "./Pages/FooterBar";
import HeaderSimple from "./Pages/HeaderSimple";
import apiClient from '../Services/apiService';

function ExpertFeedback() {
    const {id} = useParams();
    const [expertInfo, setExpertInfo] = useState(null);
    console.log("ID de consultation:", id);

    useEffect(() => {
        // Appel API pour récupérer les informations de l'expert
        apiClient.get(`/AvisExpert/getByReservationId/${id}`)
            .then(response => {
                setExpertInfo(response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des informations de l'expert:", error);
                //handleOpenToastFailed("Impossible de récupérer les informations de l'expert.");
            });
    }, [id]);
    const FeedbackSchema = Yup.object().shape({
        feedback: Yup.string()
            .min(10, 'Trop court !')
            .required('Champ obligatoire !'),
        isShareable: Yup.boolean()
            .required('Veuillez sélectionner une option !'),
        rating: Yup.number()
            .integer()
            .min(1, 'Veuillez noter que la note doit être supérieure ou égale à 1 pour soumettre votre évaluation !')
            .required('Veuillez noter que la note doit être supérieure ou égale à 1 pour soumettre votre évaluation !'),
    });


    const handleOpenToastSuccess = () => {
        toast.success('Merci pour votre retour ! Votre avis a été enregistré avec succès !', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleOpenToastFailed = (message) => {
        toast.error(message || 'Oops ! Quelque chose s\'est mal passé. Veuillez réessayer.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            {expertInfo && (
                <HeaderSimple
                    mainTitle="Formulaire de Retour d'Expert"
                    subTitle={`Avis de  ${expertInfo.prenomExpert} ${expertInfo.nomExpert}`}
                />
            )}
            <div className="bg-white p-3">
                <div className={" mx-auto max-w-screen-md"}>
                    <div className="mx-auto mb-6 max-w-2xl text-center">
                        <p className="mt-2 font-montserrat font-medium text-xl leading-8 text-gray-700">Votre avis sur la consultation est
                                                                            précieux.</p>
                    </div>
                    <Formik
                        initialValues={{
                            expertName: '',
                            expertEmail: '',
                            feedback: '',
                            isShareable: null,
                            rating: 0
                        }}
                        validationSchema={FeedbackSchema}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            const avisExpertDto = {
                                avisExpert: values.feedback,
                                evaluationExpert: values.rating,
                                isPubliable: values.isShareable === "1",
                                idReservation: id,
                            };

                            console.log('Données envoyées:', avisExpertDto);

                            apiClient.post('/AvisExpert/save', avisExpertDto)
                                .then(response => {
                                    console.log('Réponse du serveur:', response.data);
                                    handleOpenToastSuccess();
                                    resetForm();
                                })
                                .catch(error => {
                                    console.error("Erreur lors de l'enregistrement de l'avis:", error);
                                    if (error.response) {
                                        console.error('Données de réponse:', error.response.data);
                                        console.error('Statut:', error.response.status);
                                        console.error('Headers:', error.response.headers);
                                    } else if (error.request) {
                                        console.error('Aucune réponse reçue:', error.request);
                                    } else {
                                        console.error('Erreur de configuration:', error.message);
                                    }
                                    handleOpenToastFailed();
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({errors, touched, isSubmitting}) => (
                            <Form className="border font-poppins border-gray-900/10 rounded-2xl p-5 px-4  space-y-8">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="rating"
                                               className="block text-sm font-medium leading-6 text-gray-700">
                                            Évaluation de la consultation
                                        </label>
                                        <div className="mt-2.5">
                                            <StarsRating name="rating"/>
                                            {errors.rating && touched.rating ? (
                                                <div className="mt-2 text-sm text-red-600">{errors.rating}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-700">
                                            Autorisez-vous le partage de ce retour ?
                                        </label>
                                        <div className="mt-2.5">
                                            <label className="inline-flex items-center mr-6">
                                                <Field type="radio" name="isShareable" value="1"
                                                       className="form-radio text-green-600"/>
                                                <span className="ml-2">Oui</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <Field type="radio" name="isShareable" value="0"
                                                       className="form-radio text-green-600"/>
                                                <span className="ml-2">Non</span>
                                            </label>
                                            {errors.isShareable && touched.isShareable ? (
                                                <div className="mt-2 text-sm text-red-600">{errors.isShareable}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="feedback"
                                               className="block text-sm font-medium leading-6 text-gray-700">
                                            Retour sur la consultation
                                        </label>
                                        <div className="mt-2.5">
                                            <Field
                                                as="textarea"
                                                name="feedback"
                                                id="feedback"
                                                rows="4"
                                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                                            />
                                            {errors.feedback && touched.feedback ? (
                                                <div className="mt-2 text-sm text-red-600">{errors.feedback}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Soumettre l'avis
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <ToastContainer/>
                </div>
            </div>
            <FooterBar></FooterBar>
        </>
    );
}

export default ExpertFeedback;