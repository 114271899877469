import React, { useEffect, useState, useRef } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import apiClient from "../Services/apiService";
import { toast } from "react-toastify";
import { getPublicIP } from "../utils/getIpAdress";
import { STATUS_OK, TRACER_MEET_CONNECTION, TRACER_MEET_END } from "../utils/eventsTypes";
import { useEmailClient } from "../utils/useEmailClient";
import { jwtDecode } from "jwt-decode";
import ValidationPresenceToast from "./ValidationPresenceToast";

function MeetComponent({ updateExpiration, token, roomName }) {
  const [timeToUpdate, setTimeToUpdate] = useState(null);
  const externalApiRef = useRef(null);
  const currentTimeouts = useRef([]);
  const pollingIntervalId = useRef(null);
  const isMounted = useRef(true);
  const notificationShown = useRef(false);
  const clientEmail = useEmailClient();

  /*const [role, setRole] = useState(null);
  const [localUserSpeakTime, setLocalUserSpeakTime] = useState(0);
  const [otherUserSpeakTimes, setOtherUserSpeakTimes] = useState(0);*/

  const roleRef = useRef(null);
  const localUserSpeakTimeRef = useRef(0);
  const otherUserSpeakTimesRef = useRef(0);

  const notify = () => {
    toast(<ValidationPresenceToast roomName={roomName} token={token} />, {
      position: 'top-right',
      autoClose: false, // Le toast reste visible jusqu'à l'interaction de l'utilisateur
      closeOnClick: false, // Empêche la fermeture du toast en cliquant dessus
      draggable: false, // Désactive le glissement pour fermer le toast
    });
  };



  const traceEvent = async (status, message, eventType) => {
    let decoded = jwtDecode(token)
    let eventPayload;
    // Check if `ids` is a comma-separated list or a single number                // Build the payload
    eventPayload = {
      clientEmail: decoded.context.user.email,
      eventType: eventType,
      ipAddress: await getPublicIP(), // Optional
      parameter: `Affiliation : ${decoded.context.user.affiliation} / userMail : ${decoded.context.user.email}`,
      status: status,
      description: message,
      roomName: roomName
    };

    console.log(eventPayload)

    try {
      const response = await apiClient.post("/events/trace", eventPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      // Handle error response
      if (error.response) {
        // Server returned an error response
        console.error(error.response.data);
      } else {
        // Network or other error
        console.error("An unexpected error occurred");
      }
    }

  };

  /*const saveSpeakerStats = async () => {
    try {
      // Construct the data transfer object (DTO) with current state values
      const dto = {
        roomName,
        role : role,
        localUserSpeakTime: localUserSpeakTime.toString(),
        otherUserSpeakTimes: otherUserSpeakTimes.toString(),
      };

      // Send a POST request to save the speaker stats
      const response = await apiClient.post('/speaker-stats/save', dto);

      // Log the response from the server
      console.log('Save response:', response.data);
    } catch (error) {
      // Log any errors that occur during the save process
      console.error('Erreur lors de l\'enregistrement des statistiques :', error);
    }
  };*/



  useEffect(() => {
    return () => {
      isMounted.current = false;
      currentTimeouts.current.forEach(clearTimeout);
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, []);

  const handleOpenToastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  useEffect(() => {
    const fetchExpirationDate = async () => {
      try {
        const response = await apiClient.get(
          `/infoVisioCall/getExpirationDate?roomName=${roomName}`
        );
        console.log(response)
        if (isMounted.current && response.data) {
          const threeMinutesInMs = 3 * 60 * 1000;
          const newExpirationDate = new Date(response.data.expiration_date).getTime();
          updateExpiration(newExpirationDate)
          const currentTime = Date.now();
          const remainingTime = newExpirationDate - currentTime;
          setTimeToUpdate(remainingTime - threeMinutesInMs);
        }
      } catch (error) {
        console.error("Error fetching expiration date:", error);
      }
    };

    fetchExpirationDate();
  }, [roomName]);


  const exitFullScreenAndValidate = () => {
    // Check if the document is currently in fullscreen mode
    if (document.fullscreenElement) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari compatibility
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge compatibility
      }
    } else {
      console.log('Not in fullscreen mode');
    }
  }

  const validerPresence = async () => {
    let decoded = jwtDecode(token)
    let eventPayload;
    // Check if `ids` is a comma-separated list or a single number                // Build the payload
    eventPayload = {
      clientEmail: decoded.context.user.email,
      ipAddress: await getPublicIP(), // Optional
      role: decoded.context.user.affiliation,
      roomName: roomName
    };

    console.log(eventPayload)

    try {
      const response = await apiClient.post("/meet/valider-presence", eventPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      // Handle error response
      if (error.response) {
        // Server returned an error response
        console.error(error.response.data);
      } else {
        // Network or other error
        console.error("An unexpected error occurred");
      }
    }

  };



  /*const getSpeakerStats = async (token) => {
    try {
      const statsFromApi = await externalApiRef.current.getSpeakerStats();
      const decoded = jwtDecode(token);
      const newRole = decoded.context.user.affiliation;

      const speakerStats = statsFromApi.speakerStats;

      let newLocalUserSpeakTime = 0;
      let newOtherUserSpeakTimes = 0;

      Object.values(speakerStats).forEach((stat) => {
        if (stat._isLocalStats) {
          newLocalUserSpeakTime = stat.totalDominantSpeakerTime;
        } else {
          newOtherUserSpeakTimes = stat.totalDominantSpeakerTime || 0;
        }
      });


      setRole(newRole);
      setLocalUserSpeakTime(newLocalUserSpeakTime);
      setOtherUserSpeakTimes(newOtherUserSpeakTimes);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques :', error);
    }
  };


  const handleSpeakerStats = async (token) => {
    try {
      // Récupération des statistiques depuis l'API
      const statsFromApi = await externalApiRef.current.getSpeakerStats();
      const decoded = jwtDecode(token);
      const newrole = decoded.context.user.affiliation;

      const speakerStats = statsFromApi.speakerStats;

      let newlocalUserSpeakTime = 0;
      let newotherUserSpeakTimes = 0;

      Object.values(speakerStats).forEach((stat) => {
        if (stat._isLocalStats) {
          newlocalUserSpeakTime = stat.totalDominantSpeakerTime;
        } else {
          newotherUserSpeakTimes = stat.totalDominantSpeakerTime || 0;
        }
      });

      // Calculer les nouvelles statistiques
      const updatedStats = {
        newrole,
        localUserSpeakTime: Math.max(newlocalUserSpeakTime, localUserSpeakTime),
        otherUserSpeakTimes: Math.max(
          newotherUserSpeakTimes, otherUserSpeakTimes
        ),
      };

      setRole(newrole);
      setLocalUserSpeakTime(newlocalUserSpeakTime);
      setOtherUserSpeakTimes(newotherUserSpeakTimes);
      // Préparer les données pour la sauvegarde
      const dto = {
        roomName,
        role: updatedStats.newrole,
        localUserSpeakTime: updatedStats.localUserSpeakTime.toString(),
        otherUserSpeakTimes: updatedStats.otherUserSpeakTimes.toString(),
      };

      // Enregistrer les statistiques dans l'API
      const response = await apiClient.post("/speaker-stats/save", dto);
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Erreur lors du traitement des statistiques :", error);
    }
  };*/

  const getSpeakerStats = async (token) => {
    try {
      const statsFromApi = await externalApiRef.current.getSpeakerStats();
      const decoded = jwtDecode(token);
      const newRole = decoded.context.user.affiliation;

      const speakerStats = statsFromApi.speakerStats;

      let newLocalUserSpeakTime = 0;
      let newOtherUserSpeakTimes = 0;

      Object.values(speakerStats).forEach((stat) => {
        if (stat._isLocalStats) {
          newLocalUserSpeakTime = stat.totalDominantSpeakerTime;
        } else {
          newOtherUserSpeakTimes = stat.totalDominantSpeakerTime || 0;
        }
      });

      // Update refs
      roleRef.current = newRole;
      localUserSpeakTimeRef.current = newLocalUserSpeakTime;
      otherUserSpeakTimesRef.current = newOtherUserSpeakTimes;
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques :', error);
    }
  };

  const handleSpeakerStats = async (token) => {
    try {
      const statsFromApi = await externalApiRef.current.getSpeakerStats();
      const decoded = jwtDecode(token);
      const newRole = decoded.context.user.affiliation;

      const speakerStats = statsFromApi.speakerStats;

      let newLocalUserSpeakTime = 0;
      let newOtherUserSpeakTimes = 0;

      Object.values(speakerStats).forEach((stat) => {
        if (stat._isLocalStats) {
          newLocalUserSpeakTime = stat.totalDominantSpeakerTime;
        } else {
          newOtherUserSpeakTimes = stat.totalDominantSpeakerTime || 0;
        }
      });

      // Update refs
      roleRef.current = newRole;
      localUserSpeakTimeRef.current = Math.max(
        newLocalUserSpeakTime,
        localUserSpeakTimeRef.current
      );
      otherUserSpeakTimesRef.current = Math.max(
        newOtherUserSpeakTimes,
        otherUserSpeakTimesRef.current
      );

      // Prepare the DTO for saving
      const dto = {
        roomName,
        role: roleRef.current,
        localUserSpeakTime: localUserSpeakTimeRef.current.toString(),
        otherUserSpeakTimes: otherUserSpeakTimesRef.current.toString(),
      };

      const response = await apiClient.post('/speaker-stats/save', dto);
      console.log('Save response:', response.data);
    } catch (error) {
      console.error('Erreur lors du traitement des statistiques :', error);
    }
  };

  const saveSpeakerStats = async () => {
    try {
      const dto = {
        roomName,
        role: roleRef.current,
        localUserSpeakTime: localUserSpeakTimeRef.current.toString(),
        otherUserSpeakTimes: otherUserSpeakTimesRef.current.toString(),
      };

      const response = await apiClient.post('/speaker-stats/save', dto);
      console.log('Save response:', response.data);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des statistiques :', error);
    }
  };


  const updateMeetingLogic = async () => {
    if (!externalApiRef.current) return;

    try {
      const response = await apiClient.get(
        `/infoVisioCall/getExpirationDate?roomName=${roomName}`
      );
      if (response.data) {
        let selectedExpirationDate = null;
        const originalExpirationDate = new Date(response.data.expiration_date)
        if (response.data.expiration_date_bonus) {
          const expirationBonusDate = new Date(response.data.expiration_date_bonus).getTime();
          const currentTime = Date.now();
          if (expirationBonusDate - currentTime > 0) {
            selectedExpirationDate = response.data.expiration_date_bonus;
            const expirationDate = new Date(response.data.expiration_date).getTime();
            const differenceInMs = expirationBonusDate - expirationDate;
            const differenceInMinutes = differenceInMs / (1000 * 60);
            if (!notificationShown.current) {
              exitFullScreenAndValidate()
              handleOpenToastSuccess(
                `Un temps supplémentaire de ${differenceInMinutes} minutes a été ajouté à votre réunion.`
              );
              notificationShown.current = true;
            }
          }
        }

        if (!selectedExpirationDate && response.data.expiration_date) {
          selectedExpirationDate = response.data.expiration_date;
        }

        if (selectedExpirationDate) {
          const newExpirationDate = new Date(selectedExpirationDate).getTime();
          const currentTime = Date.now();
          const remainingTime = newExpirationDate - currentTime;
          updateExpiration(newExpirationDate)
          currentTimeouts.current.forEach(clearTimeout);
          currentTimeouts.current = [];

          if (remainingTime > 2 * 60 * 1000) {
            const warningTimeout = setTimeout(async () => {
              exitFullScreenAndValidate()
              notify()
              handleSpeakerStats(token)
            }, originalExpirationDate - currentTime - 2 * 60 * 1000);

            const endTimeout = setTimeout(() => {
              if (isMounted.current) {
                externalApiRef.current.executeCommand("hangup");
              }
            }, remainingTime);

            currentTimeouts.current.push(warningTimeout, endTimeout);
          } else if (remainingTime > 0) {
            const endTimeout = setTimeout(() => {
              if (isMounted.current) {
                externalApiRef.current.executeCommand("hangup");
              }
            }, remainingTime);

            currentTimeouts.current.push(endTimeout);
          }
        }
      }
    } catch (error) {
      console.error("Error updating meeting logic:", error);
    }
  };

  const setupMeetingTimers = () => {
    if (!timeToUpdate) return;

    const initialTimeout = setTimeout(() => {
      updateMeetingLogic();
      getSpeakerStats(token);

      pollingIntervalId.current = setInterval(() => {
        if (isMounted.current && !notificationShown.current) {
          updateMeetingLogic();
          getSpeakerStats(token);
        } else {
          clearInterval(pollingIntervalId.current);
        }
      }, 30000);
    }, timeToUpdate);

    currentTimeouts.current.push(initialTimeout);
  };



  return (
    <div className="flex w-full h-full bg-white rounded-2xl overflow-hidden">
      <JitsiMeeting
        domain={window._env_.REACT_APP_LIEN_MEET}
        roomName={roomName}
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite={{
          APP_NAME:'MEET_JITSI'
        }}
        jwt={token}
        onApiReady={(externalApi) => {
          externalApiRef.current = externalApi;
          traceEvent(STATUS_OK, "L'individu s'est joint a la reunion avec succes.", TRACER_MEET_CONNECTION)
          setupMeetingTimers();
          validerPresence();
        }}
        onReadyToClose={() => {
          // Logic to handle meeting close if needed
          traceEvent(STATUS_OK, 'Le meet est termine avec succes', TRACER_MEET_END)
          saveSpeakerStats();
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "100%";
          iframeRef.style.width = "100%";
        }}
      />
    </div>
  );
}

export default MeetComponent;
