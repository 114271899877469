import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import axios from 'axios'
import logoHbm from "../images/Xperlink-noir-12.webp";
import {getDomaineService} from "../Services/DomaineService";
import apiClient from "../Services/apiService";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {toast} from "react-toastify";
import {Step, Stepper, Typography} from "@material-tailwind/react";
import {CogIcon, HomeIcon, UserIcon} from "@heroicons/react/24/outline";
import {Button} from "@mui/material";
//import {useParams} from "../utils/useParams";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';





/*
const PdfViewerModal = ({ fileBase64, fileName, onClose }) => {
    if (!fileBase64) {
        console.error("Données manquantes pour afficher le fichier PDF.");
        return null; // Ne rien afficher si les données sont invalides
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-full max-w-sm sm:max-w-4xl rounded-lg shadow-lg p-4 sm:p-6 relative">
                <iframe
                    src={`data:application/pdf;base64,${fileBase64}`}
                    title={fileName}
                    className="w-full h-[70vh] md:h-[600px] lg:h-[800px] border rounded"
                    style={{border: "1px solid #ccc"}}
                />
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Fermer
                </button>
                <a
                    href={`data:application/pdf;base64,${fileBase64}`}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Ouvrir le PDF
                </a>
            </div>
        </div>


    );
};
*/

const PdfViewerModal = ({ fileBase64, fileName, onClose }) => {
    // Utilisation de useMemo pour éviter de recréer le Blob et l'URL
    const pdfUrl = useMemo(() => {
        if (!fileBase64) return null; // Traitez les données manquantes ici
        const pdfBlob = new Blob([Uint8Array.from(atob(fileBase64), c => c.charCodeAt(0))], {
            type: 'application/pdf',
        });
        return URL.createObjectURL(pdfBlob);
    }, [fileBase64]);

    // Nettoyer l'URL pour éviter les fuites de mémoire
    useEffect(() => {
        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    // Retourner null si aucune donnée de fichier n'est disponible
    if (!pdfUrl) {
        console.error("Données manquantes pour afficher le fichier PDF.");
        return null;
    }

    // Instance du plugin defaultLayoutPlugin
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-full max-w-4xl rounded-lg shadow-lg p-6 relative">
                <h2 className="text-lg font-medium text-gray-800 mb-4">{fileName}</h2>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div style={{ height: '600px', overflow: 'auto', border: '1px solid #ccc' }}>
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[defaultLayoutPluginInstance]} // Ajout du plugin
                            onDocumentLoadError={(error) => console.error('Erreur de chargement : ', error)}
                        />
                    </div>
                </Worker>
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
};


const RegisterExpert = () => {
    const [responseStatus, setResponseStatus] = useState(null)

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const navigate = useNavigate()
    const {id} = useParams();
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLastStep, setIsLastStep] = React.useState(false);
    const [isFirstStep, setIsFirstStep] = React.useState(false);
    const [expertServices, setExpertServices] = useState([]);
    const [isOpen, setIsOpen] = useState(false); // État pour suivre si la pop-up est ouverte ou fermée
    const sujetField = useRef();
    const [error, setError] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleImageClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        // Récupérer les fichiers de l'expert
        const fetchFiles = async () => {
            try {
                const response = await apiClient.get(`/file/fileExpert/${id}`);
                setFiles(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des fichiers :", error);
            }
        };

        fetchFiles();
    }, [id]);

    const openPopup = () => {
        setIsOpen(true);
    };



    const closePopup = () => {
        setIsOpen(false);
    };

    const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);



/*    const handleNext = async (values, setErrors, setTouched) => {

    };*/

    const handleValide = async (e) => {
        e.preventDefault();
        console.log("handleValide called");
        try {
            const reservation = await apiClient.get(`/experts/valider/${id}`);
            console.log("L'expert acceptée avec succès", reservation);
            toast.success(reservation.data);
            navigate("/");
        } catch (error) {
            //alert(`Une erreur est survenue : ${error.response.data}`);
            toast.error(`${error.response.data}`);
            console.error("Erreur lors de l'acceptation de la réservation :", error);
        }
    };

    const valideForm = () => {

        const sujetValue = sujetField.current.value;

        let isFormValid = true;

        // Logique pour le champ sujet
        if (sujetValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{sujet: 'Motif est obligatoire'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{sujet: ''}
            }));
        }

        setIsFormValid(isFormValid)

        return isFormValid;
    }

    const getError = (fieldName) => {
        return error[fieldName];
    }
    const displayError = (fieldName) => {
        const erreur = getError(fieldName);
        if (erreur !== undefined && erreur !== '') {
            return <div className="text-xs block text-red-500">{erreur}</div>;
        }
    }

    const [formData, setFormData] = useState({
        sujet: ""
    });
    const handleChange = (e) => {
        valideForm();
        setFormData({...formData, [e.target.name]: e.target.value});

    };

    const handleRejete = async (e) => {
        e.preventDefault();
        const isFormValid = valideForm();
        if (!isFormValid) {
            return; // Arrête l'exécution de la fonction si le formulaire n'est pas valide
        }
        console.log("handleValide called");

        /*try {
            // Ajoutez les données saisies par l'utilisateur à reservationData
            const updatedReservationData = {
                motif: formData.sujet
            };

            const reservation = await apiClient.post(`/reservation/reject/expert/${id}`, updatedReservationData);
            // Affichez les données mises à jour
            console.log("Données de la réservation mises à jour :", updatedReservationData);
            toast.success("Réservation annuler avec succès");

            // Rediriger l'utilisateur vers une autre page ou afficher un message de succès, etc.
            navigate("/refuseDemandePage");
            resetForm();
        } catch (error) {
            //alert(`Une erreur est survenue : ${error.response.data}`);
            toast.error(`${error.response.data}`);
            //toast.error(`Une erreur est survenue : ${error.response.data}`);
            console.error("Erreur lors de la création du prospect ou de la réservation :", error);
            // Gérer l'erreur
        }*/

        try {
            const updatedExpertData = {
                motif: formData.sujet
            };

            const reservation = await apiClient.post(`/experts/rejeter/${id}`,updatedExpertData);
            console.log("L'expert rejeté avec succès", updatedExpertData);
            console.log("L'expert rejeté avec succès", reservation);
            toast.success(reservation.data);
            navigate("/");
        } catch (error) {
            //alert(`Une erreur est survenue : ${error.response.data}`);
            toast.error(`${error.response.data}`);
            console.error("Erreur lors de l'acceptation de la réservation :", error);
        }
    };





    const steps = [
        {label: 'Informations personnelles'},
        {label: 'Paramétrage'},
        {label: 'Profil'},
    ];


    const [expertDetails, setExpertDetails] = useState({
        idExpert: "",
        nomExpert: "",
        prenomExpert: "",
        domaineId: "",
        idServices: []
    });

    const statusConfig = {
        VALIDE: {
            title: "Expert validé",
            icon: "✅", // Emoji pour illustration, ou utilisez une classe d'icône comme FontAwesome
            color: "text-green-600"
        },
        DESACTIVER: {
            title: "Expert désactivé",
            icon: "❌",
            color: "text-gray-500"
        },
        A_VALIDER: {
            title: "En attente de validation",
            icon: "⏳",
            color: "text-yellow-500"
        },
        REJETE: {
            title: "Expert rejeté",
            icon: "🚫",
            color: "text-red-600"
        },
        BANNE: {
            title: "Expert banni",
            icon: "🔒",
            color: "text-orange-700"
        },
        BLOQUE: {
            title: "Expert bloqué",
            icon: "⚠️",
            color: "text-red-800"
        }
    };

// Récupérer le statut actuel
    const currentStatus = expertServices?.expert?.statusExpert;

// Configurer les détails du statut
    const { title, icon, color } = statusConfig[currentStatus] || {
        title: "Statut inconnu",
        icon: "❓",
        color: "text-gray-400"
    };


        useEffect(() => {
        const fetchData = async () => {
            try {
                const detailsExpert = await apiClient.get(`/experts/details/${id}`);
                setExpertServices(detailsExpert.data)
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    if (isAuthenticated) {
        return <Navigate to={"/"}/>
    }


    const handleClose = () => {
        console.log("Bouton Close cliqué");
        setSelectedFile(null);
    };

    return (
        <>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className=" w-full md:w-2/6">
                        <a href="/"><img
                            src={logoHbm} alt="logo" className='w-52 mb-8 mx-auto block'/>
                        </a>
                        <div className="flex w-full flex-col md:flex-row items-center justify-center px-8 space-y-4 md:space-y-0 md:space-x-8">
                            <Stepper
                                lineClassName="!bg-blue-gray-50"
                                activeLineClassName="bg-black"
                                activeStep={activeStep}
                                isLastStep={(value) => setIsLastStep(value)}
                                isFirstStep={(value) => setIsFirstStep(value)}
                            >
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    onClick={() => setActiveStep(0)}>
                                    <UserIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 0 ? "black" : "gray"}
                                        >
                                            Info
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 0 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    onClick={() => setActiveStep(1)}>
                                    <HomeIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 1 ? "black" : "gray"}
                                        >
                                            Profile
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 1 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                                <Step
                                    className="!bg-blue-gray-50 text-black cursor-pointer"
                                    activeClassName="ring-0 !bg-black text-white"
                                    completedClassName="!bg-black text-white"
                                    onClick={() => setActiveStep(2)}>
                                    <CogIcon className="h-5 w-5"/>
                                    <div className="absolute -bottom-[2.5rem] w-max text-center">
                                        <Typography
                                            variant="h6"
                                            color={activeStep === 2 ? "black" : "gray"}
                                        >

                                            Parametrage
                                        </Typography>
                                        {/*<Typography
                                            color={activeStep === 2 ? "black" : "gray"}
                                            className="font-normal text-sm"
                                        >
                                            Details about yout account.
                                        </Typography>*/}
                                    </div>
                                </Step>
                            </Stepper>
                        </div>
                        <div className="p-8 mt-20 rounded-2xl  bg-white shadow">
                            <h2 className={`text-center text-2xl font-bold ${color}`}>
                                {title} {icon}
                            </h2>

                            <Formik>
                                {({errors, values, setFieldValue, setTouched, touched, setFieldTouched, setErrors}) => (

                                    <Form className="mt-8 h-auto space-y-4">
                                        {responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }
                                        {/* Étape conditionnelle */}
                                        {activeStep === 0 && (
                                            <>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Photo</label>

                                                    <div className="flex items-center justify-center w-50 h-50">
                                                        <label
                                                            htmlFor="dropzone-file"
                                                            className="flex flex-col p-2 items-center justify-center w-60 h-60 border-2 border-gray-300 border-dashed rounded-full bg-gray-50 hover:bg-gray-100 cursor-pointer"
                                                            onClick={handleImageClick}
                                                        >
                                                            <div
                                                                className="flex flex-col items-center justify-center w-full h-full">

                                                                <img
                                                                    src={`data:image/jpeg;base64,${expertServices?.expert?.photoExpert}`}
                                                                    alt="Aperçu"
                                                                    className="w-full h-full object-cover rounded-full"
                                                                />

                                                            </div>
                                                        </label>
                                                    </div>
                                                    {/* Modal pour afficher la photo en grand */}
                                                    {showPopup && (
                                                        <div
                                                            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                                                            onClick={handleClosePopup}
                                                        >
                                                            <div
                                                                className="bg-white p-4 rounded-lg relative flex items-center justify-center w-[90vw] h-[90vh] max-w-[800px] max-h-[800px]"
                                                                onClick={(e) => e.stopPropagation()} // Empêche de fermer en cliquant sur l'image
                                                            >
                                                                <img
                                                                    src={`data:image/jpeg;base64,${expertServices?.expert?.photoExpert}`}
                                                                    alt="Aperçu agrandi"
                                                                    className="max-w-full max-h-full object-contain"
                                                                />
                                                                <button
                                                                    onClick={handleClosePopup}
                                                                    className="absolute top-2 right-2 bg-gray-300 hover:bg-gray-400 text-black rounded-full w-8 h-8 flex items-center justify-center"
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800  text-sm mb-2 block">Prénom </label>
                                                        <div className="relative flex items-center">
                                                            <Field name="firstName" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.prenomExpert || ''}/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>

                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Nom</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="lastName" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.nomExpert || ''}/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <circle cx="10" cy="7" r="6"
                                                                        data-original="#000000"></circle>
                                                                <path
                                                                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                    data-original="#000000"></path>
                                                            </svg>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800 text-sm mb-2 block">Téléphone</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="tel" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.telExpert || ''}
                                                            />
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 stroke="#bbb" viewBox="0 0 24 24"
                                                                 className="w-4 h-4 absolute right-4">
                                                                <path
                                                                    d="M21.707 16.293l-5-5a1 1 0 0 0-1.414 0l-2 2a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414l2-2a1 1 0 0 0 0-1.414l-5-5a1 1 0 0 0-1.414 0C3.105 3.895 2 6.385 2 8c0 6.627 5.373 12 12 12 1.615 0 4.105-1.105 5.707-2.707a1 1 0 0 0 0-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800 text-sm mb-2 block">Email</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="email" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.emailExpert || ''}/>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 stroke="#bbb" viewBox="0 0 24 24">
                                                                <path
                                                                    d="M2 4c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h20c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H2zm0 2h20l-10 7L2 6zm0 2.236l7.5 5.264L2 18v-9.764zM22 18l-7.5-5.264L22 6v12z"/>
                                                            </svg>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label
                                                            className="text-gray-800  text-sm mb-2 block">Ville</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="ville" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.villeExpert || ''}/>

                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <path
                                                                    d="M12 2a7 7 0 017 7c0 5-7 13-7 13S5 14 5 9a7 7 0 017-7zm0 9a2 2 0 110-4 2 2 0 010 4z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Pays</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="pays" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices?.expert?.payeExpert || ''}/>
                                                            {/* Nouveau SVG pour le pays */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                 className="w-4 h-4 absolute right-4"
                                                                 viewBox="0 0 24 24">
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.93 9h-4.08c-.11-2.33-.58-4.55-1.34-6.41 2.67 1.02 4.66 3.37 5.42 6.41zM12 4c.94 1.5 1.63 3.47 1.84 6h-3.68C10.37 7.47 11.06 5.5 12 4zm-2.51.59c-.76 1.86-1.23 4.08-1.34 6.41H4.07c.76-3.04 2.75-5.39 5.42-6.41zM4.07 13h4.08c.11 2.33.58 4.55 1.34 6.41-2.67-1.02-4.66-3.37-5.42-6.41zm7.93 7c-.94-1.5-1.63-3.47-1.84-6h3.68c-.21 2.53-.9 4.5-1.84 6zm2.51-.59c.76-1.86 1.23-4.08 1.34-6.41h4.08c-.76 3.04-2.75 5.39-5.42 6.41z"></path>
                                                            </svg>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"w-full"}>
                                                    <label
                                                        className="text-gray-800 text-sm mb-2 block">Titre</label>
                                                    <div className="relative flex items-center">
                                                        <Field name="titreExpert" type="text" required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               disabled={true}
                                                               value={expertServices?.expert?.titreExpert || ''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"w-full"}>
                                                    <label
                                                        className="text-gray-800 text-sm mb-2 block">Description</label>
                                                    <div className="relative flex items-center">
                                                        <Field name="descriptionExpert" as="textarea" required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               disabled={true}
                                                               value={expertServices?.expert?.descriptionExpert || ''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Type
                                                                                                            expert</label>
                                                        <div className="relative flex items-center">
                                                            <select
                                                                id="area"
                                                                className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-black text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                                /*value={values.selectedTypeExpert} // Utilisation de Formik values
                                                                onChange={(event) => {
                                                                    const value = event.target.value;
                                                                    setFieldValue("selectedTypeExpert", value); // Mise à jour dans Formik
                                                                    setSelectedTypeExpert(value); // Mise à jour de l'état local
                                                                }} name="selectedTypeExpert"
                                                                onBlur={() => setFieldTouched("selectedTypeExpert", true)}*/ // Gestion du "touch" via Formik
                                                                disabled={true}

                                                            >
                                                                <option
                                                                    value="">{expertServices?.expert?.typeExpert || ''}</option>
                                                                {/*{typeExpertServices.map((typeExpert) => (
                                                                    <option key={typeExpert.idTypeExpert}
                                                                            value={typeExpert.idTypeExpert}>
                                                                        {typeExpert.typeExpert}
                                                                    </option>
                                                                ))}*/}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">

                                                    <div className={"py-2"} hidden={!expertServices?.expert?.rcExpert}>
                                                        <label className="text-gray-800 text-sm mb-2 block">RC (Registre
                                                                                                            de
                                                                                                            Commerce)</label>
                                                        <div className="relative flex items-center">
                                                            <Field
                                                                type="text"
                                                                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                disabled={true}
                                                                value={expertServices?.expert?.rcExpert || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"py-2"} hidden={!expertServices?.expert?.iceExpert}>
                                                        <label className="text-gray-800 text-sm mb-2 block">ICE
                                                                                                            (Identifiant
                                                                                                            Commun de
                                                                                                            l'Entreprise)</label>
                                                        <div className="relative flex items-center">
                                                            <Field
                                                                type="text"
                                                                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                disabled={true}
                                                                value={expertServices?.expert?.iceExpert || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"py-2"} hidden={!expertServices?.expert?.ribExpert}>
                                                        <label className="text-gray-800 text-sm mb-2 block">RIB (Relevé
                                                                                                            d'Identité
                                                                                                            Bancaire)</label>
                                                        <div className="relative flex items-center">
                                                            <Field
                                                                type="text"
                                                                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                disabled={true}
                                                                value={expertServices?.expert?.ribExpert || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Profils
                                                                                                            Réseaux
                                                                                                            Sociaux</label>
                                                        <div
                                                            className={"p-4 bg-gray-50 rounded-md border border-gray-200"}>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">LinkedIn</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="linkedin" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           disabled={true}
                                                                           value={expertServices?.expert?.instagram || ''}
                                                                    />
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M22 22h-4v-7c0-1.657-1.343-3-3-3s-3 1.343-3 3v7H8V8h4v2.586c.703-.949 2.057-2.586 4-2.586 2.761 0 5 2.239 5 5v9zM2 9h4v13H2zM4 7c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">Instagram</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="instagram" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           disabled={true}
                                                                           value={expertServices?.expert?.instagram || ''}/>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M7 2C4.243 2 2 4.243 2 7v10c0 2.757 2.243 5 5 5h10c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5H7zm10 2a3 3 0 013 3v10a3 3 0 01-3 3H7a3 3 0 01-3-3V7a3 3 0 013-3h10zM12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 2a3 3 0 110 6 3 3 0 010-6zm6-3a1 1 0 11.002 2.002A1 1 0 0118 6z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className={"py-2"}>
                                                                <label
                                                                    className="text-gray-800 text-sm mb-2 block">Facebook</label>
                                                                <div className="relative flex items-center">
                                                                    <Field name="facebook" type="text"
                                                                           className={`w-full text-gray-800 text-sm border px-4 py-3 rounded-md border-gray-300 outline-third`}
                                                                           disabled={true}
                                                                           value={expertServices?.expert?.facebook || ''}/>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                                         className="w-4 h-4 absolute right-4"
                                                                         viewBox="0 0 24 24">
                                                                        <path
                                                                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.993h-2.54v-2.885h2.54V9.68c0-2.507 1.493-3.89 3.777-3.89 1.093 0 2.238.195 2.238.195v2.459h-1.26c-1.242 0-1.63.771-1.63 1.56v1.872h2.773l-.443 2.885h-2.33v6.993C18.343 21.128 22 16.991 22 12z"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/* Étape conditionnelle */}
                                        {activeStep === 1 && (
                                            <>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Expériences
                                                                                                        professionnelles</label>
                                                    <div className="relative flex items-center">
                                                        <Field name="experience" as="textarea" required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               disabled={true}
                                                               value={expertServices?.experienceTitles || ''}
                                                        />
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                             stroke="#bbb" viewBox="0 0 24 24"
                                                             className="w-4 h-4 absolute top-3 right-4">
                                                            <path
                                                                d="M10 2a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V8a2 2 0 0 0-2-2h-4V4a2 2 0 0 0-2-2h-6zm0 2h6v2h-6V4zM3 8h18v4H3V8zm0 6h18v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-4z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Formations &
                                                                                                        Éducation</label>
                                                    <div className="relative flex items-center">
                                                        <Field name="formation" as="textarea" required
                                                               className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                               disabled={true}
                                                               value={expertServices?.formationTitles || ''}/>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb"
                                                             stroke="#bbb" viewBox="0 0 24 24"
                                                             className="w-4 h-4 absolute top-3 right-4">
                                                            <path
                                                                d="M12 2L1 8l11 6 11-6-11-6zm0 2.236L19.764 8 12 11.764 4.236 8 12 4.236zM3 10.809v4.5c0 2.486 4.03 4.5 9 4.5s9-2.014 9-4.5v-4.5l-2 .999v3.501c0 1.42-3.05 2.5-7 2.5s-7-1.08-7-2.5v-3.501l-2-.999z"/>
                                                        </svg>
                                                    </div>
                                                    <div className={"h-4"}>
                                                        {errors.formation && touched.formation ? (
                                                            <div
                                                                className="text-red-500 pt-2 text-xs">{errors.formation}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="text-gray-800 text-sm mb-2 block">Domaine</label>
                                                    <div className="relative flex items-center">
                                                        <select
                                                            id="area"
                                                            className="select-box h-12 p-2 font-normal w-full border bg-gray-100 text-black text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            name="selectedDomaine"
                                                            disabled={true}
                                                        >
                                                            <option
                                                                value="">{expertServices?.domainesTitles || ''}</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Services sélectionnés :
                                                        </label>
                                                    </p>
                                                    {/* Liste des checkboxes */}
                                                    <div className="bg-gray-100 p-4 rounded-md border border-gray-300">

                                                        <div>
                                                            {expertServices.servicesTitles.map((title, index) => (
                                                                <div key={index} className="flex items-center py-1">
                                                                    <input
                                                                        name="selectedService"
                                                                        type="checkbox"
                                                                        id={`service-${index}`}
                                                                        value={title}
                                                                        checked={true}
                                                                        disabled={true}
                                                                        className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                                    />
                                                                    <label
                                                                        htmlFor={`service-${index}`}
                                                                        className="ml-2 text-sm text-gray-700"
                                                                    >
                                                                        {title}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}

                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Certificats sélectionnés :
                                                        </label>
                                                    </p>

                                                    <div className="">
                                                        <div
                                                            className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                            <div>
                                                                {expertServices.certificatTitles.map((title, index) => (
                                                                    <div key={index} className="flex items-center py-1">
                                                                        <input
                                                                            name="selectedService"
                                                                            type="checkbox"
                                                                            id={`service-${index}`}
                                                                            value={title}
                                                                            checked={true}
                                                                            disabled={true}
                                                                            className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                                        />
                                                                        <label
                                                                            htmlFor={`service-${index}`}
                                                                            className="ml-2 truncate text-sm text-gray-700"
                                                                        >
                                                                            {title}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Compétence sélectionnés :
                                                        </label>
                                                    </p>
                                                    {/* Résumé des certificats sélectionnés */}
                                                    <div className="">
                                                        <div
                                                            className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                            <div>
                                                                {expertServices.competenceTitles.map((title, index) => (
                                                                    <div key={index} className="flex items-center py-1">
                                                                        <input
                                                                            name="selectedService"
                                                                            type="checkbox"
                                                                            id={`service-${index}`}
                                                                            value={title}
                                                                            checked={true}
                                                                            disabled={true}
                                                                            className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                                        />
                                                                        <label
                                                                            htmlFor={`service-${index}`}
                                                                            className="ml-2 text-sm text-gray-700"
                                                                        >
                                                                            {title}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    <h2 className="text-lg font-medium text-gray-800 mb-4">Fichiers
                                                                                                           ajoutés</h2>
                                                    {files.length > 0 ? (
                                                        <ul className="list-disc list-inside text-sm text-gray-600">
                                                            {files.map((file, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"
                                                                >
                                                                    <p>
                                                                        <span
                                                                            className="text-black font-medium">{file.typeFileName} :</span> {file.nomFile}
                                                                    </p>
                                                                    <a
                                                                        href={`data:application/pdf;base64,${file.fileBase64}`}
                                                                        download={file.nomFile}
                                                                        className="text-blue-500 hover:underline"
                                                                    >
                                                                        Télécharger
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p className="text-gray-500 text-sm">Aucun fichier ajouté pour
                                                                                             le moment.</p>
                                                    )}
                                                </div>*/}
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm block font-medium">
                                                            Fichiers ajoutés :
                                                        </label>
                                                    </p>
                                                    {files.length > 0 ? (
                                                        <ul className="list-disc list-inside text-sm text-gray-600">
                                                            {files.map((file, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="flex items-center justify-between my-2 bg-white p-2 border border-gray-300 rounded-md"
                                                                >
                                                                    <p className="flex-1 truncate">
                                                                            <span className="text-black font-medium">
                                                                              {file.typeFileName} :
                                                                            </span>{" "}
                                                                            <span title={file.nomFile} className="truncate">
                                                                              {file.nomFile}
                                                                            </span>
                                                                    </p>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (file.fileBase64 && file.nomFile) {
                                                                                setSelectedFile(file);
                                                                            } else {
                                                                                console.error("Données de fichier incomplètes :", file);
                                                                            }
                                                                        }}
                                                                        className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                                                                    >
                                                                        View
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p className="text-gray-500 text-sm">Aucun fichier ajouté pour
                                                                                             le moment.</p>
                                                    )}

                                                    {/* Modale pour afficher le PDF */}
                                                    {selectedFile && (
                                                        <PdfViewerModal
                                                            fileBase64={selectedFile.fileBase64 || ""}
                                                            fileName={selectedFile.nomFile || "Fichier sans nom"}
                                                            onClose={handleClose}
                                                        />
                                                    )}
                                                </div>

                                            </>
                                        )}
                                        {/* Étape conditionnelle */}
                                        {activeStep === 2 && (
                                            <>


                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Durée de la
                                                                                                            consultation</label>
                                                        <div className="relative  flex items-center">
                                                            <Field name="tarif" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={(expertServices?.expert.duree + ' Minutes') || ''}
                                                            />
                                                        </div>
                                                        {/*<div className={"h-4"}>
                                                            {errors.selectedUnite && touched.selectedUnite ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.selectedUnite}</div>
                                                            ) : null}
                                                        </div>*/}
                                                    </div>

                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Tarif
                                                                                                            (DH)</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="tarif" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={(expertServices?.expert.tarifParDefaut + ' DH') || ''}
                                                            />
                                                        </div>
                                                        {/*<div className={"h-4"}>
                                                            {errors.tarif && touched.tarif ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.tarif}</div>
                                                            ) : null}
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                                    <div className={"w-full"}>
                                                        <label className="text-gray-800 text-sm mb-2 block">Nombre
                                                                                                            maximal de
                                                                                                            séances
                                                                                                            cumulées</label>
                                                        <div className="relative flex items-center">
                                                            <Field name="NbrMaxSeanceCumule" type="text" required
                                                                   className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                                   disabled={true}
                                                                   value={expertServices.expert.nbrMaxSeanceCumule}
                                                            />
                                                        </div>
                                                        {/*<div className={"h-4"}>
                                                            {errors.NbrMaxSeanceCumule && touched.NbrMaxSeanceCumule ? (
                                                                <div
                                                                    className="text-red-500 pt-2 text-xs">{errors.NbrMaxSeanceCumule}</div>
                                                            ) : null}
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                <div className="p-4 bg-gray-50 rounded-md border border-gray-200">
                                                    {/* Titre */}

                                                    <p className="text-sm font-medium mb-2 text-gray-700 flex items-center justify-between">
                                                        <label className="text-gray-800 text-sm  block font-medium">
                                                            Langues sélectionnés :
                                                        </label>
                                                    </p>

                                                    <div className="">
                                                        <div
                                                            className="bg-gray-100 p-4 rounded-md border border-gray-300">
                                                            <div>
                                                                {expertServices.languesTitles.map((title, index) => (
                                                                    <div key={index} className="flex items-center py-1">
                                                                        <input
                                                                            name="selectedService"
                                                                            type="checkbox"
                                                                            id={`service-${index}`}
                                                                            value={title}
                                                                            checked={true}
                                                                            disabled={true}
                                                                            className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                                                        />
                                                                        <label
                                                                            htmlFor={`service-${index}`}
                                                                            className="ml-2 text-sm text-gray-700"
                                                                        >
                                                                            {title}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {expertServices?.expert?.etatExpert && (
                                                    <div
                                                        className={"flex flex-row space-x-5 items-center justify-center"}>
                                                        <div className={"w-full"}>
                                                            <label className="text-gray-800 text-sm mb-2 block">Motif du
                                                                                                                rejet</label>
                                                            <div className="relative flex items-center">
                                                                <textarea
                                                                    id="sujet"
                                                                    name="sujet"
                                                                    rows={7}
                                                                    className={`block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-4 ${getError('sujet') ? 'border-[1px] border-red-500' : ''}`}
                                                                    disabled={true}
                                                                    value={expertServices?.statutValidation || ''}

                                                                    style={{fontSize: '12px'}}
                                                                />

                                                            </div>
                                                            <div className={"h-4"}>
                                                                {displayError('sujet')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {isOpen && (
                                                    <div
                                                        className={"flex flex-row space-x-5 items-center justify-center"}>
                                                        <div className={"w-full"}>
                                                            <label className="text-gray-800 text-sm mb-2 block">Motif du
                                                                                                                rejet</label>
                                                            <div className="relative flex items-center">
                                                                <textarea
                                                                    id="sujet"
                                                                    name="sujet"
                                                                    rows={7}
                                                                    className={`block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-4 ${getError('sujet') ? 'border-[1px] border-red-500' : ''}`}
                                                                    defaultValue={''}
                                                                    onChange={handleChange}
                                                                    ref={sujetField}
                                                                    style={{fontSize: '12px'}}
                                                                />

                                                            </div>
                                                            <div className={"h-4"}>
                                                                {displayError('sujet')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}


                                            </>
                                        )}
                                        {/* Boutons de navigation */}

                                        <div className="mt-32 flex justify-between">
                                            <Button
                                                className="bg-black"
                                                onClick={handlePrev}
                                                disabled={isFirstStep}
                                            >
                                                Prev
                                            </Button>

                                            <>
                                                {!isOpen && (
                                                    <div>
                                                        {isLastStep ? (
                                                            <>
                                                                {!expertServices?.expert?.etatExpert && (
                                                                    <div className={"flex justify-center space-x-2"}>
                                                                        <button type="submit"
                                                                                className="py-2 px-4 text-sm tracking-wide rounded-xl text-white bg-green-700 hover:bg-green-800 focus:outline-none"
                                                                                onClick={handleValide}
                                                                        >
                                                                            Valider
                                                                        </button>
                                                                        <button type="submit"
                                                                                className="py-2 px-4 text-sm tracking-wide rounded-xl text-white bg-red-700 hover:bg-red-800 focus:outline-none"
                                                                                onClick={openPopup}
                                                                        >
                                                                            Rejeter
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Button className="bg-black"
                                                                    onClick={() => handleNext(values, setErrors, setTouched)}
                                                            >
                                                                Next
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                                {isOpen && (
                                                    <div>
                                                        {isLastStep ? (
                                                            <div className={"flex justify-center space-x-2"}>
                                                                <button type="submit"
                                                                        className="py-2 px-4 text-sm tracking-wide rounded-xl text-white bg-green-700 hover:bg-green-800 focus:outline-none"
                                                                        onClick={handleValide}
                                                                >
                                                                    Valider
                                                                </button>
                                                                <button type="submit"
                                                                        className="py-2 px-4 text-sm tracking-wide rounded-xl text-white bg-red-700 hover:bg-red-800 focus:outline-none"
                                                                        onClick={handleRejete}
                                                                >
                                                                    Rejeter
                                                                </button>
                                                            </div>

                                                        ) : (
                                                            <Button className="bg-black"
                                                                    onClick={() => handleNext(values, setErrors, setTouched)}
                                                            >
                                                                Next
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                            {/*{isOpen && (
                                                <div>
                                                    <div
                                                        className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 font-poppins">
                                                        <div className="col-span-full">
                                                            <label htmlFor="sujet"
                                                                   className="block font-medium leading-6 text-gray-500 text-xs">
                                                                Motif de l'annulation<span className="text-red-500">*</span>
                                                            </label>
                                                            <div className="mt-2">
                                                                <textarea
                                                                    id="sujet"
                                                                    name="sujet"
                                                                    rows={7}
                                                                    className={`block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-4 ${getError('sujet') ? 'border-[1px] border-red-500' : ''}`}
                                                                    defaultValue={''}
                                                                    onChange={handleChange}
                                                                    ref={sujetField}
                                                                    style={{fontSize: '12px'}}
                                                                />
                                                                {displayError('sujet')}
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="mt-4 flex items-center justify-center gap-x-6">

                                                        <button

                                                            onClick={handleRejete}
                                                            className="rounded-md bg-red-600 w-full px-3 py-2 text-sm text-center  font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Annuler la reservation
                                                        </button>
                                                    </div>
                                                    <div className="w-full mt-5 mb-3">
                                                        <hr className="h-[2px] rounded w-full bg-gray-300"/>
                                                    </div>
                                                    <div className="grid grid-cols-1 items-center justify-center gap-x-6">
                                                        <label htmlFor="sujet"
                                                               className="block font-medium leading-6 text-gray-500 text-xs">
                                                            Si vous changez d'avis
                                                            Vous pouvez toujours valider la réservation en continuant le
                                                            processus.
                                                            Si vous avez changé d'avis concernant l'annulation et que vous
                                                    souhaitez confirmer votre réservation, cliquez sur le bouton
                                                    ci-dessous.
                                                        </label>
                                                        <div className={" w-full mt-2"}>
                                                            <button
                                                                onClick={handleValide}
                                                                className=" block rounded-md bg-green-600 w-full px-3 py-2 text-sm text-center font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                Accepter la reservation
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}*/}
                                            {/*{!isOpen &&
                                                <div>


                                                    <div
                                                        className="grid grid-cols-1 mt-2 items-center justify-center gap-x-6">
                                                        <label htmlFor="sujet"
                                                               className="block font-medium leading-6 text-gray-500 text-xs">
                                                            Si vous changez d'avis
                                                            Confirmer la réservation pour finaliser le processus.
                                                        </label>
                                                        <div className={" w-full mt-2"}>
                                                            <button
                                                                onClick={handleValide}
                                                                className=" block rounded-md bg-green-600 w-full px-3 py-2 text-sm text-center font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                Accepter la reservation
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="grid grid-cols-1 mt-2 items-center justify-center gap-x-6">

                                                        <div>
                                                            <label htmlFor="sujet"
                                                                   className="block font-medium leading-6 text-gray-500 text-xs">
                                                                Si vous changez d'avis
                                                                Si vous avez besoin d'annuler cette réservation, veuillez
                                                                cliquer
                                                                ci-dessous.
                                                            </label>
                                                            <button
                                                                onClick={openPopup}
                                                                className="rounded-md bg-red-600 mt-2 w-full px-3 py-2 text-sm text-center  font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                Annuler la reservation
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            }*/}

                                        </div>
                                        {/*<div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                S'inscrire
                                            </button>
                                        </div>*/}
                                        {/*                                        <p className="text-gray-800 text-sm !mt-8 text-center">Vous avez déja un compte
                                                                                               ?
                                            <Link to="/login"
                                                  className="text-third hover:underline ml-1 whitespace-nowrap font-semibold">Connecter-vous
                                                                                                                              maintenant</Link>
                                        </p>*/}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <Link to='/'
                                  className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
                                  title="Return Home">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                                <span>Return Home</span>
                            </Link>
                        </div>
                        {/*                        <StepperWithIcon></StepperWithIcon>*/}
                    </div>

                </div>
            </div>
        </>
    );
}

export default RegisterExpert;