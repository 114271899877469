import HeaderSimple from "./HeaderSimple";
import React, { useEffect, useState } from 'react';
import FooterBar from "./FooterBar";
import VerificationPopup from "../ConfirmationPages/VerificationPopup";
import backImgTel from "../../images/Union_tel2.webp";
import backMeet from "../../images/Background_Meet.webp";
import { useLocation, useNavigate, useParams } from "react-router";
import JitsiMeetComponent from "./JitsiMeetComponent";
import JitsiMeet from "../JItsiMeet";
import { jwtDecode } from "jwt-decode";
import TimeSelector from "../TimeSelector";
import Countdown from "../MeetCountDown";
const PageMeet = () => {
    const jetsiUrl = 'https://8x8.vc/vpaas-magic-cookie-8796d75b3e99425fae680de9e2afaa26/677?jwt=eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtODc5NmQ3NWIzZTk5NDI1ZmFlNjgwZGU5ZTJhZmFhMjYvNzE5Njg5IiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsInN1YiI6InZwYWFzLW1hZ2ljLWNvb2tpZS04Nzk2ZDc1YjNlOTk0MjVmYWU2ODBkZTllMmFmYWEyNiIsIm5iZiI6MTcyMDc4MDE5NSwiaXNzIjoiY2hhdCIsImNvbnRleHQiOnsiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6dHJ1ZSwib3V0Ym91bmQtY2FsbCI6dHJ1ZSwic2lwLW91dGJvdW5kLWNhbGwiOnRydWUsInRyYW5zY3JpcHRpb24iOnRydWUsInJlY29yZGluZyI6ZmFsc2V9LCJ1c2VyIjp7ImhpZGRlbi1mcm9tLXJlY29yZGVyIjpmYWxzZSwibW9kZXJhdG9yIjpmYWxzZX19LCJleHAiOjE3MjA4MTA4MDAsImlhdCI6MTcyMDc4MDIwMCwicm9vbSI6IioifQ.G23G3Z4wrWn8Km14H9yC156GToHQEnaSO0PC0kBTVY_TNNxJH9lleOch-GqtE5380jzz6yQPZv3_ucIFe02Dh50es2jzJXF6NU4ISOtbZL6VYJKxICJ6q3Evigf6L36OawbfG_i2h0-u_jKlZZ_eF_y5n_4DlONnvuY5Y4fQJo2N_wJBd_1kShGAcVdRyExkJI-uWEMLeJEjdz8nToPCbheEj8Igy_c9kD-D-canGzskVvCxR-Y0IdOv2VvJ7NviH9PSJuUef1Q2U8tN1HNYNDortETadNjYXLE6M7QmDCmTOQfMsZFLnV_cXK3CviXJ_uv_vhCUPbj0fJkMSlweOg#config.prejoinPageEnabled=false&config.startWithAudioMuted=false&config.startWithVideoMuted=false&config.startWithScreenSharing=true';
    const [isVerified, setIsVerified] = useState(false);
    const [openPopup, setOpenPopup] = useState(true);
    const [jwt, setJwt] = useState()
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState();
    const [affiliation, setAffiliation] = useState(null)
    // Function to update parent's state
    const updateInfoVisioCall = (debut, fin) => {
        setDateDebut(debut)
        setDateFin(fin)
    };

    const updateExpiration = (newBonusMinutes) => {
        const updatedDate = new Date(dateFin);
        updatedDate.setMinutes(updatedDate.getMinutes() + parseInt(newBonusMinutes, 10));
        setDateFin(updatedDate); // Met à jour l’état local
    };

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    // Function to get query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    useEffect(() => {
        try {
            const token = getQueryParams(location.search).get('jwt');
            setJwt(token)
            let decoded = jwtDecode(token)
            setAffiliation(decoded.context.user.affiliation)
            // Afficher la pop-up au chargement de la page
            setOpenPopup(true);
        } catch (error) {
            console.error("JWT decoding error:", error);
            // Redirect to 404 page on error
            navigate('/404')
        }
    }, []);
    const handleVerified = () => {
        setIsVerified(true);
        setOpenPopup(false);
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <>
            <style jsx>{`
                @media (max-width: 768px) {
                    .dialog {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    .dialog {
                        background-image: url(${backMeet});
                        background-size: cover;
                    }
                }
            `}</style>
            <div className={"dialog"}>
                {<VerificationPopup
                    open={openPopup}
                    affiliation={affiliation}
                    updateInfoVisioCall={updateInfoVisioCall}
                    roomName={id}
                    onClose={() => setOpenPopup(true)}
                    onVerified={handleVerified}
                />}
                {isVerified && (
                    <div>
                        <div className="min-h-screen flex flex-col justify-center items-center">
                            <div className="flex-col w-3/4  flex justify-center items-center">
                                <h2 className="text-3xl font-bold mb-5 shadow-lg text-white">Bienvenue à notre réunion</h2>
                                <JitsiMeet dateDebut={dateDebut} dateFin={dateFin} roomName={id} jwt={jwt} />
                            </div>
                        </div>
                        {/* Floating Action Button */}
                        {affiliation == "owner" && <button
                            onClick={() => setIsPopupOpen(true)}
                            className="fixed bottom-6 right-6 bg-green-600 hover:bg-green-700 text-white p-4 rounded-full shadow-lg transition flex items-center justify-center"
                            aria-label="Add Meeting Time"
                        >
                            {/* Icon for the button */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                            </svg>
                        </button>}
                        {affiliation == "owner" && <TimeSelector
                            isOpen={isPopupOpen}
                            onClose={() => setIsPopupOpen(false)} // Remplacez par votre logique réelle
                            roomName={id}
                            dateFin={dateFin}
                            updateExpiration={updateExpiration} // Passez la fonction pour mettre à jour la date
                            token={jwt}
                        />}
                    </div>
                )}
                {/*{isVerified && (
                    <div>
                        <div className="min-h-screen flex flex-col justify-center items-center">
                            <div className="flex-col w-3/4  flex justify-center items-center">
                                <h2 className="text-3xl font-bold mb-5 shadow-lg text-white">Bienvenue à notre réunion</h2>
                                <div
                                    className=" w-full rounded-2xl shadow-[0_2px_8px_-1px_rgba(0,0,0,0.3)] ring-4 ring-white md:w-3/4 h-[500px] mx-auto">
                                    <iframe
                                        src={`${jetsiUrl}`}
                                        allow="microphone; camera; display-capture;"
                                        className="w-full rounded-2xl h-full"
                                        allowFullScreen
                                        frameBorder="0"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </>
    );
};
export default PageMeet;