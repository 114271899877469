import React, {lazy, memo, Suspense, useEffect, useState} from 'react';
import backImg from '../../images/Union.webp';
import backImgTel from '../../images/Union_tel2.webp';
import {getDomaineService} from "../../Services/DomaineService";

import {toast} from "react-toastify";
import { useHashedNavigate } from '../../utils/useHashedNavigate';

const Navbar = lazy(() => import('./Navbar'));
export default memo(function Header() {
    const [domaineServices, setDomaineServices] = useState([]);
    const [selectedDomaine, setSelectedDomaine] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const navigate = useHashedNavigate();

    const handleSearch = async () => {
        if (selectedService) {
            try {
                navigate("/experts/:id",selectedService);
            } catch (error) {
                console.error('Erreur lors de la récupération des experts:', error);
            }

        } else {
            toast.info('Veuillez choisir un service.');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                setDomaineServices(domainesService);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    return (
        <header
            className="relative pb-5  w-full md:h-screen md:w-screen text-white md:bg-cover md:bg-bottom flex flex-col"
        >
            <style jsx>{`
                @media (max-width: 768px) {
                    header {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    header {
                        background-image: url(${backImg});
                    }
                }
            `}</style>
            <Suspense fallback={<div>Chargement...</div>}>
                <Navbar />
            </Suspense>
            <div
                className="w-full items-start p-8 justify-center md:w-3/4 mt-20 md:mx-auto font-montserrat flex-grow flex flex-col">
                <div className="text-2xl xl:text-4xl">
                    <h1 className="font-montserrat font-extrabold  ">Prenez rendez-vous en ligne </h1>
                    <h1 className="font-montserrat font-extrabold  ">avec un de nos experts </h1>
                </div>
                <div className="mt-4 text-white">
                    <p className="font-montserrat font-medium">Pas besoin de se déplacer, nos experts sont là pour vous assister, vous guider et accélérer votre réussite</p>
                    <p className="font-montserrat font-medium">Une façon simple, efficace et écologique pour trouver les meilleurs experts</p>
                </div>
                <div className="mt-10 ">
                    <a href="#Reserver"
                       type="submit"
                       className="rounded-3xl bg-green-700 px-8 py-4 font-medium text-white hover:bg-green-800">
                        Réserver une consultation
                    </a>
                </div>
            </div>
            <div className="consulte flex p-6  justify-center md:mt-0 mt-80" id="Reserver">
                <div
                    className="extra-content rounded-xl bg-white px-8 py-6  text-sm font-montserrat font-bold text-black"
                >
                    <h1 className="font-bold text-2xl mb-5 text-center">Réserver une consultation ?</h1>
                    <div className="flex flex-col md:flex-row md:space-x-6  items-start">
                        <div className="select-container flex flex-col">
                            <label htmlFor="area" className="mb-2 text-gray-700 font-medium">Domaine *</label>
                            <select id="area"
                                    className="select-box h-12 p-2 font-normal w-72 text-lg border bg-gray-100 text-gray-500 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={selectedDomaine}
                                    onChange={(e) => setSelectedDomaine(e.target.value)}
                            >
                                <option value="">Choisissez un domaine</option>
                                {domaineServices.map((domaine) => (
                                    <option key={domaine.idDomaine} value={domaine.idDomaine}>
                                        {domaine.nomDomaine}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select-container flex flex-col mt-2 md:mt-0">
                            <label htmlFor="category" className="mb-2 text-gray-700 font-medium">Service *</label>
                            {domaineServices && (
                                <select id="category"
                                        className="select-box h-12 p-2 w-72 font-normal text-lg border bg-gray-100 border-gray-300 text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={selectedService}
                                        onChange={(e) => setSelectedService(e.target.value)}
                                >
                                    <option value="">Choisissez un service</option>
                                    {domaineServices
                                        .find((domaine) => domaine.idDomaine === parseInt(selectedDomaine))
                                        ?.services.map((service) => (
                                            <option key={service.idService} value={service.idService}>
                                                {service.nomService}
                                            </option>
                                        ))}
                                </select>
                            )}
                        </div>
                        <button
                            className="search-button px-8 py-3 bg-green-700 font-medium text-sm text-white mt-4 md:mt-7 rounded-3xl hover:bg-green-800"
                            onClick={handleSearch}
                        >
                            Rechercher
                        </button>
                    </div>
                </div>
            </div>
        </header>


    );
})
