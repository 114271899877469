import React, { useState, useRef, useEffect } from 'react';
import { BotMessageSquare, Send } from 'lucide-react';
import { ChatMessage } from './ChatMessage';
import { ExpertCard } from './ExpertCard';
import axios from 'axios';
import { useHashedNavigate } from '../../utils/useHashedNavigate';

const INITIAL_MESSAGE = {
  id: '1',
  content: `
**Bonjour 😊 !** Je suis votre assistant IA 🤖.
 
Je suis là pour vous aider à trouver l'expert idéal adapté à vos besoins. 

N'hésitez pas à être aussi précis que possible pour que je puisse vous offrir les meilleures options possibles !

**Exemple de demande :**  
> Je cherche un expert dans le domaine de ***[indiquez le domaine]*** pour ***[décrivez votre besoin]***.  

**Par exemple :** 
- Je cherche un expert dans le domaine de **droit** pour **une consultation juridique sur la création d'entreprise.**  
- Je cherche un expert dans le domaine de **marketing** pour **développer une stratégie digitale.**
  `,
  sender: 'bot',
  timestamp: new Date()
};


function ChatBot() {
  const [messages, setMessages] = useState([INITIAL_MESSAGE]);
  const [inputValue, setInputValue] = useState('');
  const [showExperts, setShowExperts] = useState(false);
  const [experts, setExperts] = useState()
  const [showChat,setShowChat] = useState(false)
  const messagesEndRef = useRef(null);

  const [isMinimized, setIsMinimized] = useState(false); // État pour minimiser


  function getDateInTimezone(timezone) {
    const now = new Date();
    // Create an Intl.DateTimeFormat object to get the offset
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const parts = formatter.formatToParts(now);
    const year = parts.find(p => p.type === 'year').value;
    const month = parts.find(p => p.type === 'month').value;
    const day = parts.find(p => p.type === 'day').value;
    const hour = parts.find(p => p.type === 'hour').value;
    const minute = parts.find(p => p.type === 'minute').value;
    const second = parts.find(p => p.type === 'second').value;
    // Construct the date string
    const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    // Return as a Date object
    return new Date(dateString);
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getLastUserMessages = (messages) => {
    // Filtrer les messages envoyés par 'user'
    const userMessages = messages.filter(message => message.sender === 'user');

    // Récupérer les deux derniers messages ou moins
    const lastTwoMessages = userMessages.slice(-2);

    // Formater les messages disponibles en Q1, Q2
    if (lastTwoMessages.length > 0) {
      return lastTwoMessages
        .map((msg, index) => `Q${index + 1}: ${msg.content}`)
        .join('\n');
    }

    return "";
  };


  const handleSend = async () => {
    if (!inputValue.trim()) return;
    setShowExperts(false)
    const userMessage = {
      id: getDateInTimezone(window._env_.REACT_APP_TIMEZONE).toString(),
      content: inputValue,
      sender: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInputValue('');

    let headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json"
    }

    let bodyContent = JSON.stringify({
      "prompt": inputValue,
      "historique": getLastUserMessages(messages)
    });

    let reqOptions = {
      url: window._env_.REACT_APP_CHAT_BOT,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    }

    try {
      let response = await axios.request(reqOptions);
      console.log(response.data);

      // Extraire les experts de la réponse
      const experts = response.data.response.experts;

      if (experts && experts.length > 0) {
        /* const botMessage = {
          id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
          content: response.data.response.textual_explanation,
          sender: 'bot',
          timestamp: new Date()
        }; */

        const botMessageSuggestion = {
          id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
          content: "Voici une liste d'experts que nous vous recommandons.",
          sender: 'bot',
          timestamp: new Date()
        };

        setExperts(response.data.response.experts)

        // Ajouter le message du bot
        setMessages(prev => [...prev, botMessageSuggestion]);

        // Montrer les experts
        setShowExperts(true);
      } else {
        /* const botMessage = {
          id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
          content: response.data.response.textual_explanation,
          sender: 'bot',
          timestamp: new Date()
        }; */

        if (response.data.response.json.selected_services_ids.length > 0) {
          const botMessageNotFound = {
            id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
            content: "Nous sommes désolés, mais ce type d'expert n'est pas encore disponible dans notre réseau.",
            sender: 'bot',
            timestamp: new Date()
          };

          // Ajouter un message alternatif si aucun expert n'est trouvé
          setMessages(prev => [...prev, botMessageNotFound]);
        } else {
          const botMessageNotFound = {
            id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
            content: "Le service demandé n'est pas disponible pour le moment ou, malheureusement, aucun service ne correspond à votre demande.",
            sender: 'bot',
            timestamp: new Date()
          };
          setMessages(prev => [...prev ,botMessageNotFound]);
        }
        // Masquer les experts si la liste est vide
        setShowExperts(false);
      }
    } catch (error) {
      console.error("Error fetching experts:", error);

      const errorMessage = {
        id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
        content: "Oops! Something went wrong. Please try again later.",
        sender: 'bot',
        timestamp: new Date()
      };

      // Ajouter un message d'erreur
      setMessages(prev => [...prev, errorMessage]);

      setShowExperts(false);
    }

    // Simulate AI response
    /*setTimeout(() => {
      const botMessage = {
        id: (getDateInTimezone(window._env_.REACT_APP_TIMEZONE) + 1).toString(),
        content: "Based on your needs, I've found some experts who might be able to help. Would you like to see them?",
        sender: 'bot',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, botMessage]);
      setShowExperts(true);
    }, 1000);*/
  };


  const navigate = useHashedNavigate();
  
  const handleExpertSelect = (expert) => {
    navigate("/expertSelected/:id",expert.id_expert);    setShowExperts(false);
  };

  return (
      <>
        <div className="fixed bottom-4 right-4 inline-flex items-center">
          {/* Conteneur pour le bouton et le tooltip */}
          <div className="relative group">
            {/* Bouton principal */}
            <button
                onClick={() => setShowChat(!showChat)}
                className="inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-green-600 hover:bg-green-700 m-0 cursor-pointer border-gray-200 p-0 shadow-lg hover:shadow-xl transition-transform duration-300 transform hover:scale-110"
                type="button"
                aria-haspopup="dialog"
                aria-expanded={showChat ? "true" : "false"}
                data-state={showChat ? "open" : "closed"}
            >
              <span
                  className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-25"></span>
              <BotMessageSquare size={28} color="white"/>
            </button>

            {/* Infobulle */}
            <span
                className="absolute top-1/2 left-[-140%] transform -translate-y-1/2 text-xs text-white bg-gray-800 rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg"
            >
              {showChat ? "Fermer le chat" : "Trouver un expert"}
            </span>
          </div>
        </div>


        {showChat &&
            <div className="max-w-xl fixed bottom-[calc(4rem+1.5rem)] right-0 mx-4">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="bg-green-600 font-poppins text-white p-4 flex items-center justify-between">
                  <div>
                    <h1 className="text-xl font-semibold">Expert Consultation Assistant</h1>
                    <p className="text-green-100 text-sm">Assistant de consultation d'experts</p>
                  </div>
                  <button
                      onClick={() => setShowChat(!showChat)}
                      className="relative group text-white hover:bg-green-100 hover:bg-opacity-75 hover:text-black p-2 rounded-full"
                      aria-label="Réduire"
                  >
    <span
        className="absolute left-[-60px] top-1/2 transform -translate-y-1/2 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      Réduire
    </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        id="ic-minimize"
                    >
                      <path
                          d="M0 0h24v24H0z"
                          fill="none"
                      ></path>
                      <path
                          d="M7 10l5 5 5-5z"
                          fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>


                <div className="max-h-[65vh] flex flex-col">
                  <div className="flex-1 overflow-y-auto p-4 space-y-4">
                    {messages.map(message => (
                        <ChatMessage key={message.id} message={message}/>
                    ))}
                    {showExperts && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                          {experts.map(expert => (
                              <ExpertCard
                                  key={expert.id}
                                  expert={expert}
                                  onSelect={handleExpertSelect}
                              />
                          ))}
                        </div>
                    )}
                    <div ref={messagesEndRef}/>
                  </div>

                  <div className="border-t p-4">
                    <div className="flex gap-2">
                      <input
                          type="text"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                          placeholder="Type your message..."
                          className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:border-green-500"
                      />
                      <button
                          onClick={handleSend}
                          className="bg-green-600 hover:bg-green-700 text-white rounded-lg px-4 py-2 transition-colors"
                      >
                        <Send className="w-5 h-5"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </>
  );
}

export default ChatBot;