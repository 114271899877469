import React from "react";
import { Link } from "react-router-dom";
import { encodeBase64 } from "./utils";

const HashedLink = ({ to, id, ...props }) => {
    if (!id) {
      throw new Error("`id` prop is required for HashedLink");
    }
  
    // Encode the ID and inject it into the URL
    const hashedTo = to.replace(":id", encodeBase64(id));
  
    return <Link to={hashedTo} {...props} />;
  };
  

export default HashedLink;
