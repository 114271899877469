// Export constants using named exports
export const CONSULTER_EXPERT_PROFILE = "CONSULTER_EXPERT_PROFILE";
export const SE_CONNECTER = "SE_CONNECTER";
export const CHANGER_MOT_DE_PASSE = "CHANGER_MOT_DE_PASSE";
export const CREER_NOUVEAU_COMPTE = "CREER_NOUVEAU_COMPTE";
export const REINETIALISER_MOT_DE_PASSE = "REINETIALISER_MOT_DE_PASSE";
export const RESERVER_UN_CRENEAUX = "RESERVER_UN_CRENEAUX";
export const VALIDER_REFUSER_RESERVATION_EXPERT = "VALIDER_REFUSER_RESERVATION_EXPERT";
export const VALIDER_REFUSER_RESERVATION_PROSPECT = "VALIDER_REFUSER_RESERVATION_PROSPECT";
export const TRACER_MEET_END = "TRACER_MEET_END";
export const TRACER_MEET_CONNECTION = "TRACER_MEET_CONNECTION";
export const CONSULTER_SERVICES = "CONSULTER_SERVICES";
export const TRACER_VALIDATION_PRESENCE_MEET = "TRACER_VALIDATION_PRESENCE_MEET";
export const TRACER_AJOUT_TEMPS_MEET = "TRACER_AJOUT_TEMPS_MEET";


export const STATUS_OK = "OK";
export const STATUS_NOK = "NOK";