import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
//import '../styles/FooterBar.css';
import backImg from "../styles/footer_backg.webp";
import logoHbm from '../../images/Xperlink-Blanc-13.webp'
import {Link} from "react-router-dom";

const FooterBar = () => {
    return (
        <footer className="relative w-full mt-5 text-white bg-top bg-cover flex flex-col"
                style={{backgroundImage: `url(${backImg})`}}>
            <div className="footer-content flex flex-col md:flex-row my-12 md:my-24 m-auto justify-center items-center">
                <h1 className="footer-title font-poppins font-semibold text-5xl text-center leading-tight px-5">Faites-vous
                                                                                                                aider
                                                                                                                par un
                                                                                                                expert.</h1>
                <a href="/#Reserver"
                   className="footer-button mt-6 md:mt-0 rounded-3xl bg-green-700 px-8 py-4 font-medium text-white hover:bg-green-800"> Consultez
                                                                                                                                        Maintenant <FontAwesomeIcon
                        icon={faArrowRight} className={"ml-2"}/> </a>
            </div>

            <hr className=" w-3/4 m-auto flex justify-center items-center h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10"/>
            <div
                className="footer-info w-3/4 flex-row my-auto m-auto  justify-center md:space-x-40 top-0 grid grid-cols-2 gap-8 py-8 md:grid-cols-4">
                <div className="left-section space-y-5">
                    <a href="/" className="footer-title-H text-white  w-full font-montserrat font-semibold"><img src={logoHbm}
                                                                                                         alt="MyLogo"
                                                                                                         className="logo  w-[180px]"/></a>
                    <p className="footer-paragraph text-gray-400  font-montserrat font-normal">Nous sommes une équipe de développeurs expérimentés, passionnés par la création de solutions digitales exceptionnelles.</p>
                    <p className="footer-email text-gray-400  font-montserrat font-normal"> contact@experlik.ma</p>
                </div>

                <div className="center-section space-y-5">
                    <h2 className="footer-title-medium text-white font-montserrat font-semibold">Menu</h2>
                    <ul className="footer-menu text-gray-400  font-montserrat font-normal space-y-5">
                        <li><Link to='/' className="hover:text-gray-300">Accueil</Link></li>
                        <li><Link to='/NosServices' className="hover:text-gray-300">Nos services</Link></li>
                        <li><Link to='/NosExperts' className="hover:text-gray-300">Nos experts</Link></li>
                        <li><Link to='/contact' className="hover:text-gray-300">Contact</Link></li>
                    </ul>
                </div>

                <div className="center-section space-y-5">
                    <h2 className="footer-title-medium text-white font-montserrat font-semibold">Support</h2>
                    <ul className="footer-menu text-gray-400  font-montserrat font-normal space-y-5">
                        <li>FAQ</li>
                        <li>Privacy Policy</li>
                        <li>Terms of Service</li>
                    </ul>
                </div>

                <div className="right-section space-y-5">
                    <h2 className="footer-title-medium text-white font-montserrat font-semibold">Social Media</h2>
                    <ul className="footer-menu text-gray-400  font-montserrat font-normal space-y-5">
                        <li><a href="https://www.linkedin.com/company/hbm-solutions/"
                               className="hover:text-gray-300">LinkedIn</a></li>
                        <li><a href="https://www.instagram.com" className="hover:text-gray-300">Instagram</a></li>
                        <li><a href="https://x.com/hbm_solutions" className="hover:text-gray-300">Twitter</a></li>
                        <li><a href="https://web.facebook.com/people/HBM-Solutions/100091359026770/"
                               className="hover:text-gray-300">Facebook</a></li>
                    </ul>
                </div>
            </div>
            <hr className=" w-3/4 m-auto flex justify-center items-center h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10"/>
            <div
                className="copyright-container mt-8 mb-8 text-gray-400 justify-center items-center md:space-x-1 flex flex-col md:flex-row  font-montserrat font-normal ">

                <p className=" ">Copyright © by <a href="https://www.experlik.ma/" className={""}>Experlik</a>.
                </p>
                <p className=" "> All rights reserved.</p>
            </div>
        </footer>
    );
}

export default FooterBar;
