// utils.js
function generateRandomSalt(length = 16) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let salt = "";
  for (let i = 0; i < length; i++) {
      salt += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return salt;
}

export function encodeBase64(id) {
  // Générer un nombre aléatoire et l'ajouter à l'ID
  const randomNumber = Math.random();
  const stringToEncode = `${id};${randomNumber}`;

  // Encoder en Base64
  const base64 = btoa(stringToEncode);

  // Générer deux sels différents
  const saltStart = generateRandomSalt();
  const saltEnd = generateRandomSalt();

  // Ajouter les sels au début et à la fin
  const encodedWithSalt = `${saltStart}${base64}${saltEnd}`;

  return encodedWithSalt;
}

export function decodeBase64(encodedId) {
  // Extraire les deux sels
  const saltStartLength = 16; // Longueur fixe pour le sel de début
  const saltEndLength = 16;   // Longueur fixe pour le sel de fin
  const base64StartIndex = saltStartLength;
  const base64EndIndex = encodedId.length - saltEndLength;

  // Extraire la partie encodée
  const cleaned = encodedId.slice(base64StartIndex, base64EndIndex);

  // Décoder la chaîne nettoyée de Base64
  const decodedString = atob(cleaned);

  // Extraire l'ID (avant le point-virgule)
  const [id] = decodedString.split(";");

  return id;
}

  
  