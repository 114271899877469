import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StepperWithIcon from './StepperWithIcon2';
import StepPersonalInfo from './StepPersonalInfo';
import StepSettings from './StepSettings';
import StepSkills from './StepSkills';

const schema = Yup.object().shape({
    firstName: Yup.string().required('Prénom requis'),
    lastName: Yup.string().required('Nom requis'),
    email: Yup.string().email('Email invalide').required('Email requis'),
    password: Yup.string().min(6, 'Minimum 6 caractères').required('Mot de passe requis'),
    // Ajoutez d'autres validations si nécessaire
});

export default function CreateExpertAccount() {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    const handlePrev = () => setActiveStep((prev) => Math.max(prev - 1, 0));

    const steps = [
        { label: 'Informations personnelles', component: StepPersonalInfo },
        { label: 'Paramétrage', component: StepSettings },
        { label: 'Compétences et certificats', component: StepSkills },
    ];

    console.log('Steps:', steps);
    console.log('Active Step:', activeStep);
    console.log('Step Component:', steps[activeStep]?.component?.name || 'Component not found');


    return (
        <div className="bg-gray-50 dark:bg-primary font-poppins">
            <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                <div className="w-full md:w-2/6">
                    <div className="p-8 rounded-2xl bg-white shadow">
                        <h2 className="text-gray-800 text-center text-2xl font-bold">Créer votre compte expert</h2>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                password: '',
                                // Ajoutez d'autres valeurs initiales
                            }}
                            validationSchema={schema}
                            onSubmit={(values) => {
                                console.log('Form Submitted', values);
                                alert('Formulaire soumis avec succès !');
                            }}
                        >
                            {({ values, errors, touched, setFieldValue }) => {
                                const StepComponent = steps[activeStep].component;

                                return (
                                    <Form>
                                        <StepperWithIcon
                                            activeStep={activeStep}
                                            setActiveStep={setActiveStep}
                                            steps={steps}
                                        />
                                        {/* Render the current step */}
                                        <div className="mt-4">
                                            <StepComponent
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                        <div className="flex justify-between mt-4">
                                            <button
                                                type="button"
                                                onClick={handlePrev}
                                                disabled={activeStep === 0}
                                                className="btn btn-secondary"
                                            >
                                                Précédent
                                            </button>
                                            {activeStep === steps.length - 1 ? (
                                                <button type="submit" className="btn btn-primary">
                                                    Soumettre
                                                </button>
                                            ) : (
                                                <button type="button" onClick={handleNext} className="btn btn-primary">
                                                    Suivant
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

