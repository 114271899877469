import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import HeaderSimple from "./Pages/HeaderSimple";
import {toast} from "react-toastify";
import { getPublicIP } from "../utils/getIpAdress";
import { REINETIALISER_MOT_DE_PASSE, SE_CONNECTER, STATUS_NOK, STATUS_OK } from "../utils/eventsTypes";
import apiClient from "../Services/apiService";


const schema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/
            , "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial")
        .required("Mot de passe est obligatoire"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
});

const checkAuthentication  = () => {
    const userFirstName = window.localStorage.getItem('userFirstName');
    const sessionExpireAt = window.localStorage.getItem('session_expire_at');
    const isSessionValid = sessionExpireAt && new Date(sessionExpireAt) > new Date();

    // Vérifier si un utilisateur est connecté et si la session est toujours valide
    return userFirstName && isSessionValid;
};


const ResetPassword = () => {
    const [responseStatus, setResponseStatus] = useState(null)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const [showPopup, setShowPopup] = useState(true);
    const location = useLocation();
    const signIn = useSignIn();
    const isAuthenticated = checkAuthentication();


    const traceEvent = async (status, message,token) => {
        let eventPayload;
        // Check if `ids` is a comma-separated list or a single number                // Build the payload
        eventPayload = {
            eventType: REINETIALISER_MOT_DE_PASSE,
            ipAddress: await getPublicIP(), // Optional
            parameter: token,
            status: status,
            description: message
        };

        try {
            const response = await apiClient.post("/events/trace", eventPayload, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            // Handle error response
            if (error.response) {
                // Server returned an error response
                console.error(error.response.data);
            } else {
                // Network or other error
                console.error("An unexpected error occurred");
            }
        }

    };

    const navigate = useNavigate()

    // Function to extract token from the URL
    const getResetTokenFromURL = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('token');
    };

    const resetToken = getResetTokenFromURL();

    // Handle form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        setResponseStatus('');

        try {
            const response = await fetch(`${window._env_.REACT_APP_BACKEND_URL}/api/v1/auth/reset-password?token=${resetToken}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPassword: values.newPassword,
                }),
            });

            if (response.ok) {
                //setResponseStatus('Mot de passe réinitialisé avec succès');
                toast.success("Mot de passe réinitialisé avec succès")
                traceEvent(STATUS_OK,'Mot de passe reinitialise avec succes',resetToken)
                setTimeout(() => {
                    navigate('/login'); // Redirect to login page after success
                }, 2000);
            } else if (response.status === 400) {
                //setResponseStatus('Le token de réinitialisation est invalide ou expiré');
                toast.error('Le token de réinitialisation est invalide ou expiré');
                traceEvent(STATUS_NOK,'Le token de reinitialisation est invalide ou expire',resetToken)
            } else {
                //setResponseStatus('Une erreur est survenue lors de la réinitialisation du mot de passe');
                toast.error('Une erreur est survenue lors de la réinitialisation du mot de passe');
                traceEvent(STATUS_NOK,'Une erreur est survenue lors de la reinitialisation du mot de passe',resetToken)
            }
        } catch (error) {
            setResponseStatus('Erreur de connexion au serveur');
            traceEvent(STATUS_NOK,'Erreur de connexion au serveur.',resetToken)
        }

        setSubmitting(false);
    };

    // Function to get the current date and time plus 30 minutes
    const getExpirationDate = (minutesToAdd) => {
        const now = new Date(); // Current date and time
        now.setMinutes(now.getMinutes() + minutesToAdd); // Add minutes
        return now;
    };



    return (
        <>
            <HeaderSimple
                mainTitle="Mettre à Jour Votre Mot de Passe"
                subTitle={'Sécurisez votre compte en modifiant votre mot de passe ! '}
            ></HeaderSimple>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className=" flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                        <div className="p-8 rounded-2xl bg-white shadow">
                            <Formik
                                initialValues={{
                                    newPassword: '',
                                    passwordConfirmation: ''
                                }}
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                            >
                                {({errors, touched}) => (

                                    <Form className="h-auto space-y-4">
                                        {responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Nouveau mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="newPassword" type={visiblePassword ? 'text' : 'password'}
                                                       required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Entrez votre nouveau mot de passe"/>
                                                <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4 cursor-pointer"
                                                     viewBox="0 0 128 128">
                                                    <path
                                                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.newPassword && touched.newPassword ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.newPassword}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Confirmer le nouveau mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="passwordConfirmation" type="password" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Confirmez votre nouveau mot de passe"/>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                    <div
                                                        className="text-red-500 pt-2 text-xs">{errors.passwordConfirmation}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                Réinitialiser le mot de passe
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default ResetPassword;