import React, {useEffect, useState} from 'react';
import apiClient from "../Services/apiService";


function ExpertComponent({ expert, onToggleReviews, isReviewsVisible }) {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);
    const [averageRating, setAverageRating] = useState(null);
    const [languages, setLanguages] = useState([]);
    //const [isReviewsVisible, setIsReviewsVisible] = useState(false);

    /*const handleToggle = () => {
        setIsReviewsVisible(!isReviewsVisible);
        onToggleReviews();
    };*/

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await apiClient.get(`/AvisClient/expert/${expert.idExpert}`);
                const fetchedReviews = response.data;
                const langue = await apiClient.get(`/ExpertLangue/expert/${expert.idExpert}`);
                const fetchedLanguages = langue.data;
                // Éviter les doublons d'icônes (par exemple, Amazigh)
                const uniqueFlags = [];
                const seenIcons = new Set();

                fetchedLanguages.forEach((lang) => {
                    // Vérifier si le nom commence par "Amazigh"
                    if (!seenIcons.has("Amazigh") && lang.langue.nomLangue.startsWith("Amazigh")) {
                        uniqueFlags.push(lang);
                        seenIcons.add("Amazigh");
                    } else if (!lang.langue.nomLangue.startsWith("Amazigh")) {
                        // Ajouter directement les langues qui ne commencent pas par "Amazigh"
                        uniqueFlags.push(lang);
                        seenIcons.add(lang.langue.nomLangue);
                    }
                });

                setLanguages(uniqueFlags);

                setReviews(fetchedReviews);
                setError(null); // Réinitialiser l'erreur si la récupération réussit

                // Calcul de la note moyenne uniquement pour les avis publiables
                const publiableReviews = fetchedReviews.filter(review => review.publiable);
                const totalRating = publiableReviews.reduce((sum, review) => sum + review.evaluationProspect, 0);
                const average = publiableReviews.length > 0 ? (totalRating / publiableReviews.length).toFixed(1) : null;

                setAverageRating(average);
            } catch (err) {
                console.error("Erreur lors de la récupération des avis :", err);
                setError("Impossible de récupérer les avis. Veuillez réessayer.");
            }
        };

        if (expert?.idExpert) {
            fetchReviews();
        }
    }, [expert]);

    return (
        <div
            className='flex flex-col max-h-full col-span-1 mb-5 justify-self-end mr-5 md:mr-11 items-center justify-center h-[32rem] pb-10 w-full md:w-[535px] bg-[length:auto_553px] drop-shadow-[0_14px_6px_rgba(0,0,0,0.1)]  bg-hero bg-no-repeat bg-center '
        >
            <img
                className="w-40 h-40 p-1 mb-3 rounded-full shadow-lg ring-2 ring-amber-300 dark:ring-amber-300"
                src={`data:image/jpeg;base64,${expert.photoExpert}`}
                alt="Bonnie image"
            />
            <div className='flex flex-row items-center '>
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-2A3F3F- flex items-center">
                    {expert.nomExpert} {expert.prenomExpert}
                </h5>

                <svg
                    className="w-4 h-4 text-yellow-300 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                >
                    <path
                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                    />
                </svg>
                {averageRating ? (
                    <span className="ms-1 text-sm font-bold underline hover:text-green-700 cursor-pointer text-1B3FA0"
                          onClick={onToggleReviews}>{averageRating}</span>
                ) : (
                    <span className="ms-1 text-sm font-bold text-gray-900">0,0</span>
                )}


            </div>


            <span className="text-md font-semibold text-1B3FA0 dark:text-1B3FA0">{expert.titreExpert}</span>
            <span className="text-sm font-medium mt-2 overflow-hidden w-72 line-clamp-4">
            {expert.descriptionExpert}
            </span>
            <div className="flex flex-col items-center mt-2 ">
                {/*<span className="text-sm font-semibold text-1B3FA0 dark:text-1B3FA0 ">Langue</span>*/}
                <div className="flex items-center justify-end space-x-1">
                    {languages.map((lang) => (
                        <div className="flex flex-col items-center" key={lang.idExpertlangue}>
                            <label className="block text-xs text-center">{lang.langue.abbreviationLangue}</label>
                            <img
                                className="w-6 h-6 rounded-full"
                                src={lang.langue.iconLangue}
                                alt={`Flag of ${lang.langue.nomLangue}`}
                                title={lang.langue.nomLangue} // Tooltip au survol
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col items-center mt-2 ">
                <span className="text-sm font-semibold text-1B3FA0 dark:text-1B3FA0 ">Tarif de consultation<span
                    className="text-md font-semibold"> ({expert.uniteMinutes} minutes)</span></span>
                <span
                    className="flex items-center justify-center gap-2 mt-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg text-sm px-2 py-1 shadow-lg">
                    <span className="text-lg font-semibold">{expert.tarifParDefaut.tarif} MAD</span>

                </span>
            </div>

            <div className="p-5 fixed bottom-0 items-center w-full">


                {/* Avis à gauche */}

                <div className={"flex items-center justify-center"}>
                    <a
                        onClick={onToggleReviews}
                        className="text-sm font-poppins font-medium text-1B3FA0  underline hover:underline-offset-4 hover:text-green-700 transition duration-200 cursor-pointer active:scale-95"
                    >
                        {isReviewsVisible ? "Voir créneau" : "Voir avis"}
                    </a>
                </div>

                {/* Drapeaux à gauche */}
                {/*<div className="flex items-center  mr-20 justify-end space-x-0.5">
                    <img
                        className="w-5 h-5 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
                        alt="Flag of France"
                    />
                    <img
                        className="w-5 h-5 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Morocco.svg/langfr-1280px-Flag_of_Morocco.svg.png"
                        alt="Flag of Morocco"
                    />
                    <img
                        className="w-5 h-5 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Berber_flag.svg"
                        alt="Berber flag"
                    />
                    <img
                        className="w-5 h-5 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png"
                        alt="Flag of United Kingdom"
                    />
                    {languages.map((lang) => (
                        <>
                            <label className="block text-xs text-center">{lang.langue.abbreviationLangue}</label>
                            <img
                                key={lang.idExpertlangue}
                                className="w-5 h-5 rounded-full"
                                src={lang.langue.iconLangue}
                                alt={`Flag of ${lang.langue.nomLangue}`}
                                title={lang.langue.nomLangue} // Tooltip au survol
                            />
                        </>

                    ))}
                </div>*/}


            </div>


        </div>
    );
}

export default ExpertComponent;

