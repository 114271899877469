import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getPlanning} from "../Services/PlaningService";
import {getExpertServicesById} from "../Services/ListExpertService";
import iconExp from "../images/professional_life.webp"
import iconCrtf from "../images/icon-certificate.webp"
import iconEduca from "../images/Education.webp"
import apiClient from "../Services/apiService";
import { useHashedNavigate } from '../utils/useHashedNavigate';

function PlanningComponent({
                               startDate,
                               handlePrevWeek,
                               handleNextWeek,
                               renderDays,
                               expert,
                               expertsData,
                               serviceId,
                               isReviewsVisible,
                               setReviewsVisible
                           }) {
    const [planningCreneaus, setPlanningCreneaus] = useState({});
    const [creneauxDisponibles, setCreneauxDisponibles] = useState(true);
    const currentDate = new Date(); // Obtenez la date actuelle
    const currentHour = currentDate.getHours(); // Obtenez l'heure actuelle
    const [expertServices, setExpertServices] = useState([]);
    const [expertCertificat, setExpertCertificat] = useState([]);
    const [expertCompetence, setExpertCompetence] = useState([]);
    const [expertFormation, setExpertFormation] = useState([]);
    const [expertExperience, setExpertExperience] = useState([]);
    const [expertDetails, setExpertDetails] = useState([]);
    const fetchPlanningInterval = useRef();
    const navigate = useNavigate();
    const hashedNavigate = useHashedNavigate();
    // Déclarez un état pour stocker si une heure est sélectionnée ou non
    const [isHourSelected, setIsHourSelected] = useState(false);
    // Déclarez un état pour stocker l'heure sélectionnée
    const [selectedHour, setSelectedHour] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    //const [isReviewsVisible, setIsReviewsVisible] = useState(false); // Ajout de l'état
    const [isProfileVisible, setIsProfileVisible] = useState(false); // État pour afficher/masquer le profil
    const [isCreneauVisible, setIsCreneauVisible] = useState(true);
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);

    /*const handleToggleReviews = () => {
        setIsReviewsVisible(!isReviewsVisible);
    };*/

    // Fonction pour afficher les créneaux

    // Fonction pour fermer les avis
    const handleFermerAvis = () => {
        setReviewsVisible(false);
        setCreneauxDisponibles(true);
    };

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await apiClient.get(`/AvisClient/expert/${expert.idExpert}`);
                setReviews(response.data);
                setError(null); // Réinitialiser l'erreur si la récupération réussit
            } catch (err) {
                console.error("Erreur lors de la récupération des avis :", err);
                setError("Impossible de récupérer les avis. Veuillez réessayer.");
            }
        };

        if (expert?.idExpert) {
            fetchReviews();
        }
    }, [expert]);

    /*useEffect(() => {
        const fetchData = async () => {
            try {
                // Récupérer les services de l'expert et mettre à jour l'état expertServices
                const services = await getExpertServicesById(expert.idExpert);
                const certificats = await getExpertCertificatById(expert.idExpert);
                const competences = await getExpertCompetenceById(expert.idExpert);
                const formations = await getExpertFormationById(expert.idExpert);
                const experiences = await getExpertExperienceById(expert.idExpert);
                setExpertCertificat(certificats);
                setExpertCompetence(competences);
                setExpertServices(services);
                setExpertFormation(formations);
                setExpertExperience(experiences);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Afficher un message d'erreur à l'utilisateur si nécessaire
            }finally {
                setIsLoading(false); // Définir isLoading à false une fois le chargement terminé
            }
        };

        fetchData();
    }, [expert]);*/

    const fetchData = async () => {
        try {
            // Récupérer les services de l'expert et mettre à jour l'état expertServices
            const services = await getExpertServicesById(expert.idExpert);
            //console.log("services",services);
            /*            const certificats = await getExpertCertificatById(expert.idExpert);
                        const competences = await getExpertCompetenceById(expert.idExpert);
                        const formations = await getExpertFormationById(expert.idExpert);
                        const experiences = await getExpertExperienceById(expert.idExpert);*/
            const details = await apiClient.get(`/experts/${expert.idExpert}/titles`)
            /*setExpertCertificat(certificats);
            setExpertCompetence(competences);*/
            setExpertServices(services);
            /*setExpertFormation(formations);
            setExpertExperience(experiences);*/
            setExpertDetails(details.data);

        } catch (err) {
            console.error("Erreur lors de la récupération des services de l'expert :", err);
            // Afficher un message d'erreur à l'utilisateur si nécessaire
        } finally {
            setIsLoading(false); // Définir isLoading à false une fois le chargement terminé
        }
    };

    const resetSelectedHour = () => {
        setSelectedHour(null);
        setIsHourSelected(false);
    };

    // Fonction pour gérer la sélection de l'heure
    const handleHourSelection = (hour) => {
        // Si l'heure sélectionnée est la même que l'heure déjà sélectionnée, désélectionnez-la
        if (selectedHour === hour) {
            resetSelectedHour();
        } else {
            // Sinon, mettez à jour l'heure sélectionnée
            setSelectedHour(hour);
            setIsHourSelected(true);
        }
    };

    useEffect(() => {
        const fetchPlanningData = async () => {
            try {
                const services = await getExpertServicesById(expert.idExpert);
                setExpertServices(services);
                setLoading(true);
                const planningData = await getPlanning(startDate, expert.idExpert);
                setPlanningCreneaus(planningData);
                setLoading(false);
            } catch (err) {
                console.error("Erreur lors de la récupération des données de planification :", err);
                // Afficher un message d'erreur à l'utilisateur si nécessaire
            }
        };
        fetchPlanningData();
        // Définition de l'intervalle de rafraîchissement
        fetchPlanningInterval.current = setInterval(fetchPlanningData, 60000);

        // Nettoyage de l'intervalle lors du démontage du composant
        return () => clearInterval(fetchPlanningInterval.current);
    }, [startDate, expert]);

    console.log(expertServices);
    const handleClickReservation = async () => {
        if (selectedHour) {
            try {
                const idDomaine = expertServices.length > 0 ? expertServices[0].idService.idDomaine : null;
                console.log("domaine id :", idDomaine);
                const dureeEnMinutes = expertUnitMinutesMap[expert.idExpert];

                // Envoyer les informations du créneau sélectionné, telles que idExpert et idPlaningCreneau
                const donneesReservation = {
                    idExpert: expert.idExpert,
                    nomComplet: expert.nomExpert + " " + expert.prenomExpert,
                    titreExpert: expert.titreExpert,
                    idPlaningCreneau: selectedHour,
                    prixTotal: expert.tarifParDefaut.tarif,
                    duree: dureeEnMinutes,
                    idDomaine: idDomaine,
                    idService: serviceId,
                    //idService:idService,
                    // Ajoutez d'autres informations si nécessaire
                };

                // Utilisez navigate pour naviguer vers la page DetailsProspect en passant les données via les paramètres d'URL
                navigate("/DetailsProspect", {state: {reservationData: donneesReservation}});

                // Envoyer ces informations au backend ou effectuer d'autres actions nécessaires
                console.log("Informations du créneau sélectionné :", donneesReservation);
            } catch (error) {
                console.error("Erreur lors de la réservation :", error);
                // Gérer l'erreur
            }
        } else {
            // Afficher une alerte ou une exception
            alert("Veuillez sélectionner une heure avant de réserver.");
            // Vous pouvez également utiliser console.error pour afficher l'erreur dans la console
            console.error("Aucune heure sélectionnée pour la réservation.");
        }
    };

    const expertUnitMinutesMap = useMemo(() => {
        const map = {};

        expertsData.forEach((expertItem) => {
            const dateKey = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
            const expertCreneaux = planningCreneaus[dateKey]?.filter((creneau) => creneau.idCreneau.idExpert === expertItem.idExpert && creneau.idCreneau.unite && creneau.idCreneau.unite.valeurEnMinutes);

            if (expertCreneaux && expertCreneaux.length > 0) {
                map[expertItem.idExpert] = expertCreneaux[0].idCreneau.unite.valeurEnMinutes;
            } else {
                map[expertItem.idExpert] = 60;
            }
        });

        return map;
    }, [expertsData, planningCreneaus, startDate]);


    const handleVoirCreneau = () => {
        // Logique pour afficher les créneaux
        setCreneauxDisponibles(true);
        //setIsReviewsVisible(false);
    };

    const handleCloseReviews = () => {
        setReviewsVisible(false); // Cache les avis
        setCreneauxDisponibles(true); // Affiche les créneaux
    };

    const handleVoirProfile = async () => {
        try {
            // Récupérer les services de l'expert lorsque le bouton "Voir Créneau" est cliqué
            const services = await getExpertServicesById(expert.idExpert);
            // Mettre à jour l'état avec les services récupérés
            setExpertServices(services);
            // Mettre à jour l'état pour afficher les créneaux
            setCreneauxDisponibles(false);
            fetchData();
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des services :", error);
            // Afficher un message d'erreur à l'utilisateur si nécessaire
        }
    };

    const handlePrevWeekLocal = () => {
        handlePrevWeek();
        // Mettre à jour le planning de l'expert ici si nécessaire
    };

    const handleNextWeekLocal = () => {
        handleNextWeek();
        // Mettre à jour le planning de l'expert ici si nécessaire
    };


    const handleClick = (e, serviceId) => {
        e.preventDefault();
        hashedNavigate("/service/:id", serviceId );
        window.location.reload();
    };

    // ...
    const renderPlanning = () => {
        return (
            <div className="flex justify-center w-full  pb-3 text-green-500 h-[363px]">
                {renderDays().map((day, index) => {
                    const date = new Date(startDate);
                    date.setDate(startDate.getDate() + index);
                    const dateKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

                    const allHoursOfDay = [];
                    for (let hour = 9; hour < 24; hour++) {
                        for (let minute = 0; minute < 60; minute += expertUnitMinutesMap[expert.idExpert]) {
                            const formattedHour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                            allHoursOfDay.push(formattedHour);
                        }
                    }

                    return (
                        <div key={index} className="flex flex-col px-1">
                            {allHoursOfDay.map((hour) => {

                                const creneau = planningCreneaus[dateKey]?.find((creneau) => creneau.idCreneau.idExpert === expert.idExpert && creneau.idCreneau.heureDebut === hour);
                                const idPlaningCreneau = creneau ? creneau.idPlaningCreneau : null;
                                let buttonClass = 'border py-px w-[45px] md:w-16 h-6 mb-1 mt-0 rounded font-medium text-sm ';
                                const focusStyle = 'border-2A3F3F bg-blue-800 text-white outline-none ring-4';


                                // Ajoutez une classe pour barrer l'heure si elle est inférieure à l'heure actuelle pour le jour actuel et tous les jours précédents
                                if ((date < currentDate && date.toDateString() !== currentDate.toDateString()) ||
                                    (date.toDateString() === currentDate.toDateString() && parseInt(hour.split(':')[0], 10) < currentHour + 3)) {
                                    buttonClass += 'line-through border-gray-300 bg-gray-200 text-gray-600 cursor-not-allowed';

                                } else if (creneau) {

                                    switch (creneau.statutCreneau) {
                                        case 'DISPONIBLE':
                                            if (idPlaningCreneau === selectedHour) {
                                                buttonClass += '' + focusStyle + '';
                                            } else {
                                                buttonClass += 'border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500';
                                            }
                                            break;
                                        case 'RÉSERVÉ':
                                            buttonClass += 'bg-gray-400 cursor-not-allowed border border-yellew-300 hover:text-green-700 bg-yellow-400 text-gray-600';
                                            break;
                                        case 'OCCUPÉ':
                                            buttonClass += 'border-gray-400 bg-outer-space-600 text-white cursor-not-allowed';
                                            break;
                                        default:
                                            buttonClass += 'border-gray-300 bg-gray-200 text-gray-600 cursor-not-allowed';
                                            break;
                                    }
                                } else {
                                    buttonClass += 'border-gray-300 bg-gray-200 text-gray-600 ';
                                }

                                const isPastHour =
                                    (date < currentDate && date.toDateString() !== currentDate.toDateString()) ||
                                    (date.toDateString() === currentDate.toDateString() && parseInt(hour.split(':')[0], 10) < currentHour + 3);


                                return (
                                    <button
                                        key={hour}
                                        value={idPlaningCreneau}
                                        className={buttonClass}
                                        id="hour-button"
                                        /*disabled={!creneau || creneau.statutCreneau === 'OCCUPÉ' || creneau.statutCreneau === 'RÉSERVÉ'}*/
                                        disabled={
                                            isPastHour || // Désactiver si l'heure est passée
                                            !creneau ||
                                            creneau.statutCreneau === 'OCCUPÉ' ||
                                            creneau.statutCreneau === 'RÉSERVÉ'
                                        }
                                        title={creneau ? creneau.statutCreneau : 'INDISPONIBLE'}
                                        onClick={() => handleHourSelection(idPlaningCreneau)}
                                    >
                                        {hour}
                                    </button>
                                );
                            })}
                        </div>


                    );
                })}
            </div>
        );
    };

// ...
    /*const reviews = [
        {
            name: "Jess Hopkins",
            initial: "J",
            color: "red-500",
            rating: 4.5,
            comment: "Gorgeous design! Even more responsive than the previous version. A pleasure to use!",
            date: "Feb 13, 2021",
        },
        {
            name: "Alice Banks",
            initial: "A",
            color: "yellow-500",
            rating: 4,
            comment: "The device has a clean design and the metal housing feels sturdy in my hands.",
            date: "Feb 13, 2021",
        }, {
            name: "Alice Banks",
            initial: "A",
            color: "yellow-500",
            rating: 3,
            comment: "The device has a clean design and the metal housing feels sturdy in my hands.",
            date: "Feb 13, 2021",
        }, {
            name: "Alice Banks",
            initial: "A",
            color: "yellow-500",
            rating: 5,
            comment: "The device has a clean design and the metal housing feels sturdy in my hands.",
            date: "Feb 13, 2021",
        },
        // Ajoutez d'autres avis ici
    ];*/


    return (

        <div
            className="flex flex-col p-6 max-h-full items-center justify-center  bg-white  rounded-lg w-full md:w-[580px] h-[496px] pr-15 pt-18  shadow-[0_0px_14px_6px_rgba(0,0,0,0.1)] "
        >
            {/* Section des créneaux disponibles */}
            {!isReviewsVisible && (
                <>
                    {creneauxDisponibles ? (
                        <div className="justify-center">
                            <div className="flex justify-center  pb-3">
                                <div
                                    onClick={handlePrevWeekLocal}
                                    className="-rotate-90 cursor-pointer w-3  flex justify-center items-center"
                                    style={{fontSize: '0.8rem'}} // Exemple de réduction de la taille de la police
                                >
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.001 6L6.00098 1L1.00098 6"
                                            stroke="black"
                                            strokeOpacity="0.4"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                {renderDays()}
                                <div
                                    onClick={handleNextWeekLocal}
                                    className="rotate-90 cursor-pointer w-3  flex justify-center items-center"
                                    style={{fontSize: '0.8rem'}} // Exemple de réduction de la taille de la police
                                >
                                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.001 6L6.00098 1L1.00098 6"
                                            stroke="black"
                                            strokeOpacity="0.4"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                            {loading ? (
                                <div className="relative">
                                    {/* Fond visible en arrière-plan */}
                                    <div
                                        className="absolute inset-0 bg-white bg-opacity-30 flex items-center justify-center z-10">
                                        <div className="flex items-center">
                                            <span className="text-md font-poppins mr-4">Chargement...</span>
                                            <svg className="animate-spin h-8 w-8 text-gray-800"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    {/* Le contenu principal */}
                                    <div className="overflow-x-auto justify-center items-center md:w-full">
                                        {renderPlanning()}
                                    </div>
                                </div>
                            ) : (
                                <div className={"overflow-x-auto justify-center items-center md:w-full"}>
                                    {renderPlanning()}
                                </div>
                            )}
                            <div className="flex justify-center pt-3 buttons w-[580px]">
                                <button
                                    onClick={handleVoirProfile}
                                    className="mx-6 rounded-lg px-5 text-sm font-medium text-white bg-26235C py-2.5 hover:bg-26235C focus:outline-none dark:bg-26235C dark:hover:bg-1B3FA0 dark:focus:bg-26235C"
                                >
                                    Voir Profile
                                </button>
                                <button
                                    onClick={handleClickReservation}
                                    disabled={!isHourSelected} // Désactiver le bouton si aucune heure n'est sélectionnée
                                    className={`mx-6 rounded-lg px-5 text-sm font-medium py-2.5 focus:outline-none ${isHourSelected ? 'bg-green-600 text-white hover:bg-green-700' : 'bg-gray-400 text-gray-600 cursor-not-allowed'}`}>Réserver
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="items-center justify-center">
                            <div className="flex w-full justify-center overflow-auto pb-3 h-[416px]">
                                <div className="flex flex-row">
                                    <div className="md:ml-0 mb-5 flex flex-col p-2 md:p-3 w-[200px] md:w-[275px]">
                                        <div className="pb-2 Experience">

                                            <div className="pb-2">
                                        <span
                                            className="block text-sm font-semibold text-1B3FA0 dark:text-1B3FA0">
                                        Expériences professionnelles
                                        </span>
                                            </div>

                                            {/*{expertExperience.map((experiences, index) => (
                                        <div className="flex flex-row">
                                            <div className="w-[25px] h-[25px]">
                                                <label>
                                                    <img src={iconExp} alt="imgExp"/>
                                                </label>
                                            </div>
                                            <div className="ml-2 w-full">
                                                    <span className="block whitespace-normal text-sm font-normal">
                                                    {experiences.titreExperience}
                                                    </span>
                                            </div>
                                        </div>
                                    ))}*/}
                                            {expertDetails && expertDetails["Experience Titles"] && (
                                                <>
                                                    {expertDetails["Experience Titles"].map((title, index) => (

                                                        <div className="flex flex-row">

                                                            <div className="w-[25px] h-[25px]">
                                                                <label>
                                                                    <img src={iconExp} alt="imgExp"/>
                                                                </label>
                                                            </div>
                                                            <div className="ml-2 w-full">

                                                    <span key={index}
                                                          className="block whitespace-normal text-sm font-normal">
                                                    {title}
                                                    </span>

                                                            </div>


                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            {/*{expert.details && expert.details["Service Titles"] && (
                                        <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                            <span className="text-md font-bold text-teal-900">Services : </span>
                                            {expert.details["Service Titles"].map((title, index) => (
                                                <span key={index}> {title} |</span>
                                            ))}
                                        </Typography>
                                    )}*/}
                                        </div>
                                        <div className="pb-2 Education">
                                            <div className="pb-2">
                                        <span
                                            className="block text-sm font-semibold text-1B3FA0 dark:text-1B3FA0">
                                        Formations & Éducation
                                    </span>
                                            </div>

                                            {/*{expertFormation.map((formations, index) => (
                                        <div className="flex flex-row">
                                            <div className="w-[25px] h-[25px]">
                                                <label>
                                                    <img src={iconEduca} alt="imgExp"/>
                                                </label>
                                            </div>
                                            <div className="ml-2 w-full">
                                                    <span className="block whitespace-normal text-sm font-normal">
                                                    {formations.formation.titreFormation}
                                                    </span>
                                            </div>
                                        </div>
                                    ))}*/}
                                            {expertDetails && expertDetails["Formation Titles"] && (
                                                <>
                                                    {expertDetails["Formation Titles"].map((title, index) => (

                                                        <div className="flex flex-row">

                                                            <div className="w-[25px] h-[25px]">
                                                                <label>
                                                                    <img src={iconEduca} alt="imgExp"/>
                                                                </label>
                                                            </div>
                                                            <div className="ml-2 w-full">

                                                    <span key={index}
                                                          className="block whitespace-normal text-sm font-normal">
                                                    {title}
                                                    </span>

                                                            </div>


                                                        </div>
                                                    ))}
                                                </>
                                            )}

                                        </div>
                                        <div className="pb-2 Certif">

                                            <div className="pb-2">
                                        <span
                                            className="block text-sm font-semibold text-1B3FA0 dark:text-1B3FA0">
                                        Certifications
                                    </span>
                                            </div>
                                            {/*{expertCertificat.map((certificats, index) => (
                                        <div className="flex flex-row pb-2">
                                            <div className="w-[25px] h-[25px]">
                                                <label>
                                                    <img src={iconCrtf} alt="imgExp"/>
                                                </label>
                                            </div>
                                            <div className="ml-2 w-full">
                                            <span key={index} className="block whitespace-normal text-sm font-normal">
                                            {certificats.certificat.titreCertificat}
                                            </span>
                                            </div>
                                        </div>
                                    ))}*/}
                                            {expertDetails && expertDetails["Certificat Titles"] && (
                                                <>
                                                    {expertDetails["Certificat Titles"].map((title, index) => (

                                                        <div className="flex flex-row">

                                                            <div className="w-[25px] h-[25px]">
                                                                <label>
                                                                    <img src={iconCrtf} alt="imgExp"/>
                                                                </label>
                                                            </div>
                                                            <div className="ml-2 w-full">

                                                    <span key={index}
                                                          className="block whitespace-normal text-sm font-normal">
                                                    {title}
                                                    </span>

                                                            </div>


                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>


                                    </div>

                                    <div className="w-[2px]">
                                        <hr className="h-full w-full bg-gray-300"/>
                                    </div>
                                    <div className="ml-2 p-2 md:p-3 w-[200px] md:w-[275px]">
                                        <div className="w-full pb-3">
                                            <div className="">
                                    <span
                                        className="block text-sm font-semibold text-1B3FA0 dark:text-1B3FA0">
                                        Services
                                    </span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                {expertServices.map((service, index) => (
                                                    <span
                                                        key={index}
                                                        className="mt-2 mr-2 whitespace-nowrap rounded-full bg-green-600 px-3 py-1 text-center text-xs font-medium text-white"
                                                    >
                                              <button
                                                  //href={`/service/${service.idService.idService}`}
                                                  className="justify-center items-center service-link"
                                                  onClick={(e) => handleClick(e, service.idService.idService)}
                                              >
                                                {service.idService.nomService} "<span
                                                  className={"font-bold underline "}>{service.idService.nomDomaine}</span>"
                                              </button>
                                            </span>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="w-full pb-2 Compet">
                                            <div className="pb-2">
                                        <span
                                            className="block text-sm font-semibold text-1B3FA0 dark:text-1B3FA0">
                                        Compétences
                                    </span>
                                            </div>

                                            <div className="flex flex-row w-[250px]">
                                                <div className="flex flex-wrap">
                                                    {/*{expertCompetence.map((competence, index) => (
                                                <span
                                                    key={index}
                                                    className="mb-2 rounded-lg border border-green-500 bg-white px-2 text-sm font-medium text-green-500 mx-0.5 py-0.5">
                                                {competence.competence.titreCompetence}
                                            </span>
                                            ))}*/}
                                                    {expertDetails && expertDetails["Competence Titles"] && (
                                                        <>
                                                            {expertDetails["Competence Titles"].map((title, index) => (

                                                                <span
                                                                    key={index}
                                                                    className="mb-2 rounded-lg border border-green-500 bg-white px-2 text-sm font-medium text-green-500 mx-0.5 py-0.5">
                                                            {title}
                                                        </span>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="flex justify-center pt-[7px] buttons w-[580px]">
                                {!creneauxDisponibles && (
                                    <>
                                        <button
                                            onClick={handleVoirCreneau}
                                            className="mx-6 rounded-lg px-5 text-sm font-medium text-white bg-26235C py-2.5 hover:bg-26235C focus:outline-none dark:bg-26235C dark:hover:bg-1B3FA0 dark:focus:bg-26235C"
                                        >
                                            Voir Créneau
                                        </button>
                                        {/*                                        <button
                                            //onClick={handleToggleReviews}
                                            className="mx-6 rounded-lg px-5 text-sm font-medium text-white bg-26235C py-2.5 hover:bg-26235C focus:outline-none dark:bg-26235C dark:hover:bg-1B3FA0 dark:focus:bg-26235C"
                                        >
                                            Voir Avis
                                        </button>*/}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </>

            )}

            {/*<>
                <div className="items-center justify-center">
                    <div className="flex w-full justify-center overflow-auto pb-3 h-[416px]">
                        <div className="flex justify-center">
                            <div className=" px-10 flex flex-col gap-2 p-2  text-gray-800">
                                <span
                                    className="text-md font-semibold justify-center text-center text-1B3FA0 dark:text-1B3FA0">Reviews</span>

                                <div className="flex flex-col gap-3">
                                     Avis individuel
                                    {reviews.map((review, index) => (
                                        <div key={index}
                                             className="flex flex-col gap-4 rounded-lg bg-gray-100 p-4 shadow-sm hover:shadow-lg transition duration-300 ease-in-out">
                                             Header de l'avis
                                            <div className="flex justify-between items-center">
                                                <div className="flex items-center gap-3">
                                                    <div
                                                        className={`w-10 h-10 text-center rounded-full flex items-center justify-center bg-${review.color} text-white font-bold`}>
                                                        {review.initial}
                                                    </div>
                                                    <span
                                                        className="text-sm font-semibold text-gray-800">{review.name}</span>
                                                </div>
                                                <div className="flex items-center gap-1 text-yellow-400">
                                                    {[...Array(5)].map((_, i) => (
                                                        <ion-icon
                                                            key={i}
                                                            name={i < review.rating ? "star" : "star-outline"}
                                                            className="text-lg"
                                                        ></ion-icon>
                                                    ))}
                                                </div>
                                            </div>

                                             Contenu de l'avis
                                            <div className="text-sm text-gray-600">
                                                {review.comment}
                                            </div>

                                             Footer de l'avis
                                            <div className="flex justify-between items-center">
                                                <span className="text-xs text-gray-500">{review.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <script src="https://unpkg.com/ionicons@5.0.0/dist/ionicons.js"></script>
                    </div>
                    <div className="flex justify-center pt-[7px] buttons w-[580px]">
                        <button
                            onClick={handleFermerAvis}
                            className="mx-6 rounded-lg px-5 text-sm font-medium text-white bg-26235C py-2.5 hover:bg-26235C focus:outline-none dark:bg-26235C dark:hover:bg-1B3FA0 dark:focus:bg-26235C"
                        >
                            Voir Créneau
                        </button>
                    </div>
                </div>
            </>*/}

            {/* Section Avis */}
            {isReviewsVisible && (
                <div className="items-center justify-center">
                    <div className="flex w-full justify-center h-[416px]">
                        <div className="flex w-full max-w-full justify-center">
                            <div className=" px-10 w-full max-w-full flex flex-col gap-2  text-gray-800">
                                <span
                                    className="text-xl w-full max-w-full font-montserrat font-semibold justify-center text-center text-1B3FA0 dark:text-1B3FA0">Avis client</span>

                                <div className="flex w-full max-w-full pr-2 p-2  overflow-auto flex-col gap-3">
                                    {/* Avis individuel */}
                                    {reviews.length > 0 ? (
                                        reviews.map((review, index) => (
                                            <div
                                                key={index}
                                                className="flex  flex-col gap-4 rounded-lg bg-gray-100 p-4 border border-gray-200 hover:border-gray-300 shadow-sm hover:shadow-lg transition duration-300 ease-in-out"
                                            >
                                                {/* Header de l'avis */}
                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center gap-3">
                                                        <div
                                                            className={`w-10 h-10 text-center rounded-full flex items-center justify-center bg-blue-500 text-white font-bold`}
                                                        >
                                                            {review.nomProspect.charAt(0).toUpperCase()}
                                                        </div>
                                                        <span className="text-sm font-semibold text-gray-800">
                                                             {review.nomProspect.charAt(0).toUpperCase() + review.nomProspect.slice(1).toLowerCase()}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center gap-1 text-yellow-400">
                                                        {[...Array(5)].map((_, i) => (
                                                            <ion-icon
                                                                key={i}
                                                                name={
                                                                    i < Math.floor(review.evaluationProspect) // Étoiles pleines
                                                                        ? "star"
                                                                        : i < review.evaluationProspect // Demi-étoile
                                                                            ? "star-half"
                                                                            : "star-outline" // Étoiles vides
                                                                }
                                                                className="text-xl"
                                                            ></ion-icon>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* Contenu de l'avis */}
                                                <div className="text-sm text-gray-600">{review.avisProspect}</div>

                                                {/* Footer de l'avis */}
                                                <div className="flex justify-between items-center">
                                <span className="text-xs text-gray-500">
                                    {new Date(review.dateReservation).toLocaleString('fr-FR', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </span>
                                                    {/*{review.publiable ? (
                                                        <span className="text-xs text-green-500">Public</span>
                                                    ) : (
                                                        <span className="text-xs text-red-500">Non Public</span>
                                                    )}*/}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        !error &&
                                        <p className="text-gray-500">Aucun avis disponible pour cet expert.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <script src="https://unpkg.com/ionicons@5.0.0/dist/ionicons.js"></script>
                    </div>
                    <div className="flex justify-center pt-[7px] buttons w-[580px]">
                        <button
                            onClick={setReviewsVisible}
                            className="mx-6 rounded-lg px-5 text-sm font-medium text-white bg-26235C py-2.5 hover:bg-26235C focus:outline-none dark:bg-26235C dark:hover:bg-1B3FA0 dark:focus:bg-26235C"
                        >
                            Voir Créneau
                        </button>
                    </div>
                </div>

            )}
        </div>
    );
}

export default PlanningComponent;


/*{reviews.map((review, index) => (
                                        <div key={index}
                                             className="flex flex-col gap-4 rounded-lg bg-gray-100 p-4 shadow-sm hover:shadow-lg transition duration-300 ease-in-out">
                                            {/!* Header de l'avis *!/}
<div className="flex justify-between items-center">
    <div className="flex items-center gap-3">
        <div
            className={`w-10 h-10 text-center rounded-full flex items-center justify-center bg-${review.color} text-white font-bold`}>
            {review.initial}
        </div>
        <span
            className="text-sm font-semibold text-gray-800">{review.name}</span>
    </div>
    <div className="flex items-center gap-1 text-yellow-400">
        {[...Array(5)].map((_, i) => (
            <ion-icon
                key={i}
                name={
                    i < Math.floor(review.rating) // Étoiles pleines
                        ? "star"
                        : i < review.rating // Demi-étoile
                            ? "star-half"
                            : "star-outline" // Étoiles vides
                }
                className="text-xl"
            ></ion-icon>
        ))}
    </div>

</div>

{/!* Contenu de l'avis *!/}
<div className="text-sm text-gray-600">
    {review.comment}
</div>

{/!* Footer de l'avis *!/}
<div className="flex justify-between items-center">
    <span className="text-xs text-gray-500">{review.date}</span>
</div>
</div>
))}*/
