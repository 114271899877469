
import React, { useState, useEffect, useCallback } from 'react';
import ExpertComponent from './ExpertComponent';
import PlanningComponent from './PlanningComponent';
import { getPlanning } from '../Services/PlaningService';
import { getExperts } from '../Services/ExpertService';
import Header from "./Pages/Header";
import FooterBar from "./Pages/FooterBar";
import { getExpertById } from "../Services/ExpertSelected";
import apiClient from "../Services/apiService";
import { useNavigate } from "react-router-dom";
import HeaderSimple from "./Pages/HeaderSimple";
import { toast } from "react-toastify";
import NosExperts from "./Pages/NosExperts";
import { debounce } from "lodash";
import { useParams } from '../utils/useParams';
import { useHashedNavigate } from '../utils/useHashedNavigate';
import { useEmailClient } from '../utils/useEmailClient';
import axios from 'axios';
import { CONSULTER_EXPERT_PROFILE } from '../utils/eventsTypes';
import { getPublicIP } from '../utils/getIpAdress';


export default function ExpertSelectedId() {
    const [experts, setExperts] = useState([]);
    const [startDateMap, setStartDateMap] = useState({});
    //const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listExpertService, setListExpertService] = useState([]);
    const [planningData, setPlanningData] = useState([]); // Nouvelle state pour les données du planning
    const [cachedPlanningData, setCachedPlanningData] = useState({});
    const [cachedExpertsData, setCachedExpertsData] = useState({}); // Ajout de l'état pour les experts en cache
    const { ids } = useParams();
    const navigate = useHashedNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const { serviceId } = useParams();

    const emailClient = useEmailClient();


    const [isReviewsVisible, setReviewsVisible] = useState(false);

    const [reviewsVisibleMap, setReviewsVisibleMap] = useState({});

    /*const handleToggleReviews = () => {
        setReviewsVisible(!isReviewsVisible);
    };*/

    const handleToggleReviews = (idExpert) => {
        setReviewsVisibleMap((prev) => ({
            ...prev,
            [idExpert]: !prev[idExpert], // Bascule uniquement pour cet expert
        }));
    };
    /*
        const handleToggleReviews = () => {
            setReviewsVisible(!isReviewsVisible);
        };*/

    const handleSearch = async (event) => {
        event.preventDefault();
        try {

            //const response = await fetch(`/experts/nomOrPrenom?keyword=${encodeURIComponent(searchTerm)}`);
            //const data = await response.json();
            const response = await apiClient.get('/experts/nomOrPrenom', { params: { keyword: searchTerm } });
            const data = response.data;


            if (data.length > 0) {
                // Récupérer tous les IDs des experts ayant le même nom
                const expertIds = data.map(expert => expert.idExpert);
                // Construire une chaîne d'IDs séparés par une virgule
                const expertIdsString = expertIds.join(',');
                // Envoyer tous les IDs à la page suivante dans une seule URL
                navigate("/expertSelected/:id", expertIdsString);
                window.location.reload();
            } else {
                console.error('Aucun expert trouvé pour ce nom');
                // Afficher un message à l'utilisateur si aucun expert n'est trouvé
                toast.warning(`Aucun expert trouvé pour le nom : ${searchTerm}`);
            }
        } catch (error) {
            console.error('Erreur lors de la recherche des experts : ', error);
            // Gérer l'erreur, par exemple afficher un message d'erreur à l'utilisateur
            //toast.error('Erreur lors de la recherche des experts');
            toast.error(`Aucun expert trouvé pour le nom : ${searchTerm}`);
        }
    };

    const fetchSuggestions = async (value) => {
        try {
            const response = await apiClient.get('/experts/nomOrPrenom', { params: { keyword: value } });
            setSuggestions(response.data);
            if (response.data.length === 0) {
                setError('Aucun expert trouvé');
            }
        } catch (error) {
            console.error('Erreur lors de la recherche de l\'expert:', error);
            setError('Erreur lors de la recherche de l\'expert');
        }
    };

    // Utilisez useCallback pour mémoriser la fonction debounce
    const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 400), []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setError('');

        if (value.length >= 2) {
            debouncedFetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (expertId) => {
        navigate("/expertSelected/:id", expertId);
        window.location.reload();
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                //setLoading(true);
                const cachedExperts = cachedExpertsData['experts'];
                const expertsData = cachedExperts ? cachedExperts : await getExpertById(ids);

                if (!cachedExperts) {
                    setCachedExpertsData({ experts: expertsData });
                }

                const startDateMapData = {};
                const planningPromises = [];

                expertsData.forEach(expert => {
                    const lastMonday = getLastMondayForExpert(expert.idExpert);
                    const formattedDate = new Date(`${lastMonday.getFullYear()}-${String(lastMonday.getMonth() + 1).padStart(2, '0')}-${String(lastMonday.getDate()).padStart(2, '0')}`);
                    startDateMapData[expert.idExpert] = formattedDate;
                    planningPromises.push(getPlanning(formattedDate, expert.idExpert));
                });

                const planningData = await Promise.all(planningPromises);

                // Mettre à jour le cache
                setCachedPlanningData(prevState => ({
                    ...prevState,
                    [JSON.stringify(startDateMapData)]: planningData
                }));

                setPlanningData(planningData);
                setExperts(expertsData);
                setStartDateMap(startDateMapData);
                //setLoading(false);
            } catch (err) {
                setError(err.message);
                //setLoading(false);
            }
        };

        const traceEvent = async (status, message) => {
            let eventPayload;
            // Check if `ids` is a comma-separated list or a single number
            if (ids.includes(",")) {
                const idList = ids.split(",").map((id) => id.trim()); // Split into array and trim spaces

                // Construct parameters dynamically
                const parameters = idList.reduce((acc, id, index) => {
                    acc[`id${index + 1}`] = id; // e.g., id1=2, id2=7, ...
                    return acc;
                }, {});

                // Take the first ID for `expertId`
                const expertId = idList[0];

                // Convert object to a string joined by semicolons
                const parameterString = Object.entries(parameters)
                    .map(([key, value]) => `${key}=${value}`) // Format each key-value pair as "key=value"
                    .join(';');                // Build the payload
                eventPayload = {
                    expertId: expertId, // First ID
                    clientEmail: emailClient, // Not used in this case
                    eventType: CONSULTER_EXPERT_PROFILE,
                    ipAddress: await getPublicIP(), // Optional
                    parameter: parameterString,
                    status: status,
                    description: message
                    // Dynamic parameters
                };
            } else {
                // Single ID case
                eventPayload = {
                    expertId: ids, // Directly use the single ID
                    clientEmail: emailClient,
                    eventType: CONSULTER_EXPERT_PROFILE,
                    ipAddress: await getPublicIP(), // Optional
                    parameter: null, // No dynamic parameters needed
                    status: status,
                    description: message
                };
            }

            try {
                const response = await apiClient.post("/events/trace", eventPayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            } catch (error) {
                // Handle error response
                if (error.response) {
                    // Server returned an error response
                    console.error(error.response.data);
                } else {
                    // Network or other error
                    console.error("An unexpected error occurred");
                }
            }

        };


        const getExpertById = async (ids) => {
            try {
                const response = await apiClient.get(`/experts/getByIdExpert`, {
                    params: { ids: ids } // Convertit le tableau d'IDs en une chaîne séparée par des virgules pour l'URL
                });
                traceEvent('OK', "Consultation du profil d'un expert.")
                return response.data; // Retourne les données d'experts directement
            } catch (error) {
                if (error.response) {
                    const message = error.response.status + ' : ' + (error.response.data.message ? error.response.data.message   :  'Somenting went wrong')
                    traceEvent('NOK', message);
                }
            }
        };

        fetchData();
    }, []);



    const getLastMonday = (date) => {
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };

    const getLastMondayForExpert = (expertId) => {
        const date = new Date();
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };

    const handlePrevWeek = (expertId) => {
        const newStartDate = new Date(startDateMap[expertId] || getLastMondayForExpert(expertId));
        newStartDate.setDate(newStartDate.getDate() - 7);

        if (newStartDate < new Date()) {
            setStartDateMap(prevState => ({
                ...prevState,
                [expertId]: getLastMondayForExpert(expertId),
            }));
            return;
        }

        setStartDateMap(prevState => ({
            ...prevState,
            [expertId]: newStartDate,
        }));
    };

    const handleNextWeek = (expertId) => {
        const currentStartDate = new Date(startDateMap[expertId] || getLastMondayForExpert(expertId));
        const newStartDate = new Date(currentStartDate);
        newStartDate.setDate(currentStartDate.getDate() + 7);

        // Vérifier si la nouvelle date dépasse la limite de 4 semaines
        const maxDate = new Date(getLastMondayForExpert(expertId));
        maxDate.setDate(maxDate.getDate() + 27); // Limite de 4 semaines (28 jours)

        if (newStartDate > maxDate) {
            return; // Si on dépasse les 4 semaines, on ne fait rien
        }

        setStartDateMap(prevState => ({
            ...prevState,
            [expertId]: newStartDate,
        }));
    };

    const renderDays = (startDate) => {
        const days = [];
        const startDay = new Date(startDate);
        startDay.setDate(startDay.getDate() - startDay.getDay() + (startDay.getDay() === 0 ? -6 : 1));

        for (let i = 0; i < 7; i++) {
            const date = new Date(startDay);
            date.setDate(startDay.getDate() + i);
            const day = date.toLocaleString('default', { weekday: 'short' }).toLowerCase();
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;

            days.push(
                <div key={i}
                    className="flex items-center justify-center flex-col w-11 md:mx-[17px] md:w-[38px] text-sm font-medium">
                    <div className={"Date "}>
                        <div className={`text-center ${day === 'sam.' || day === 'dim.' ? 'text-red-500' : ''}`}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                        </div>
                        <div className="text-xs">{formattedDate}</div>
                    </div>
                </div>
            );
        }
        return days;
    };


    return (
        <nav className="m-auto">
            <div>
                {experts.length > 1 ? (
                    <HeaderSimple
                        mainTitle="Liste des experts"
                    //subTitle="Standard Subtitle"
                    />
                ) : (
                    experts.map((expert) => (
                        <HeaderSimple
                            key={expert.id}  // Assuming each expert has a unique id
                            mainTitle={`${expert.nomExpert} ${expert.prenomExpert}`}
                            subTitle={expert.titreExpert}
                        />
                    ))
                )}
            </div>
            <form className=" w-full flex p-4 flex-col items-center" onSubmit={handleSearch} autoComplete="off">
                <div>
                    <h1 htmlFor="default-search" className="mb-5 font-poppins text-md font-semibold text-dark">Trouvez
                        votre
                        expert
                        ici
                        :</h1>
                </div>

                <div className="relative w-full md:w-96">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search"
                        className="block w-full p-4 ps-10 focus:border-blue-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  "
                        placeholder="Recherche par expert..." required
                        value={searchTerm} onChange={handleChange} />
                    <button type="submit"
                        className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-full text-sm px-4 py-2">
                        Search
                    </button>
                    {suggestions.length > 0 && (
                        <div
                            id="dropdownDefaultCheckbox"
                            className="absolute mt-1 w-full md:w-96 bg-white border border-gray-300 rounded-lg shadow z-10"
                        >
                            <ul className="text-sm text-gray-700">
                                {suggestions.map(suggestion => (
                                    <li key={suggestion.idExpert}
                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => handleSuggestionClick(suggestion.idExpert)}>
                                        {suggestion.nomExpert} {suggestion.prenomExpert}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

            </form>
            {/*{loading ? (*/}
            {/*<div className="relative p-5 mt-5">
                    <div className="absolute inset-0 bg-white bg-opacity-60 flex items-center justify-center">
                        <div className="flex items-center">
                            <span className="text-md font-poppins mr-4">Chargement...</span>
                            <svg className="animate-spin h-8 w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    </div>
                </div>*/}
            {/*) : (*/}
            <div className="middle-content-exp">
                <div className="">
                    {experts.map((expert) => (
                        <div className="flex flex-col md:flex-row items-center mb-5 justify-center"
                            key={expert.idExpert}>
                            <ExpertComponent
                                expert={expert}
                                onToggleReviews={() => handleToggleReviews(expert.idExpert)}
                                isReviewsVisible={reviewsVisibleMap[expert.idExpert] || false}
                            />
                            <PlanningComponent
                                isReviewsVisible={reviewsVisibleMap[expert.idExpert] || false}
                                setReviewsVisible={() => handleToggleReviews(expert.idExpert)}
                                startDate={startDateMap[expert.idExpert]}
                                handlePrevWeek={() => handlePrevWeek(expert.idExpert)}
                                handleNextWeek={() => handleNextWeek(expert.idExpert)}
                                renderDays={() => renderDays(startDateMap[expert.idExpert])}
                                expert={expert}
                                expertsData={experts}
                                listExpertService={listExpertService} // Ajoutez cette ligne pour passer les données des services d'experts
                                planningData={planningData.find(data => data.idExpert === expert.idExpert)}
                                serviceId={serviceId}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/*)}*/}
            <FooterBar></FooterBar>
        </nav>
    );
}
