import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

export function useEmailClient(){
    const isAuthenticated = useIsAuthenticated()
    const authUser = useAuthUser()
    if(isAuthenticated){
        return authUser.email
    }
    return null
}