import React from 'react';
import { Field } from 'formik';

export default function StepPersonalInfo({ values, errors, touched }) {
    return (
        <div>
            <div className="flex flex-row space-x-5 items-center justify-center">
                <div className="w-full">
                    <label className="text-gray-800 text-sm mb-2 block">Prénom</label>
                    <Field
                        name="firstName"
                        type="text"
                        required
                        className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                        placeholder="Entrez votre prénom"
                    />
                    {errors.firstName && touched.firstName && (
                        <div className="text-red-500 pt-2 text-xs">{errors.firstName}</div>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-gray-800 text-sm mb-2 block">Nom</label>
                    <Field
                        name="lastName"
                        type="text"
                        required
                        className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                        placeholder="Entrez votre nom"
                    />
                    {errors.lastName && touched.lastName && (
                        <div className="text-red-500 pt-2 text-xs">{errors.lastName}</div>
                    )}
                </div>
            </div>
        </div>
    );
}
